<template>
    <div v-if="showFilters" class="filter-group">
        <div class="flex justify-content-between filter-group-title-wrapper">
            <h3>Filters</h3>
            <span class="material-icons-round grey mr-3 filter-close" @click="togglePanel">close</span>
        </div>
        <div class="flex align-items-center justify-content-start options-wrapper">
            <div v-if="showOptions && showOptions.includes('portfolios')" class="title-dropdown"><label>Portfolios:</label><portfolio-select /></div>
            <div v-if="showOptions && showOptions.includes('people')" class="title-dropdown"><label>People:</label><people-select /></div>
            <div v-if="showOptions && showOptions.includes('policies')" class="title-dropdown"><label>Policy:</label><policy-select /></div>
            <div v-if="showOptions && showOptions.includes('valuation')" class="title-dropdown"><label>Valuation Date:</label><valuation-date dateRef="valuationDate" /></div>
            <!-- @TODO: Period dropdown -->
            <div v-if="showOptions && showOptions.includes('period')" class="title-dropdown"><label>Period:</label><period-select /></div>
            <div v-if="showOptions && showOptions.includes('currency')" class="title-dropdown"><label>Currency:</label><currency-select /></div>
            <div v-if="showOptions && showOptions.includes('transactions')" class="title-dropdown"><label>View Transaction History (from):</label><valuation-date dateRef="dateRangeFrom" /></div>
            <div v-if="showOptions && showOptions.includes('transactions')" class="title-dropdown"><label>View Transaction History (to):</label><valuation-date dateRef="dateRangeTo" /></div>
        </div>
    </div>
</template>
<script>
import CurrencySelect from './CurrencySelect.vue'
import PeopleSelect from './PeopleSelect.vue'
import PolicySelect from './PolicySelect.vue'
import PortfolioSelect from './PortfolioSelect.vue'
import ValuationDate from './ValuationDate.vue'
import { computed } from 'vue'
import { useStore } from 'vuex'
import PeriodSelect from "./PeriodSelect";

export default {
    props: {
        showOptions: Array,
        toggleKey: String
    },
    setup(props) {
        const store = useStore()

        const showFilters = computed(() => store.getters.getElementVisibility(props.toggleKey))

        const togglePanel = () => {
            console.log('toggle key is ', props.toggleKey)
            store.dispatch('updateElementVisibility', { "key": props.toggleKey, "value": !showFilters.value})
        }

        return {
            showFilters,
            togglePanel
        }
    },
    components: {
      PeriodSelect,
        CurrencySelect,
        PeopleSelect,
        PolicySelect,
        PortfolioSelect,
        ValuationDate
    }
}
</script>
<style scoped lang="scss">
.filter-group {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: var(--clarity-snow-grey);
    border-radius: 27px;
    padding: 20px 10px 20px 20px ;
    margin-top: 20px;
}
.title-dropdown {
    display: flex;
    flex-direction: column;
    width: 33%;
    margin-bottom: 10px;
    padding-right: 10px;
}
@media only screen and (max-width: 1366px) {
    .title-dropdown {
        width: 50%;
    }
}
@media only screen and (max-width: 768px) {
    .title-dropdown {
        width: 100%;
    }
}
.title-dropdown label {
    margin: 10px 0px;
    // font-weight: bold;
}

.p-dropdown, .p-multiselect {
  width: 100%;
}
.options-wrapper {
  flex-wrap: wrap;
}
</style>
