<template>
  <Dropdown :model-value="selectedOption" :options="policyOptions" optionLabel="label" optionGroupLabel="label" optionGroupChildren="items" @change="updatePolicySelected" :loading="loading" placeholder="All Policies" :showClear="true">
    <template #optiongroup="slotProps">
      <div class="flex align-items-center">
        <div>{{slotProps.option.label}}</div>
      </div>
    </template>
    <template #value="slotProps">
        <div v-if="slotProps.value">
            {{ slotProps.value.label }}
        </div>
        <span v-else>
          {{ slotProps.placeholder }}
        </span>
    </template>
    <template #option="slotProps">
        {{ slotProps.option.label }} <span v-html="ownershipStringForUUID(slotProps.option.value)"></span>
    </template>
  </Dropdown>
</template>

<script>
import {useStore} from "vuex";
import { computed, onMounted } from "vue";

export default {
  name: "PolicySelect",
  setup() {

    const store = useStore()

    onMounted(() => {
      getPolicyOptions()
    })

    const getPolicyOptions = () => {
      store.dispatch('getPolicyOptions')
    }

    const updatePolicySelected = (data) => {
      store.dispatch('setPolicySelected', data.value)
    }

    const policyOptions = computed(() => {
      const stateOptions = store.state.selections.policyOptions;
      if(stateOptions && stateOptions.error){
        store.dispatch('addNotification', {severity: 'error', summary: 'Policy Select', detail: stateOptions.message })
        return [];
      }else{
        const options = store.getters.policyOptions;
        return options;
      }
    })

    const ownershipStringForUUID = (uuid) => {
        const basicInfo = store.getters.policyBasicInfo(uuid);
        if (basicInfo && basicInfo.ownership) {
            let rtn = []
            basicInfo.ownership.forEach(person => {
                rtn.push(store.getters.personFromUuid(person).name)
            })
            return '(<span style="font-weight: bold">'+rtn.join(", ")+'</span>)'
        }
        return '';
    }

    return {
      selectedOption: computed(() => store.getters.policySelected ),
      loading: computed(() => store.state.selections.policyLoading),
      policyOptions,
      updatePolicySelected,
      ownershipStringForUUID
    }

  }
}
</script>

<style scoped>

</style>
