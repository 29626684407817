export const pdfStyles = () => {
    // need to dynamically get this from pdf-styles file
    return `
            body {font-family: 'Josefin Sans', sans-serif; font-size: 14px;}
            h3 {color:#0058A9;text-transform: uppercase; font-weight: lighter;}
            h4 {color: #179BD7;text-transform: uppercase; font-weight: lighter; margin: 0px;}
            table, th, td { border-collapse: collapse; border: none;}
            .filters-table tr td {padding: 5px 0px;}
            .filters-table tr td:first-of-type, .details-table tr td:first-of-type {color: #8B96A2; padding-right: 10px;}
            .details-table tr td:nth-of-type(2) {text-align: left;}
            .summary-table {width: 100%;}
            .summary-table tr td {border-bottom: 1px solid #8B96A2; padding: 10px 0px;}
            .right {text-align: right;}
            .summary-table tr:last-of-type td {color:#0058A9; font-weight: bold; border-bottom: none;}
            .plans-table, .plans-table tr {font-size: 12px; width: 100%;}
            .plans-table tr:nth-child(odd) {background-color: #F2F4F6;}
            .plans-table tr th {color: #FFFFFF; background-color: #0058A9; font-weight: lighter; padding: 5px;}
            .plans-table tr th {text-align: left;}
            .plans-table tr th:last-of-type {text-align: right;}
            .plans-table tr td {padding: 10px 0px;}
            .plans-table tr td:first-of-type {padding-left: 5px;}
            .plans-table tr td:last-of-type {padding-right: 5px; text-align: right;}
            .plans-table tr td:nth-of-type(2), .plans-table tr td:nth-of-type(3), .plans-table tr td:nth-of-type(4) {color: #8B96A2; padding-left: 5px; padding-right: 10px; font-size: 11px;}
            .plans-table tr.total td {background:  #179BD7 0% 0% no-repeat padding-box; color: #FFFFFF; font-weight: bold; width: 100%;}
            #header, #footer {position: fixed;left: 0;right: 0; padding: 30px;}
            #header {top: 0;}
            #footer {bottom: 0;}
            #header table, #footer table {width: 100%;border-collapse: collapse;border: none;}
            #header td, #footer td {padding: 0;width: 50%;}
            .page-number {text-align: center;}
            .page-number:before {content: counter(page); font-weight: bold;}
            .subtitle { color: #179BD7; margin-bottom: 0px; padding-bottom: 0px;}
            .owner-details {color: #8B96A2; padding-right: 10px; font-weight: lighter;}
            .ims-application { width: 100% }
            .ims-application td { padding: 3px; }
            `
}
