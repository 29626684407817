<template>
  <inner-card>
    <template #content>
      <!-- collapsed two line -->
      <div v-if="!isExpanded" class="flex align-items-center justify-content-between">
        <div class="flex flex-column">
          <div class="flex align-items-center mr-3"><h3 class="blue">{{ policy.label }}</h3></div>
          <div class="flex">
            <span class="small-text grey mr-3">Owner: {{ getOwnerFromUuid(policyBasicInfo.ownership) }} </span>
            <pill v-if="policyIsIMS" label="IMS Portfolio" icon="pi pi-check"/>
            <separator-v class="mx-3" style="border-right-color: var(--clarity-light-grey); height: 24px"/>
            <img v-if="policyBasicInfo.clarityType === 'clarity'" src="@/assets/logos/clarity-logo-small-blue.svg">
            <span v-if="policyBasicInfo.clarityType === 'openbanking'" class="material-icons-round blue">account_balance</span>
            <span v-if="policyBasicInfo.clarityType === 'selfmanagedholding'" class="material-icons-round blue">person</span>
            <span class="small-text ml-3" style="color: var(--clarity-grey)">{{ getPolicyTypeLabel(policyBasicInfo) }}</span>
          </div>
        </div>
        <div @click.stop="toggleView" style="cursor: pointer;">
          <div class="flex align-items-center grey"><span class="material-icons-round grey">expand_more</span>Show
            Details
          </div>
        </div>
      </div>
      <!-- expanded single lines -->
      <div v-if="isExpanded" class="flex align-items-center justify-content-between">
        <div class="flex align-items-center">
          <div class="flex align-items-center mr-3"><h3 class="blue mx-2">{{ policy.label }}</h3><span
              class="small-text grey">Owner: {{ getOwnerFromUuid(policyBasicInfo.ownership) }} </span></div>
          <pill v-if="policyIsIMS" label="IMS Portfolio" icon="pi pi-check"/>
          <separator-v class="mx-3" style="border-right-color: var(--clarity-light-grey); height: 24px"/>
          <img v-if="policyBasicInfo.clarityType === 'clarity'" src="@/assets/logos/clarity-logo-small-blue.svg">
          <span v-if="policyBasicInfo.clarityType === 'openbanking'" class="material-icons-round blue">account_balance</span>
          <span v-if="policyBasicInfo.clarityType === 'selfmanagedholding'" class="material-icons-round blue">person</span>
          <span class="small-text ml-3" style="color: var(--clarity-grey)">{{ getPolicyTypeLabel(policyBasicInfo) }}</span>
        </div>
        <div @click.stop="toggleView" style="cursor: pointer;">
          <div class="flex align-items-center grey"><span class="material-icons-round grey">expand_less</span>Hide
            Details
          </div>
        </div>
      </div>
      <div v-if="!policyBasicInfo.transactions && isExpanded" class="clarity-card-inner">
        <div class="banner">
          <div class="flex flex-column">
            <h3>No Performance?</h3>
          </div>
          <div>
            <Button class="clarity-btn clarity-gradient-orange" @click="$router.push('/securemessaging/message'); contactAdviser(policyBasicInfo.name);">Transfer to Clarity</Button>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="flex align-items-center justify-content-between my-4"
             v-if="isExpanded && policyBasicInfo.transactions && !showPerformance">
          <div class="flex full-width">
            <!-- SEARCH -->
            <div class="half pr-5">
                            <span class="p-input-icon-left cl-input full-width">
                            <i class="pi pi-search"></i>
                            <InputText type="text" v-model="filters.global.value" placeholder="Search..."
                                       class="p-inputtext-lg w-full"/>
                            </span>
            </div>
            <!-- FILTER -->
            <div class="half transaction-filter">
              <div class="dropdown">
                <label class="hide-small">Filters:</label>
                <MultiSelect v-model="selected" :options="options(data)" optionLabel="name"
                             placeholder="Select Type Description"  @before-show="stopScroll" @before-hide="allowScroll" >
                  <template #value="slotProps">
                    {{ getValue(slotProps, options(data).length) }}
                  </template>
                </MultiSelect>
              </div>
            </div>
          </div>
          <!-- Rows per page -->
          <div class="flex justify-content-end align-items-center" style="width: 50%"><span class="mr-2"><span class="hide-small">Entries</span>Per Page:</span>
            <Dropdown v-model="rows" :options="[5,10,20,50]"/>
          </div>
        </div>
        <div v-if="showPerformance && isExpanded">
          <div class="body clarity-snow-grey mt-5">
            <div class="flex align-items-center">
              <div class="flex-1 text-center p-4">
                <h3 class="light-blue text-center">Money in</h3>
                <div class="flex align-items-center justify-content-center">
                  <span class="material-icons-round mr-3">expand_less</span>
                  <h1 class="green text-center">
                    <currency-value :value=" moneyInTotal ?? 0.00" :decimal-places="2"/>
                  </h1>
                  <span class="material-icons-round ml-3">expand_less</span>
                </div>
                <p class="small-text text-center grey">Total money in from {{ $date(from, 'DD/MM/YYYY') }} to
                  {{ valuationDate.format('DD/MM/YYYY') ?? to }}</p>
              </div>
              <separator-v
                  style="border-right-color: var(--clarity-pure-white); border-right-width: 2px; height: 122px;"/>
              <div class="flex-1 text-center p-4">
                <h3 class="light-blue text-center">Money Out</h3>
                <div class="flex align-items-center justify-content-center">
                  <span class="material-icons-round mr-3">expand_more</span>
                  <h1 class="red text-center">
                    <currency-value :value="moneyOutTotal ?? 0.00" :decimal-places="2"/>
                  </h1>
                  <span class="material-icons-round ml-3">expand_more</span>
                </div>
                <p class="small-text text-center grey">Total money out from {{ $date(from, 'DD/MM/YYYY') }} to
                  {{ valuationDate.format('DD/MM/YYYY') ?? to }}</p>
              </div>
              <separator-v
                  style="border-right-color: var(--clarity-pure-white); border-right-width: 2px; height: 122px;"/>
              <div class="flex-1 text-center p-4">
                <h3 class="light-blue text-center">Net (%)</h3>
                <h1 class="blue text-center" v-if="typeof goalSeek.percent == 'number' && !isNaN(goalSeek.percent)">
                    {{ $nb(goalSeek.percent, 'percentage', 2) }}</h1>
                <h1 class="blue text-center" v-else>N/A</h1>
                <p class="small-text text-center grey">Total net return from {{ $date(from, 'DD/MM/YYYY') }} to
                  {{ valuationDate.format('DD/MM/YYYY') ?? to }}</p>
              </div>
            </div>
          </div>
          <div class="flex justify-content-center">
            <Button v-if="!showMonthData" @click="toggleMonthData"
                    class="custom-btn more clarity-btn round align-self-center clarity-pure-white"><span
                class="material-icons-round grey">add</span></Button>
            <Button v-if="showMonthData" @click="toggleMonthData"
                    class="custom-btn less clarity-btn round align-self-center clarity-pure-white"><span
                class="material-icons-round grey">remove</span></Button>
          </div>
          <transition name="fade" mode="out-in">
            <div v-show="showMonthData" class="mb-5">
              <DataTable v-if="goalSeek" :value="goalSeek.months" class="rd-table">
                <Column header="Month" field="month"/>
                <Column header="Money In" field="money_in">
                  <template #body="slotProps">
                    <currency-value :value="slotProps.data.money_in" :decimal-places="2"
                                    :class="{ 'positive-text': hasValue(slotProps.data.money_in)}"/>
                  </template>
                </Column>
                <Column header="Money Out" field="money_out">
                  <template #body="slotProps">
                    <currency-value :value="slotProps.data.money_out" :decimal-places="2"
                                    :class="{ 'negative-text': hasValue(slotProps.data.money_out)}"/>
                  </template>
                </Column>
              </DataTable>
            </div>
          </transition>

          <div>
            <h3 class="mb-3">Money In Transactions</h3>
            <TransactionsTable v-if="moneyIn" :monies="moneyIn" id="transactions-in"></TransactionsTable>
          </div>
          <div>
            <h3 class="mb-3 mt-5">Money Out Transactions</h3>
            <TransactionsTable v-if="moneyOut" :monies="moneyOut" id="transactions-out"></TransactionsTable>
          </div>
        </div>
        <div v-if="!data && isExpanded && !showPerformance">
          <Skeleton v-if="loading" class="mb-2" width="100%" height="4rem" borderRadius="27px"></Skeleton>
          <Skeleton v-if="loading" class="mb-2" width="70%" height="2rem" borderRadius="27px"></Skeleton>
          <Skeleton v-if="loading" class="mb-2" width="50%" height="2rem" borderRadius="27px"></Skeleton>
          <Skeleton v-if="loading" class="mb-2" width="30%" height="2rem" borderRadius="27px"></Skeleton>
        </div>
        <div v-if="data && isExpanded && !showPerformance && !loading">
          <DataTable :value="data.transactions" class="rd-table" :paginator="true" :rows="rows"
                     :filters="filters"
                     :globalFilterFields="['product','description']">
            <Column field="product" header="Holding Name" sortable style="min-width: 400px;">
              <template #body="slotProps">
                <h3 class="light-blue">{{ slotProps.data.product }}</h3>
                <span class="hide-column-title">{{ slotProps.data.description}}</span>
              </template>
            </Column>
            <Column filterField="description" class="hide-column" header="Description" sortable>
              <template #body="{data}">
                {{ data.description }}
              </template>
            </Column>
            <Column field="transactionDate" header="Date" sortable style="width: 10%;">
              <template #body="slotProps">
                {{ $date(slotProps.data.transactionDate, 'DD/MM/YYYY') }}
              </template>
            </Column>
            <Column field="units" header="Units" class="hide-column" sortable style="max-width: 140px;"></Column>
            <Column field="price" header="Price" class="hide-column" sortable style="max-width: 140px;">
              <template #body="slotProps">
                <currency-value :value="slotProps.data.price" :decimal-places="2"/>
              </template>
            </Column>
            <Column class="valueCells" field="value" header="Value" sortable style="max-width: 170px;">
              <template #body="slotProps">
                <currency-value :value="slotProps.data.value" :decimal-places="2"
                                :class="{ 'negative-text': isNegative(slotProps.data.value)}"/>
              </template>
            </Column>
            <template #paginatorstart>
              <!-- Needed to centralise paginator -->
            </template>
            <template #paginatorend>
              <div class="my-2"><span class="mr-2">Entries Per Page:</span>
                <Dropdown v-model="rows" :options="[5,10,20,50]" class="pagination-options"/>
              </div>
            </template>
            <template #empty>
              <h3 class="flex justify-content-center">No transactions data available.</h3>
            </template>
            <template #loading>
              <Skeleton class="mb-2" width="100%" height="2rem" borderRadius="27px"></Skeleton>
            </template>
          </DataTable>
        </div>
      </div>
    </template>
  </inner-card>
</template>
<script>
import {
    computed, onMounted, ref, inject,
    watch, onBeforeUnmount
} from 'vue'
import {useStore} from 'vuex'
import {
  FilterMatchMode,
  FilterOperator
} from 'primevue/api';
import dayjs from 'dayjs'
import InnerCard from '../common/InnerCard.vue'
import Pill from '@/components/nav/Pill'
import SeparatorV from '@/components/common/layout/SeparatorV.vue'
import CurrencyValue from "@/components/common/CurrencyValue";

import TransactionsTable from '../transactions/TransactionsTable.vue'
import api from '@/core/services/api'
import {toDrupalRequest} from '@/core/helpers';

export default {
  props: ['policy', 'expanded', 'dateRange', 'showPerformance'],
  setup(props) {
    const store = useStore()
    const bus = inject('bus')
    const selected = ref()
    const loading = ref(true)

    const filters = ref({
      'description': {value: null, matchMode: FilterMatchMode.CONTAINS},
      'global': {value: null, matchMode: FilterMatchMode.CONTAINS}
    })

    const rows = ref(10)

    onMounted(() => {
      loading.value = true
      loadPresentValues(props.policy.value)
      loadPastValues(props.policy.value)
      store.dispatch('getTransactionsForPolicy', {
        ...props.policy,
        from: props.dateRange.from,
        to: props.dateRange.to
      })
    })

    watch(selected, () => {
      filters.value.description = createDynamicFilters()
    })

    const createDynamicFilters = () => {
      let trimmed = []
      selected.value.forEach(opt => {
        trimmed.push({value: opt.name, matchMode: FilterMatchMode.CONTAINS})
      })

      return trimmed.length ? {operator: FilterOperator.OR, constraints: trimmed} : {
        value: null,
        matchMode: FilterMatchMode.CONTAINS
      }
    }

    const from = computed(() => dayjs(store.state.valuation.dateRangeFrom).format('YYYY-MM-DD'))
    const to = computed(() => dayjs(store.state.valuation.dateRangeTo).format('YYYY-MM-DD'))

    const loadPresentValues = async (uuid) => {
        const presentResponse = await api.get(`${toDrupalRequest('valuation/plan/summary/'+uuid+'/'+to.value)}`)
        if (presentResponse) {
            await store.dispatch('setPerformanceTransactionTotal', {policy: uuid, value: presentResponse.total, point: 'present'})
        }
    }

    const loadPastValues = async (uuid) => {
        const pastResponse = await api.get(`${toDrupalRequest('valuation/plan/summary/'+uuid+'/'+from.value)}`)
        if (pastResponse) {
            await store.dispatch('setPerformanceTransactionTotal', {policy: uuid, value: pastResponse.total, point: 'past'})
        }
    }

    watch(from, () => {
        loadPresentValues(props.policy.value)
        loadPastValues(props.policy.value)
        store.dispatch('getTransactionsForPolicy', {
            ...props.policy,
            from: from.value,
            to: to.value
        })
    })

    watch(to, () => {
        loadPresentValues(props.policy.value)
        loadPastValues(props.policy.value)
        store.dispatch('getTransactionsForPolicy', {
            ...props.policy,
            from: from.value,
            to: to.value
        })
    })


    const updateTransactions = () => {
      // check if origin is from valuations
      if (from.value > to.value) {
        return store.dispatch('addNotification',{severity: 'error', summary: 'Date Range Error', detail: 'From date must come before To date.'});
      }

      loading.value = true
      loadPresentValues(props.policy.value)
      loadPastValues(props.policy.value)
      store.dispatch('getTransactionsForPolicy', {
        ...props.policy,
        from: from.value,
        to: to.value
      })
    }

    bus.on('refreshTransactions', () => {
      updateTransactions()
    })

    bus.on('transactionsLoaded', () => {
      loading.value = false
    })

    const buildFilterOptions = (data) => {
      const opts = []
      const raw = []
      let uniq = []

      if (data && data.transactions && data.transactions.length) {
        data.transactions.forEach(t => {
          if (t.description.includes(' - ')) {
            const split = t.description.split('-')
            raw.push(split[0].trim())
          } else {
            raw.push(t.description)
          }
        });
        uniq = [...new Set(raw)];

        uniq.forEach(option => {
          opts.push({name: option, value: option})
        })
      }

      return uniq.length && opts.length ? opts : []
    }

    const isExpanded = ref(props.expanded)

    const toggleView = () => {
      isExpanded.value = !isExpanded.value
    }

    const isNegative = (val) => {
      return val < 0.00
    }

    const showMonthData = ref(false)
    const toggleMonthData = () => {
      showMonthData.value = !showMonthData.value
    }

    const moneyIn = computed(() => store.getters.performanceTransactions(props.policy.value, 'in'))
    const moneyOut = computed(() => store.getters.performanceTransactions(props.policy.value, 'out'))
    const moneyInTotal = computed(() => store.getters.performanceTotal(props.policy.value, 'in'))
    const moneyOutTotal = computed(() => store.getters.performanceTotal(props.policy.value, 'out'))

    const getPlanName = (info) => {
      return info ? info.name : ''
    }

    const getOwnerFromUuid = (owner) => {
      let rtn = []
      owner.forEach(person => {
        rtn.push(store.getters.personFromUuid(person).name)
      })

      return rtn.join(", ")
    }

    const valuationDate = computed(() => {
      let valuation = store.state.valuation.valuationDate
      return new dayjs(valuation)
    })

    const hasValue = (val) => {
      return val > 0.00
    }

    const getValue = (slotProps, optLength) => {
      switch (true) {
        case selected.value && optLength == selected.value.length:
          return 'All'
        case selected.value && selected.value.length > 1:
          return 'Multiple'
        default:
          // this state reached at start and when deselecting all return placeholder
          return slotProps.value && slotProps.value.length ? slotProps.value[0].name : slotProps.placeholder
      }
    }

    const getPolicyTypeLabel = (policyInfo) => {
      switch (policyInfo.clarityType) {
        case 'clarity':
          return 'investment'
        case 'selfmanagedholding':
          return 'Self Managed Holding'
        case 'openbanking':
          return 'Open Banking'
      }
    }

    const stopScroll = () => {
      bus.emit('stopScroll')
    }
    const allowScroll = () => {
      bus.emit('allowScroll')
    }

      const contactAdviser = (plan) => {
          store.dispatch('globalMessageTrigger',  {
              subject: 'Transfer to clarity - ' + plan,
              category: 'Investments',
              recipient: store.getters.getAdviserName,
              messagebody: 'I would like to discuss transferring my '+plan+' to clarity.'
          })
      }

      onBeforeUnmount(() => {
          bus.off('refreshTransactions')
          bus.off('transactionsLoaded')
      });

      return {
      filters,
      rows,
      selected,
      isExpanded,
      isNegative,
      toggleView,
      data: computed(() => store.getters.policyTransactions(props.policy)),
      policyBasicInfo: computed(() => store.getters.policyBasicInfo(props.policy.value)),
      options: buildFilterOptions,
      moneyIn,
      moneyOut,
      getPlanName,
      getOwnerFromUuid,
      moneyInTotal,
      moneyOutTotal,
      valuationDate,
      from,
      to,
      goalSeek: computed(() => store.getters.getGoalSeek(props.policy.value)),
      showMonthData, toggleMonthData,
      hasValue,
      getValue,
      getFilters: computed(() => {
        return filters.value
      }),
      loading,
      policyIsIMS: computed(() => store.getters.policyIsIMS(props.policy.value)),
      getPolicyTypeLabel,
      allowScroll,
      stopScroll,
      contactAdviser
    }
  }
  ,
  components: {
    InnerCard,
    Pill,
    SeparatorV,
    CurrencyValue,
    TransactionsTable,
  }
}
</script>
<style scoped>
.half {
  min-width: calc(100vw / 5);
  display: flex;
  flex-direction: row;
  margin-right: 30px
}

.transaction-filter label {
  font-size: 18px;
  margin-right: 20px;
}

.transaction-filter .dropdown {
  flex-direction: row;
  align-items: baseline;
}

.transaction-filter .p-dropdown,
.transaction-filter .p-multiselect {
  min-width: calc(100vw / 6);
  height: 54px;
  align-items: center;
}

.pagination-options {
  height: 45px;
  margin-right: -20px;
}

.pagination-options:deep(.p-inputtext) {
  align-self: center;
  padding: 15px 10px 15px 20px;
}

.negative-text {
  color: var(--clarity-red);
}

.banner {
  flex-direction: row;
  justify-content: space-between;
  padding: 30px;
  width: 66%;
  align-self: center;
  background-image: url('../../assets/img/banner-bg-fade-left.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
  margin-top: 30px;
}

/* CARRIED OVER FROM PERFORMANCE MODAL */
.body {
  border-radius: 27px;
}

.custom-btn {
  top: -26px; /* half button height */
  border-color: var(--clarity-light-grey);
}

.custom-btn:hover {
  background: var(--clarity-pure-white);
  border-color: var(--clarity-light-grey);
}

.custom-btn:hover span {
  color: var(--clarity-blue);
}

.more:hover:after {
  color: var(--clarity-blue);
  content: 'View Info';
  padding-left: 10px;
}

.less:hover:after {
  color: var(--clarity-blue);
  content: 'View Less';
  padding-left: 10px;
}

.negative-text {
  color: var(--clarity-red);
}

.positive-text {
  color: var(--clarity-green);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s linear;
}

.fade-enter-from,
.fade-leave-active {
  opacity: 0;
}

@media only screen and (max-width: 1366px)  {
    .hide-small {
        display: none;
    }
}

</style>
