<template>
    <img :src='resolve_img_url(name)' :alt='altText' class="icon">
</template>

<script>
import { ref } from 'vue'
    export default {
        props: {
            name: String,
            altText: String,
            isLarge: Boolean
        },
        setup(props) {
            const source = ref()
            const resolve_img_url = (name) => {
                var path = '';
                if(props.isLarge) {
                    path = name + '-lrg.svg';
                    let largeImages = require.context('../../assets/icons/largeicons/', false, /\.svg$|\.png$|\.jpg$/)
                    return source.value = largeImages("./"+path)

                } else {
                    path = name + '.svg';
                    let images = require.context('../../assets/icons/', false, /\.svg$|\.png$|\.jpg$/)
                    return source.value = images("./"+path)
                }
            }

            return {
                resolve_img_url,
            }
        }
    }
</script>

<style scoped>
    img {
        margin: 0;
        padding: 0;
        max-width: auto;
        max-height: auto;
        opacity: 0.5;
    }
</style>
