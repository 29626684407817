import dayjs from "dayjs";
import PlanTransactionSection from '../../classes/ValuationExcelExportPlanTransactionSection'
import * as ExportData from './ValuationDataExtractor'

export async function buildTransactionExcelPayload(dateRange, adviser){
    const fromDateDMY = dayjs(dateRange.from).format('DD/MM/YYYY')
    const toDateDMY = dayjs(dateRange.to).format('DD/MM/YYYY')
    const todayDateDMY = dayjs().format('DD/MM/YYYY')

    const baseObject = {
        details: {},
        filters: {},
        plans: {}
    }

    let detailsSet = await buildHeaderSection()
    let filtersSet = await buildFilterSection()
    let plansSet = await buildPlansSection()

    if (detailsSet && filtersSet && plansSet) {
        return baseObject
    }
    async function buildHeaderSection() {
        const primaryClient = await ExportData.getPrimaryClient()
        const adviserName = adviser.name

        return baseObject.details = {
            "sectionheader": [],
            "sectioncontent": [
                {
                    "A": "Client Name",
                    "F": primaryClient
                },
                {
                    "A": "Exporting Date",
                    "F": todayDateDMY
                },
                {
                    "A": "Adviser Name",
                    "F": adviserName
                }
            ],
            "sectionfooter": []
        }
    }

    async function buildFilterSection() {
        // @TODO: redo this to make use of portfolio options instead to pull out name
        let portfolio = await ExportData.getSelectedPortfolio()
        let policy = await ExportData.getPolicies()

        return baseObject.filters = {
            "sectionheader": [],
            "sectioncontent": [
                {
                    "A": "Portfolio",
                    "F": portfolio
                },
                {
                    "A": "People",
                    "F": await ExportData.getSelectedPeople()
                },
                {
                    "A": "Policies",
                    "F": await policy
                },
                {
                    "A": "From Date",
                    "F": fromDateDMY
                },
                {
                    "A": "To Date",
                    "F": toDateDMY
                }
            ],
            "sectionfooter": []
        }
    }

    async function buildPlansSection() {
        const plansObject = {
            sectionheader:[],
            sectioncontent:[],
            sectionfooter: []
        }

        let plans = await ExportData.getTransactions(dateRange)

        for (const [name, rows, owner] of plans) {
            if(rows.length > 0) {
                let section = new PlanTransactionSection(name, owner, rows)
                plansObject.sectioncontent.push(section.getSection())
            }
        }

        baseObject.plans = plansObject

        return plansObject
    }
}
