<template>
  <div class="body flex-1">
    <div class="flex align-items-center show-big">
      <div class="flex-1 text-center p-4">
        <div v-if="loadingPresent" align="center">
          <Skeleton class="mb-2" height="2rem" borderRadius="16px"></Skeleton>
          <Skeleton class="mb-3" width="12rem" borderRadius="16px"></Skeleton>
        </div>
        <div v-else>
          <h2 class="blue text-center">
            <currency-value :value="presentValue" :decimal-places="0"/>
          </h2>
          <p class="small-text text-center">Value as at {{ presentDate.format('DD-MM-YYYY') }}</p>
        </div>
      </div>
      <separator-v style="height: 80px"/>
      <div class="flex-1 text-center p-4">
        <div v-if="loadingPast" align="center">
          <Skeleton class="mb-2" height="2rem" borderRadius="16px"></Skeleton>
          <Skeleton class="mb-3" width="12rem" borderRadius="16px"></Skeleton>
        </div>
        <div v-else>
          <h2 class="blue text-center">
            <currency-value :value="pastValue" :decimal-places="0"/>
          </h2>
          <p class="small-text text-center">Value as at {{ pastDate.format('DD-MM-YYYY') }}</p>
        </div>
      </div>
      <separator-v style="height: 80px"/>
      <div v-if="fullPerformance" class="flex-1 text-center p-4">
        <h4 class="dark-grey text-center bold uncapitalize">{{ $case('Annualised Return', 'title') }}</h4>
        <div class="inline-flex align-items-center justify-content-center">
          <h2 class="blue text-center">
            <currency-value :value="presentValue - (pastValue+netMovement)" :decimal-places="0"/>
          </h2>
          <h3 class="light-blue ml-3">({{ $nb(goalseek, 'percentage') }})</h3></div>
        <p class="small-text text-center">Total net return from {{ pastDate.format('DD-MM-YYYY') }} to
          {{ presentDate.format('DD-MM-YYYY') }}</p>
      </div>
      <div v-else class="flex-1 text-center p-4">
        <h4 class="dark-grey text-center bold uncapitalize">{{ $case('Annual Change', 'title') }}</h4>
        <h2 class="blue text-center">
          <currency-value :value="presentValue - pastValue" :decimal-places="0"/>
        </h2>
        <p class="small-text text-center">Total net return from {{ pastDate.format('DD-MM-YYYY') }} to
          {{ presentDate.format('DD-MM-YYYY') }}</p>
      </div>
    </div>
    <div class="clarity-card-inner show-small">
      <div class="flex align-items-center flex-column justify-content-evenly" style="height: 100%;">
          <div class="flex text-center p-3">
              <div v-if="loadingPresent" align="center">
                  <Skeleton class="mb-2" height="2rem" borderRadius="16px"></Skeleton>
                  <Skeleton class="mb-3" width="12rem" borderRadius="16px"></Skeleton>
              </div>
              <div v-else>
                  <h2 class="blue text-center">
                      <currency-value :value="presentValue" :decimal-places="0"/>
                  </h2>
                  <p class="small-text text-center">Value as at {{ presentDate.format('DD-MM-YYYY') }}</p>
              </div>
          </div>
          <separator-h />
          <div class="flex text-center p-3">
              <div v-if="loadingPast" align="center">
                  <Skeleton class="mb-2" height="2rem" borderRadius="16px"></Skeleton>
                  <Skeleton class="mb-3" width="12rem" borderRadius="16px"></Skeleton>
              </div>
              <div v-else>
                  <h2 class="blue text-center">
                      <currency-value :value="pastValue" :decimal-places="0"/>
                  </h2>
                  <p class="small-text text-center">Value as at {{ pastDate.format('DD-MM-YYYY') }}</p>
              </div>
          </div>
          <separator-h />
          <div v-if="fullPerformance" class="flex text-center p-3">
              <div class="flex flex-column">
                <h4 class="dark-grey text-center bold uncapitalize">{{ $case('Annualised Return', 'title') }}</h4>
                <div class="inline-flex align-items-center justify-content-center">
                    <h2 class="blue text-center">
                        <currency-value :value="presentValue - (pastValue+netMovement)" :decimal-places="0"/>
                    </h2>
                    <h3 class="light-blue ml-3">({{ $nb(goalseek, 'percentage') }})</h3></div>
                <p class="small-text text-center">Total net return from {{ pastDate.format('DD-MM-YYYY') }} to
                    {{ presentDate.format('DD-MM-YYYY') }}</p>
              </div>
          </div>
          <div v-else class="flex text-center p-3">
              <div class="flex flex-column">
                <h4 class="dark-grey text-center bold uncapitalize">{{ $case('Annual Change', 'title') }}</h4>
                <h2 class="blue text-center">
                    <currency-value :value="presentValue - pastValue" :decimal-places="0"/>
                </h2>
                <p class="small-text text-center">Total net return from {{ pastDate.format('DD-MM-YYYY') }} to
                    {{ presentDate.format('DD-MM-YYYY') }}</p>
              </div>
          </div>
      </div>
    </div>
  </div>
</template>
<script>

import dayjs from 'dayjs'
import {computed, ref, onMounted, watch} from "vue";
import {useStore} from 'vuex'
import api from '@/core/services/api'
import {toDrupalRequest} from '@/core/helpers';
import CurrencyValue from "../common/CurrencyValue";
import SeparatorV from "@/components/common/layout/SeparatorV.vue";
import SeparatorH from "@/components/common/layout/SeparatorH.vue";
import goalSeekDataFormatter from "@/core/services/goalSeekDataFormatter";
import NewPerformanceCalc from "@/core/services/newPerfomanceCalc";
import {createThrottledComputed} from "../../core/throttleComputed";
import {createDebouncedComputed} from "../../core/debounceComputed";

export default {
  name: "ValuationPerformance",
  components: {CurrencyValue, SeparatorV, SeparatorH},
  setup() {
    const store = useStore()

    const presentValue = ref(0);
    const pastValue = ref(0);

    const callFilters = computed(() => store.getters.valuationDateCallFilters)
    const policySelected = computed(() => store.getters.policySelected)
    const fullSelected = computed(() => {
      if (policySelected.value) {
        return [policySelected.value.value]
      } else {
        return store.getters.policyUUIDArray
      }
    })

    watch(fullSelected, (value) => {
        if(fullPerformance.value){
            value.forEach(plan => {
                store.dispatch('getPerformanceTransactionsForPolicy', {
                    value: plan,
                    'from': pastDate.value.format('YYYY-MM-DD'),
                    'to': presentDate.value.format('YYYY-MM-DD')
                })
            })
        }
    })

    const fullPerformance = computed(() => store.getters.allPolicyPerformance)
    const presentDate = computed(() => {
      const date = store.state.valuation.valuationDate;
      return new dayjs(date)
    })

    const pastDate = computed(() => {
      const date = store.state.valuation.valuationDate;
      return new dayjs(date).subtract(1, "year")
    })

    const loadingPresent = ref(true);
    const loadingPast = ref(true);

    onMounted(() => {
      loadValues(callFilters.value)
    })

    const loadValues = (filter) => {
      loadingPresent.value = true
      loadingPast.value = true
      loadPresentValues(filter);
      loadPastValues(filter);
    }

    const loadPresentValues = async (filter) => {
      let request
      if (policySelected.value) {
        request = `${toDrupalRequest('valuation/plan/summary/' + policySelected.value.value + '/' + presentDate.value.format('YYYY-MM-DD'))}`
      } else {
        request = `${toDrupalRequest('valuation/summary/total', filter)}`
      }
      const presentResponse = await api.get(request)
      if (presentResponse) {
        loadingPresent.value = false;
        presentValue.value = presentResponse.total;
      }
    }

    const loadPastValues = async (filter) => {
      let request
      if (policySelected.value) {
        request = `${toDrupalRequest('valuation/plan/summary/' + policySelected.value.value + '/' + pastDate.value.format('YYYY-MM-DD'))}`
      } else {
        // update the filter date
        filter.date = pastDate.value.format('YYYY-MM-DD')
        request = `${toDrupalRequest('valuation/summary/total', filter)}`
      }
      const pastResponse = await api.get(request)
      if (pastResponse) {
        loadingPast.value = false;
        pastValue.value = pastResponse.total;
      }
    }

    const netMovement = computed(() => {
      let movement = 0;
      allTransactions.value.in.forEach((transaction) => {
        movement += transaction.value;
      })

      allTransactions.value.out.forEach((transaction) => {
        movement += transaction.value;
      })

      return movement;
    })

    const allTransactions = createDebouncedComputed(
        computed(() => {
            const transactions = {
                in: [],
                out: []
            }

            if (fullPerformance.value && !loadingPast.value && !loadingPresent.value) {
                fullSelected.value.forEach((plan) => {
                    // use perform
                    const trans = store.getters.findPolicyByID(plan)
                    if (trans) {
                        transactions.in.push(trans['transactions']['credits'])
                        transactions.out.push(trans['transactions']['debits'])
                    }

                })
            }
            transactions.in = transactions.in.flat()
            transactions.out = transactions.out.flat()

            return transactions;
        }),
        1000
    )

    const goalseek = createThrottledComputed(
        computed(() => {

          const vd = store.getters.valuationDate
          const f = new dayjs(vd).subtract(1, 'year')
          const t = new dayjs(vd)

          const gsdf = new goalSeekDataFormatter()
          const gsa = gsdf.getDateArray(f, t)
          const filteredIn = gsdf.filterTransactions(gsa, allTransactions.value.in)
          const i = gsdf.calculateMonthlyTotals(filteredIn)
          const filteredOut = gsdf.filterTransactions(gsa, allTransactions.value.out)
          const o = gsdf.calculateMonthlyTotals(filteredOut)

          const g = new NewPerformanceCalc({
            performance: 1,
            openingBalance: pastValue.value,
            closingBalance: presentValue.value,
            moneyIn: i,
            moneyOut: o,
          });

          return g.performanceValue()
        }),
        1000
    );

    watch(callFilters, (value) => {
      loadValues(value)
    })

    watch(policySelected, () => {
      loadValues(callFilters.value)
    })

    return {
      presentDate,
      pastDate,
      presentValue,
      pastValue,
      loadingPresent,
      loadingPast,
      fullPerformance,
      fullSelected,
      goalseek,
      netMovement
    }
  },
}
</script>
<style scoped>
.clarity-card-inner {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 600px;
    border-radius: 27px;
    background: var(--clarity-pure-white);
    border: 1px solid var(--clarity-light-grey);
}

.show-small {
    display: none;
    @media only screen and (max-width: 980px) {
        display: block;
    }
}

@media only screen and (max-width: 768px) {
    .clarity-card-inner.show-small {
        height: 350px;
    }
}

.show-big {
    display: block;
    @media only screen and (max-width: 980px) {
        display: none !important;
    }
}

</style>
