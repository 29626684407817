<template>
  <Dialog v-model:visible="showRevokeDialog" class="revoke-dialog">
    <div class="flex justify-content-between modal-header">
      <h3 class="blue">Revoke Access</h3>
      <Button class="clarity-close-modal-btn" @click="toggleRevokeDialog"><span
          class="material-icons-round grey">close</span></Button>
    </div>
    <div class="py-3">
      <p class="body-text">Are you sure you want to remove this account?</p>
      <p class="body-text">Any other bank accounts from the same provider will also be removed from your valuation
        report.</p>
    </div>
    <div class="flex justify-content-between">
      <Button class="clarity-btn clarity-gradient-grey" @click="toggleRevokeDialog">Dismiss</Button>
      <submit-button @submitted="confirmRevoke" :loading="loadingState" button-text="Proceed" loading-text="Revoking access..." ></submit-button>
    </div>
  </Dialog>
</template>

<script>
import Dialog from "primevue/dialog";
import {computed, toRef} from "vue";
import {useStore} from "vuex";
import SubmitButton from "@/components/common/layout/interactive/SubmitButton";


export default {
  name: 'RevokeAccessDialog',
  components: {
    SubmitButton,
    Dialog
  },
  props: {
    id: String,
    show: Boolean
  },
  emits: ['toggleRevokeDialog'],
  setup(props, context) {
    const store = useStore()
    const uuid = toRef(props, 'id')
    const showRevokeDialog = toRef(props, 'show')
    const loadingState = computed(() => store.getters.isLoading('RevokeOpenBanking'))


    const toggleRevokeDialog = () => {
      // showRevokeDialog.value = !showRevokeDialog.value
      context.emit('toggleRevokeDialog')
    }

    const confirmRevoke = async () => {
      await store.dispatch('setLoading', {id: 'RevokeOpenBanking', state: true})
      await store.dispatch('revokeAccess', uuid.value)
      await store.dispatch('setLoading', {id: 'RevokeOpenBanking', state: false})
      toggleRevokeDialog()
    }

    return {
      confirmRevoke,
      toggleRevokeDialog,
      showRevokeDialog,
      loadingState
    }
  }
}
</script>
<style scoped lang="scss">
.revoke-dialog {
  .clarity-btn {
    padding: 15px 45px;
  }
}
</style>
