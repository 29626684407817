<template>
      <div ref="valuationchart" class="valuation-history-chart">

      </div>
</template>

<script>
    import * as am5 from '@amcharts/amcharts5';
    import * as am5xy from '@amcharts/amcharts5/xy';
    import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
    import {ref, watch, computed, onMounted } from 'vue'
    import {useStore} from "vuex";
    import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
    import {formatter} from "../../core/services/formatter";

    export default {
      name: 'ValuationHistoryChart',
      setup() {

        const store = useStore();

        const series = ref(null);
        const chartRendered = ref(false);
        const valuationchart = ref(null);
        const callFilters = computed(() => {
          const filters = store.getters.valuationDateCallFilters;
          filters.period = store.state.selections.periodSelected;
          return filters;
        })
        const policySelected = computed(() => store.getters.policySelected );
        const exporting = ref();

        const chartData = computed(() => {
          let theData;
          if(policySelected.value) {
            theData = store.state.valuation.valuationPolicyChartData;
          }else{
            theData = store.state.dashboard.dashboardChartData;
          }
          if (theData && theData.error) {
            store.dispatch('addNotification', {severity: 'error', summary: 'Valuation Summary Chart', detail: theData.message})
            return false;
          } else {
            return theData;
          }
        })

        const selectedOption = computed(() => store.getters.currencySelected );
        const currencyConvertData = (data) => {
          const convertedData = [];
          data.forEach((record) =>{
            convertedData.push({
              "date": record.date,
              "formatted": record.formatted,
              "value": formatter.currency(selectedOption.value, record.value, 2, true)
            })
          })
          return convertedData;
        }

        watch(callFilters, (value) => {
          getChartData(value);
        })

        watch(policySelected, () => {
          getChartData(callFilters.value);
        })

        onMounted(() => {
          getChartData(callFilters.value);
        })

        watch(chartData, (value) => {
          const converted = currencyConvertData(value);
          if(chartRendered.value){
            updateChart(series, converted)
          }else{
            renderChart(series, converted)
            chartRendered.value = true;
          }
        })

        watch(selectedOption, () => {
          if(chartData.value) {
            const converted = currencyConvertData(chartData.value);
            if (chartRendered.value) {
              updateChart(series, converted)
            } else {
              renderChart(series, converted)
              chartRendered.value = true;
            }
          }
        })

        const getChartData = (filters) => {
          if(policySelected.value){
            store.dispatch('getPolicyValuationSummaryChartData', filters)
          }else {
            store.dispatch('getDashboardChartData', filters)
          }
        }

        const renderChart = (series, data) => {

          let root = am5.Root.new(valuationchart.value);
          root.setThemes([am5themes_Animated.new(root)]);

          root.numberFormatter.setAll({
            numberFormat: "#,###.00a",

            // Group only into M (millions), and B (billions)
            bigNumberPrefixes: [
              { "number": 1e+6, "suffix": "M" },
              { "number": 1e+9, "suffix": "B" }
            ],

            // Do not use small number prefixes at all
            smallNumberPrefixes: []
          });

          let dotGradient = am5.LinearGradient.new(root, {
            stops: [{
              color: am5.color("#179BD7")
            }, {
              color: am5.color("#0058A9")
            }]
          });

          let chart = root.container.children.push(am5xy.XYChart.new(root, {
            width: am5.percent(100),
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 20,
            paddingBottom: 20,
          }));

          let tooltip = am5.Tooltip.new(root, {
            getFillFromSprite: false,
            autoTextColor: false,
            getLabelFillFromSprite: false,
            labelText: "{valueY}"
          });

          tooltip.get("background").setAll({
            fillGradient: dotGradient
          });

          tooltip.label.setAll({
            fill:  am5.color("#FFFFFF")
          });

          let xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
            renderer: am5xy.AxisRendererX.new(root, {
              minGridDistance: 50,
            }),
            maxDeviation: 0.5,
            baseInterval: {
              timeUnit: "day",
              count: 1
            },
            gridInterval: [
              {timeUnit: "day", count: 1},
            ],
            startLocation: 0.5,
            endLocation: 0.5,
          }));

          let xRenderer = xAxis.get("renderer");
          xRenderer.labels.template.setAll({
            fill: am5.color(0x8B96A2),
          });

          xRenderer.grid.template.setAll({
            location: 0.5,
            stroke: am5.color("#DCE1E6"),
            strokeWidth: 1,
            strokeOpacity: 1
          });

          let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
            maxDeviation:1,
            //spacing at the top of the chart
            extraMax: 0.2,
            // spacing at the bottom of the chart
            extraMin: 0.4,
            renderer: am5xy.AxisRendererY.new(root, {
              minGridDistance: 80,
            })
          }));

          let yRenderer = yAxis.get("renderer");
          yRenderer.labels.template.setAll({
            fill: am5.color(0x8B96A2),
            minPosition: 0.1,
            maxPosition: 0.9
          });

          yRenderer.grid.template.setAll({
            stroke: am5.color("#DCE1E6"),
            strokeWidth: 1,
            strokeOpacity: 1
          });

          series.value = chart.series.push(am5xy.SmoothedXLineSeries.new(root, {
            name: "Series",
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "value",
            valueXField: "date",
            locationX: 0.5,
            locationY: 0,
            connect: true,
            tooltip: tooltip,
          }));

          series.value.fills.template.setAll({
            visible: true,
            fillGradient: am5.LinearGradient.new(root, {
              stops: [{
                color: am5.color("#179BD7"),
                opacity: 1
              },
                {
                  color: am5.color("#7CC2E2"),
                  opacity: 0
                }]
            }),
            fillOpacity: 0.7
          });

          series.value.strokes.template.setAll({
            strokeWidth: 6,
            strokeGradient: am5.LinearGradient.new(root, {
              stops: [{
                color: am5.color("#179BD7")
              }, {
                color: am5.color("#0058A9")
              }]
            })
          });

          series.value.bullets.push(function() {
            return am5.Bullet.new(root, {
              locationY: 0,
              locationX: 0.5,
              sprite: am5.Circle.new(root, {
                radius: 5,
                stroke: root.interfaceColors.get("background"),
                strokeWidth: 2,
                fillGradient: dotGradient
              })
            });
          });

          series.value.data.setAll(data);

          series.value.appear(1000);
          chart.appear(1000, 100);

          // Add cursor
          // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
          var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
            snapToSeries: [ series.value ]
          }));
          cursor.lineY.set("visible", false);

          exporting.value = am5plugins_exporting.Exporting.new(root, {});
          setTimeout(function () {
            exportImage();
          } , 1000);

        }

        const exportImage = () => {
          return exporting.value.export("png").then(function(imgData) {
                store.commit('setPDFGraph1', imgData);
          });
        }

        const updateChart = (series, data) => {
          series.value.data.setAll(data);
          setTimeout(function () {
                exportImage();
          } , 1000);
        }

        return {
          series,
          chartRendered,
          valuationchart,
          exportImage
        }
      }
    }
</script>

<style scoped lang="scss">
    .valuation-history-chart {
      position: relative;
      width: 100%;
      height: 600px;
    }
</style>
