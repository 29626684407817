<template>
  <div class="body flex justify-content-between align-items-center">
    <h4>Policies</h4>
    <div>
      <Button class="mr-2 mb-2 p-button p-component p-button-icon-only clarity-btn policy-btn" :class="{ 'p-button-outlined': activeView === 'list', 'p-button-text': activeView != 'list' }" @click="setView('list')" v-tooltip.top="'List View'">
        <span class="material-icons-round blue">view_list</span>
      </Button>
      <Button class="mr-2 mb-2 p-button p-component p-button-icon-only clarity-btn policy-btn" :class="{ 'p-button-outlined': activeView === 'grid', 'p-button-text': activeView != 'grid' }" @click="setView('grid')" v-tooltip.top="'Grid View'">
        <span class="material-icons-round blue">grid_view</span>
      </Button>
    </div>
  </div>
  <div>
    <div v-if="activeView === 'grid'">
      <div class="grid">
        <div v-for="policy in policyOrder" :key="policy.value" v-visible="() => loadGridItem(policy.value)" class="col-12 md:col-6 xl:col-4" style="min-height: 400px;">
          <inner-card v-if="isGridItemLoaded(policy.value)" :class="{ expired: isExpired(policy.value) }">
            <template #content>
                <policy-grid-item :planuuid="policy.value"></policy-grid-item>
            </template>
          </inner-card>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="grid">
        <div v-for="policy in policyOrder" :key="policy.value" :id="`pol-${policy.value}`"  v-visible="() => loadListItem(policy.value)" class="col-12 mb-5" style="scroll-margin-top: 150px; min-height: 400px;">
          <inner-card v-if="isListItemLoaded(policy.value)" :class="{ expired: isExpired(policy.value) }">
            <template #content>
              <policy-list-item :planuuid="policy.value"></policy-list-item>
            </template>
          </inner-card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import {computed, inject, onBeforeUnmount, ref, watch} from "vue";
import InnerCard from '@/components/common/InnerCard'
import {useStore} from "vuex";
import PolicyGridItem from "./PolicyGridItem";
import PolicyListItem from "./PolicyListItem";
import visibleDirective from "../../core/v-visible";


export default {
  name: "ValuationPolicies",
  components: {PolicyListItem, InnerCard, PolicyGridItem},
  directives: {
      visible: visibleDirective
  },
  setup() {

    const store = useStore()
    const bus = inject('bus')
    const activeView = ref('grid');
    const focusOn = ref('')

    const setView = (view) => {
      activeView.value = view;
    }

    watch(focusOn, (val) => {
      scrollTo(val)
    })

    const policySelected = computed(() => store.getters.policySelected );

    const scrollTo = (el) => {
      setTimeout(function () {
        const element = document.getElementById(el)
        if (element) {
          element.scrollIntoView({behavior: 'smooth', block: 'start'})
        }
      }, 1000);
    }

    bus.on('scrollToListView', (planstring) => {
      focusOn.value = `pol-${planstring}`
      setView('list')
      scrollTo(planstring)
    })

    const policyOrder = computed(() => {
      const policyOrder = [];
      store.getters.policyOptions.forEach((policyGroup) => {
        policyGroup.items.forEach((policy) => {
          if(policySelected.value){
            if(policy.value === policySelected.value.value){
              policyOrder.push(policy);
            }
          }else {
            policyOrder.push(policy);
          }
        });
      })
      return policyOrder;
    })

    const loadedGridItems = ref([]);
    function loadGridItem(i) {
        if (!loadedGridItems.value.includes(i)) {
            loadedGridItems.value.push(i);
        }
    }
    function isGridItemLoaded(i) {
        return loadedGridItems.value.includes(i);
    }

    const loadedListItems = ref([]);
    function loadListItem(i) {
        if (!loadedListItems.value.includes(i)) {
            loadedListItems.value.push(i);
        }
    }
    function isListItemLoaded(i) {
        return loadedListItems.value.includes(i);
    }

    onBeforeUnmount(() => {
        bus.off('scrollToListView')
    });

    const isExpired = (account) => {
      const policyInfo = store.getters.policyBasicInfo(account)
      if(Object.prototype.hasOwnProperty.call(policyInfo, 'consent_expires')) {
        return calculateDaysFromToday(policyInfo['consent_expires']) < 0
      }
      return false;
    }

    const calculateDaysFromToday = (date) => {
      const today = new Date();
      const givenDate = new Date(date);

      // Calculate the difference in milliseconds
      const diffInMillis = givenDate - today;

      // Convert milliseconds to days
      const diffInDays = Math.round(diffInMillis / (1000 * 60 * 60 * 24));

      return diffInDays;
    }

    return {
      policyOptions: computed(() => store.getters.policyOptions),
      policyOrder,
      activeView,
      setView,
      loadGridItem,
      isGridItemLoaded,
      loadListItem,
      isListItemLoaded,
      isExpired
    }
  }
}
</script>
<style scoped>
.policy-btn {
  box-shadow: none;
  border: none;
}
.policy-btn.p-button-outlined {
  background-color: var(--clarity-snow-grey);
}
.col-4 {
  padding: 1.5rem !important;
}

@media only screen and (max-width: 1024px) {
    :deep(.content) {
        padding: 18px;
    }
}
</style>
