<template>
  <div class="policy-holdings">
    <div v-if="loadingHoldings">
      <Skeleton class="mt-4" width="100%" height="150px"></Skeleton>
    </div>
    <div v-else>
      <DataTable :value="holdingsFull" class="rd-table" scrollable scrollHeight="600px" id="HoldingsTable">
        <Column header="Holding Name" field="name" sortable class="holdingname">
          <template #body="slotProps">
            <div class="flex align-items-center">
              <h3 class="mr-2 light-blue">{{ slotProps.data.name }}</h3>
              <Icon v-if="slotProps.data.isESG == 1" name="leaf-icon" altText="ESG_fund"/>
            </div>
          </template>
        </Column>
        <Column field="units" header="Units" style="max-width: 170px;" class="hide-column" sortable/>
        <Column field="price" header="Price" style="max-width: 130px;" class="hide-column" sortable>
          <template #body="slotProps">
            <currency-value :value="parseFloat(slotProps.data.price)" :decimal-places="4" :show-symbol="false"/>
          </template>
        </Column>
        <Column field="priceDate" header="Date" style="max-width: 120px;" class="hide-column" sortable>
          <template #body="slotProps">
            {{ $date(slotProps.data.priceDate, 'DD-MM-YYYY') }}
          </template>
        </Column>
        <Column field="value" header="Value" style="max-width: 170px;" sortable>
          <template #body="slotProps">
            <currency-value :value="parseFloat(slotProps.data.value)"/>
          </template>
        </Column>
        <Column header="More Info" style="max-width: 125px;">
          <template #body="slotProps">
            <div class="flex justify-content-around" style="width: 100%">
              <div class="holding-action" @click="toggleModal('fundInfo', slotProps.data)" v-tooltip.top="'Fund Info'">
                <span class="material-icons-round grey">info</span>
              </div>
              <div class="holding-action" @click="toggleModal('fundHistory', slotProps.data)"
                   v-tooltip.top="'Historial Price Chart'">
                <span class="material-icons-round grey">history</span>
              </div>
              <div class="holding-action" @click="toggleModal('fundAllocation', slotProps.data)"
                   v-tooltip.top="'Asset Allocation'">
                <span class="material-icons-round grey">bar_chart</span>
              </div>
            </div>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>
<script>

import {onMounted, ref, toRef, computed, watch} from "vue";
import api from '@/core/services/api'
import {toDrupalRequest} from '@/core/helpers';
import Icon from '@/components/common/Icon.vue'
import CurrencyValue from '../common/CurrencyValue.vue';
import dayjs from "dayjs";
import {useStore} from "vuex";

export default {
  name: "PolicyHoldings",
  props: {
    planuuid: String
  },
  emits: ['toggleDialog'],
  components: {
    Icon,
    CurrencyValue
  },
  setup(props, context) {
    const store = useStore()

    const uuid = toRef(props, 'planuuid')
    const holdingsFull = ref([]);
    const loadingHoldings = ref(true);

    const valuationDate = computed(() => {
      const date = store.state.valuation.valuationDate;
      return new dayjs(date)
    })

    watch(valuationDate, () => {
        loadHoldings()
    })

    onMounted(() => {
      loadHoldings()
    })

    const loadHoldings = async () => {
      loadingHoldings.value = true
      const response = await api.get(`${toDrupalRequest('valuation/plan/holdings/' + uuid.value + '/' + valuationDate.value.format('YYYY-MM-DD'))}`)
      if (response) {
        const holdingsLoaded = []
        response.holdings.forEach((holding) => {
          holding.value = (holding.price * holding.units).toFixed(2);
          holdingsLoaded.push(holding);
        });
        holdingsLoaded.sort((a, b) => {
              // Convert both names to lowercase to ensure case-insensitive comparison
              const nameA = a.name.toLowerCase();
              const nameB = b.name.toLowerCase();
              // Compare the names
              if (nameA < nameB) {
                  return -1;
              }
              if (nameA > nameB) {
                  return 1;
              }
              // Names are equal
              return 0;
        });
        holdingsFull.value = holdingsLoaded;
        loadingHoldings.value = false;
      }
    }

    const toggleModal = async (modal, isin) => {
      context.emit('toggleDialog', modal)
      let current = await store.getters.getCurrentISIN
      if (isin !== current) {
        store.dispatch('setCurrentISIN', isin)
      }
    }

    return {
      uuid,
      holdingsFull,
      loadingHoldings,
      loadHoldings,
      toggleModal,
    }
  }
}
</script>
<style scoped>

.holding-action {
  cursor: pointer;
}

:deep(.holdingname) {
   min-width: 450px;
   width: 55%;
   @media only screen and (max-width: 900px) {
        min-width: 220px;
        padding-left: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-right: 2px;
   }
}

:deep(.holdingname h3){
    @media only screen and (max-width: 1000px) {
        font-size: 16px !important;
    }
    @media only screen and (max-width: 900px) {
        font-size: 14px !important;
    }
}

</style>
