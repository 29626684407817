<template>
  <content-card-full>
    <template #title>
      <card-title>
        <template #title><h2 class="title">Transactions</h2></template>
        <template #content>
          <div class="flex align-items-center">
            <span class="mr-3">Filters:</span>
            <Button class="clarity-btn round grey clarity-gradient-grey" @click="toggleFilters"><span
                class="material-icons-round blue">filter_alt</span></Button>
          </div>
        </template>
        <template #filters>
          <FilterGroup :showOptions="['portfolios', 'people', 'currency', 'transactions']" toggleKey="transactionfilter"/>
        </template>
      </card-title>
    </template>
    <template #content-full>
      <div class="flex flex-wrap justify-content-evenly filter-button-group">
          <div class="flex flex-grow-1 justify-content-evenly filter-button-group mb-3">
              <div class="mr-3" style="width: 50%;">
                <DownloadButton :items="items" id="transactions-tab"/>
              </div>
              <Button class="clarity-btn clarity-gradient-grey" @click="togglePerformance" style="width: 50%;">
                  <span v-if="showPerformance" class="material-icons-outlined grey mr-3 cursor-pointer">check_box</span>
                  <span v-else class="material-icons-outlined grey mr-3 cursor-pointer">check_box_outline_blank</span>
                  Show Performance
              </Button>
          </div>
          <div class="flex flex-grow-1 justify-content-evenly filter-button-group mb-3">
            <Button class="clarity-btn clarity-gradient-grey" @click="$router.push('/investments/portfolios')" style="width: 50%;">Manage
              Portfolios
            </Button>
            <Button class="clarity-btn clarity-gradient-grey" @click="updateTransactions" style="width: 50%;">Update Feeds</Button>
          </div>
      </div>
      <div class="mt-5">
        <h4 class="mb-3">Reporting Transactions</h4>
        <div v-for="policy in policyOrder" :key="policy.name" class="my-5">
          <transactions-card :policy="policy" :expanded="expandFirst(policy, policyOrder)" :dateRange="dateRange"
                             :showPerformance="showPerformance"/>
        </div>
      </div>
    </template>
  </content-card-full>
</template>
<script>
import dayjs from 'dayjs'
import {computed, reactive, ref, inject, onMounted} from 'vue'
import {useStore} from 'vuex'
import {buildTransactionExcelPayload} from "@/core/services/documentExport/transactionExcelBuilder"
//import {buildTransactionPDFString} from "@/core/services/documentExport/transactionPdfBuilder";
import CardTitle from '../common/CardTitle.vue'
import ContentCardFull from '../common/ContentCardFull.vue'
import DownloadButton from '@/components/common/layout/interactive/DownloadButton.vue'
import FilterGroup from '@/components/common/filters/FilterGroup.vue'
import TransactionsCard from '../transactions/TransactionsCard.vue';
import {downloadDocument} from "../../core/services/documentDownload";
import {useRouter} from "vue-router";


export default {
  setup() {
    const store = useStore()
    const router = useRouter()
    const showPerformance = ref(false)
    const expanded = ref([])
    const bus = inject('bus')

    onMounted(() => {
      store.dispatch('getPolicyOptions')
    })

    const togglePerformance = () => {
      console.log('toggle called!');
      showPerformance.value = !showPerformance.value
      showPerformance.value ? store.dispatch('setActiveModal', {origin: 'transactions'}) : store.dispatch('setActiveModal')
    }

    const policyOrder = computed(() => {
      const policyOrder = [];
      store.getters.policyOptions.forEach((policyGroup) => {
        policyGroup.items.forEach((policy) => {
          policyOrder.push(policy);
        });
      })

      return policyOrder;
    })

    const expandFirst = (policy, policyOrder) => {
      return policy == policyOrder[0]
    }

    const from = computed(() => dayjs(store.state.valuation.dateRangeFrom).format('YYYY-MM-DD'))
    const to = computed(() => dayjs(store.state.valuation.dateRangeTo).format('YYYY-MM-DD'))

    const dateRange = reactive({
      from: from,
      to: to
    })

    const updateTransactions = () => {
      bus.emit('refreshTransactions')
      router.push('/investments/datafeeds');
    }

    const showFilters = computed(() => store.getters.getElementVisibility('transactionfilter'));

    const toggleFilters = () => {
        store.dispatch('updateElementVisibility', { "key": 'transactionfilter', "value": !showFilters.value})
    }

    const portfolio = computed(() => store.getters.portfolioSelected)
    const fullPortfolioSelected = computed(() => store.getters.fullPortfolioSelected)

    const getHumanReadablePortfolioName = () => {
      switch (portfolio.value) {
        case 'nonpens':
          return 'NONPENSION'
        case 'pens':
          return 'PENSION'
        case 'all':
          return 'ALLHOLDINGS'
        default:
          // assumption here is that ISA and IMS will remain the same
          return fullPortfolioSelected.value.name.replace(" ","");
      }
    }

    const adviser = computed(() => store.getters.getAdviserName)

    /*const downloadPDF = async () => {
      const transactionsPdf = await buildTransactionPDFString(dateRange, {name: adviser.value})

      if (transactionsPdf) {
        const fileName = `TransactionsReport_${getHumanReadablePortfolioName()}_${dayjs().format('DDMMYYYY')}.pdf`
        downloadDocument('pdf', transactionsPdf, fileName, 'transactions_pdf')
      }
    }*/

    const downloadExcel = async () => {
      const transactionsExcel = await buildTransactionExcelPayload(dateRange, {name: adviser.value})

      if (transactionsExcel) {
        const fileName = `TransactionsReport_${getHumanReadablePortfolioName()}_${dayjs().format('DDMMYYYY')}.xlsx`
        downloadDocument('excel', transactionsExcel, fileName, 'ClientTransactions')
      }
    }

    const items = ref([
      /*{
        key: '0_0',
        label: 'PDF',
        icon: 'pdf-icon',
        command: () => {
          // PDF bits to go here
          downloadPDF();
          store.dispatch('setLoading', {id: 'transactions_pdf', state: true})
        }
      },*/
      {
        key: '0_1',
        label: 'Excel',
        icon: 'description',
        command: () => {
          downloadExcel()
          store.dispatch('setLoading', {id: 'ClientTransactions', state: true})
        }
      }
    ])

    return {
      dateRange,
      expanded,
      policyOrder,
      showPerformance,
      togglePerformance,
      toggleFilters,
      expandFirst,
      updateTransactions,
      selectedOption: computed(() => store.getters.portfolioSelected),
      items,
    }
  },
  components: {
    CardTitle,
    ContentCardFull,
    DownloadButton,
    FilterGroup,
    TransactionsCard
  },
}
</script>
<style scoped>
.title {
  color: var(--clarity-blue);
}

h5 {
  margin: 0px 10px 0px 0px;
}

div:deep(.p-paginator) {
  border-width: 0px !important;
}

.filter-button-group button {
  justify-content: center;
  width: 25%;
  margin-right: 14px;
}
</style>
