<template>
      <div ref="allocationvariancechart" class="allocation-variance-chart">

      </div>
</template>

<script>
    import * as am5 from '@amcharts/amcharts5';
    import * as am5xy from '@amcharts/amcharts5/xy';
    import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
    import {ref, watch, toRef, onMounted, computed} from 'vue'
    import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";

    export default {
      name: 'AllocationVarianceChart',
      props: {
        chartData: Object,
        keys: Array,
        barWidth: Number
      },
      setup(props) {

        const series = ref([]);
        const chartRendered = ref(false);
        const allocationvariancechart = ref(null);
        const exporting = ref();

        const chartData = toRef(props, 'chartData')
        const keys = toRef(props, 'keys')
        const barWidth = toRef(props, 'barWidth')

        const tidyChartData = computed(() => {
          const originalData = chartData.value;
          if(originalData) {
            let removeVariance = originalData.filter(record => record.allocation !== 'variance')

            let currentData = {}
            let benchmarkData = {}

            keys.value.forEach((key) => {
              currentData[key.value] = removeVariance[0][key.value]*1
              benchmarkData[key.value] = removeVariance[1][key.value]*1
            })

            var returnData = [];
            keys.value.forEach((key) => {
              returnData.push({
                allocation: key.label,
                current: currentData[key.value],
                benchmark: benchmarkData[key.value]
              })
            })

            return returnData;

          }else{
            return false;
          }
        })

        onMounted(() => {
          if(tidyChartData.value) {
            renderChart(series, tidyChartData.value)
            chartRendered.value = true;
          }
        })

        watch(tidyChartData, (value) => {
          if(chartRendered.value){
            updateChart(series, value)
          }else{
            renderChart(series, value)
            chartRendered.value = true;
          }
        })

        const renderChart = (series, data) => {

          am5.addLicense("AM5C334338446");

          let root = am5.Root.new(allocationvariancechart.value);
          root.setThemes([am5themes_Animated.new(root)]);

          // Create chart
          // https://www.amcharts.com/docs/v5/charts/xy-chart/
          var chart = root.container.children.push(am5xy.XYChart.new(root, {
            panX: false,
            panY: false,
            layout: root.verticalLayout
          }));

          chart.get("colors").set("colors", [
            am5.color("#179BD7"),
            am5.color("#F18903")
          ]);

          const linearGradient = am5.LinearGradient.new(root, {
            stops: [{
              opacity: 1
            }, {
              opacity: 0.2
            }],
            rotation: 90
          })

          /*const linearGradient2 = am5.LinearGradient.new(root, {
            stops: [{
              opacity: 0
            }, {
              opacity: 0
            }],
            rotation: 90
          })*/

          // Add legend
          // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
          var legend = chart.children.push(
              am5.Legend.new(root, {
                centerX: am5.p50,
                x: am5.p50,
                useDefaultMarker: true
              })
          );

          legend.labels.template.setAll({
            fontSize: 14,
            fontWeight: "400",
            marginLeft: 18,
            centerY: 6
          });

          legend.markerRectangles.template.setAll({
            rotation: 180,
            cornerRadiusTL: 15,
            cornerRadiusTR: 15,
            cornerRadiusBL: 15,
            cornerRadiusBR: 15,
            width: 25,
            height: 25,
            dx: 24,
            dy: 24,
          });

          var xRenderer = am5xy.AxisRendererX.new(root, {
            minGridDistance: 15,
            cellStartLocation: 0.15,
            cellEndLocation: 0.85,
            gridThickness: 0,
            width: 95
          });
          xRenderer.labels.template.setAll({
            paddingRight: 15
          });


          // Create axes
          // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
          var xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
            categoryField: "allocation",
            renderer: xRenderer,
            tooltip: am5.Tooltip.new(root, {}),
          }));

          xAxis.data.setAll(data);

          var yRenderer = am5xy.AxisRendererY.new(root, {})

          var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
            renderer: yRenderer
          }));

              // Add series
          // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
          function makeSeries(name, fieldName) {
            series.value[fieldName] = chart.series.push(am5xy.ColumnSeries.new(root, {
              name: name,
              xAxis: xAxis,
              yAxis: yAxis,
              valueYField: fieldName,
              categoryXField: "allocation"
            }));

            series.value[fieldName].columns.template.setAll({
              tooltipText: "{name} {categoryX}: {valueY}%",
              width: am5.percent(95),
              tooltipY: 0,
              cornerRadiusTL: barWidth.value/2,
              cornerRadiusTR: barWidth.value/2,
              maxWidth: barWidth.value
            });

            series.value[fieldName].columns.template.set("fillGradient", linearGradient);
            series.value[fieldName].columns.template.set("strokeOpacity", 0);


            series.value[fieldName].data.setAll(data);

            // Make stuff animate on load
            // https://www.amcharts.com/docs/v5/concepts/animations/
            series.value[fieldName].appear();

            series.value[fieldName].bullets.push(function () {
              return am5.Bullet.new(root, {
                locationY: 1,
                sprite: am5.Label.new(root, {
                  text: "{valueY}%",
                  centerX: am5.p50,
                  dy: -22,
                  scale: 0.8,
                  populateText: true
                })
              });
            });

            legend.data.push(series.value[fieldName]);
          }

          makeSeries('Current', 'current');
          makeSeries('Benchmark', 'benchmark');

              // Make stuff animate on load
          // https://www.amcharts.com/docs/v5/concepts/animations/
          chart.appear(1000, 100);

          exporting.value = am5plugins_exporting.Exporting.new(root, {});

        }

        const exportImage = () => {
          exporting.value.export("png").then(function(imgData) {
            console.log('image data is ', imgData);
          });
        }

        const updateChart = (series, data) => {

          series.value['current'].data.setAll(data);
          series.value['benchmark'].data.setAll(data);

        }

        return {
          series,
          chartRendered,
          allocationvariancechart,
          exportImage
        }
      }
    }
</script>

<style scoped lang="scss">
    .allocation-variance-chart {
      position: relative;
      width: 100%;
      height: 450px;
    }
</style>
