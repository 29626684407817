<template>
    <div class="clarity-card-inner">
        <div class="body">
            <div class="content-title">
                <slot name="title"></slot>
            </div>
            <div class="content-chart">
                <slot name="content-chart"></slot>
            </div>
        </div>
    </div>
</template>
<style scoped>
/* duplicated from Inner Card - extract to global style */
.clarity-card-inner {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border-radius: 27px;
    background: var(--clarity-pure-white);
    border: 1px solid var(--clarity-light-grey);
}
.body {
    border-radius: 27px;
    background: white;
}
</style>
