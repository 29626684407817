
export default class pdfGeneration {
    constructor({css, html}) {
        this.css = css;

        // Scaling factor to ensure goalseek works
        this.html = html;

        this.header = '<html>\n' +
            '<head>\n' +
            '    <style>\n' +
            '        /**\n' +
            '        * Set the margins of the PDF to 0\n' +
            '        * so the background image will cover the entire page.\n' +
            '        **/\n' +
            '        @page {\n' +
            '            margin: 0cm 0cm;\n' +
            '        }\n' +
            '\n' +
            '        /**\n' +
            '        * Define the real margins of the content of your PDF\n' +
            '        * Here you will fix the margins of the header and footer\n' +
            '        * Of your background image.\n' +
            '        **/\n' +
            '        body {\n' +
            '            margin-top:    3.5cm;\n' +
            '            margin-bottom: 2cm;\n' +
            '            margin-left:   2.75cm;\n' +
            '            margin-right:  2.75cm;\n' +
            '        }\n' +
            '\n' +
            '        /**\n' +
            '        * Define the width, height, margins and position of the watermark.\n' +
            '        **/\n' +
            '        #watermark {\n' +
            '            position: fixed;\n' +
            '            bottom:   0px;\n' +
            '            left:     0px;\n' +
            '            /** The width and height may change\n' +
            '                according to the dimensions of your letterhead\n' +
            '            **/\n' +
            '            width:    21cm;\n' +
            '            height:   29.7cm;\n' +
            '\n' +
            '            /** Your watermark should be behind every content**/\n' +
            '            z-index:  -1000;\n' +
            '        }\n' +
            '\n' +
            '        /**\n' +
            '         Custom CSS Here\n' +
            '         */';

        this.inner = '</style>\n' +
            '</head>\n' +
            '<body>\n' +
            '    <!-- Custom Content Here -->'


        this.footer = '</body>\n' +
            '</html>\n';

    }

    buildHTML() {
        return this.header+this.css+this.inner+this.html+this.footer;
    }

}



