<template>
    <div class="clarity-card-inner" :class="colourClass">
        <div class="body">
            <div class="content-title">
                <slot name="title"></slot>
            </div>
            <div class="content">
                <slot name="content"></slot>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  props: {
    colourClass: {
      default() {
        return 'clarity-pure-white'
      },
      type: String,
    }
  }
}
</script>
<style scoped>
.clarity-card-inner {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border-radius: 27px;
    border: 1px solid var(--clarity-light-grey);
}
.body {
    border-radius: 27px;
}
.content {
    display: flex;
    flex-direction: column;
    padding: 30px;
}
.grey {
  background: var(--clarity-snow-grey) 0% 0% no-repeat padding-box;
  border: 1px solid var(--clarity-light-grey);
}
.expired {
  background: transparent linear-gradient(180deg, #F2686E33 0%, #D131384D 100%) 0% 0% no-repeat padding-box;
  border: 1px solid #D1313880;
}
</style>
