import {formatter} from "../services/formatter";
import store from "@/store";

export default class PlanTransactionSection {
    constructor(name, owner, rows) {
        this.name = name
        this.owner = owner
        this.rows = rows
    }

    getHeader() {
        return [
            {
                "A": "Holding Name",
                "B": "Description",
                "C": "Date",
                "D": "Units",
                "E": "Price",
                "F": "Value"
            }
        ]
    }

    getContent() {
        let body = []

        const currency = store.state.selections.currencySelected;

        for(let row of this.rows) {
            body.push({
                "A": row.product,
                "B": row.description,
                "C": row.transactionDate,
                "D": row.units,
                "E": formatter.currency(currency, row.price, 2, true),
                "F": formatter.currency(currency, row.value, 2, true)
            })
        }

        return body
    }

    getFooter() {
        return {}
    }


    getSection() {
        return {
            name: this.name,
            owner: this.owner,
            sectionheader: this.getHeader(),
            sectioncontent: this.getContent(),
            sectionfooter: this.getFooter()
        }
    }
}
