<template>
  <div>
    <div class="flex align-items-center justify-content-between">
      <div class="flex flex-column">
        <h3 class="blue">{{ policyBasicInfo.name }}</h3>
        <div class="small-text grey">Owner: <span v-html="getOwnerFromUuid(policyBasicInfo.ownership)"></span></div>
      </div>
      <div class="flex">
        <!-- IMS check -->
        <pill v-if="policyIsIMS" label="IMS Portfolio"  icon="pi pi-check"/>
        <separator-v v-if="policyIsIMS" class="mx-3"  style="border-right-color: var(--clarity-light-grey); height: 24px"/>

        <img v-if="policyBasicInfo.clarityType === 'clarity'" src="@/assets/logos/clarity-logo-small-blue.svg">
        <span v-if="policyBasicInfo.clarityType === 'openbanking'" class="material-icons-round blue">account_balance</span>
        <span v-if="policyBasicInfo.clarityType === 'selfmanagedholding'" class="material-icons-round blue">person</span>
        <span class="small-text ml-3" style="color: var(--clarity-grey)">{{ getPolicyTypeLabel(policyBasicInfo) }}</span>
      </div>
    </div>

    <div class="show-big">
      <div class="flex align-items-end">
        <div class="flex-1 text-center p-4">
          <div v-if="loadingPresent" align="center">
            <Skeleton class="mb-2" height="2rem" borderRadius="16px"></Skeleton>
            <Skeleton class="mb-3" width="12rem" borderRadius="16px"></Skeleton>
          </div>
          <div v-else>
            <h1 class="blue text-center"><currency-value :value="presentValue" :decimal-places="0" /></h1>
            <p class="small-text text-center">Value as at {{ presentDate.format('DD-MM-YYYY') }}</p>
          </div>
        </div>
        <div class="flex-1 text-center p-4">
          <div v-if="loadingPast" align="center">
            <Skeleton class="mb-2" height="2rem" borderRadius="16px"></Skeleton>
            <Skeleton class="mb-3" width="12rem" borderRadius="16px"></Skeleton>
          </div>
          <div v-else>
            <h2 class="blue text-center"><currency-value :value="pastValue" :decimal-places="0" /></h2>
            <p class="small-text text-center">Value as at {{ pastDate.format('DD-MM-YYYY') }}</p>
          </div>
        </div>
        <div class="flex-1 text-center p-4">
          <h4 v-if="policyBasicInfo.performance" class="dark-grey text-center bold uncapitalize">{{$case('Annualised Return', 'title')}}</h4>
          <div v-if="policyBasicInfo.performance" class="inline-flex align-items-center">
            <h2 class="blue text-center"><currency-value :value="annualisedReturn(presentValue, pastValue, moneyIn, moneyOut)" :decimal-places="0" /></h2>
            <h6 v-if="typeof calcReturn.percent == 'number'" class="light-blue ml-3" :class="[isNegative(calcReturn.percent) ? 'negative-text' : 'positive-text']">{{ $nb(calcReturn.percent, 'percentage')}}</h6>
            <h6 v-else class="light-blue ml-3">(N/A)</h6>
          </div>
          <h4 v-if="!policyBasicInfo.performance" class="dark-grey text-center bold uncapitalize">{{$case('Annual Change', 'title')}}</h4>
          <div v-if="!policyBasicInfo.performance" class="inline-flex align-items-center">
            <h2 class="blue text-center"><currency-value :value="presentValue - pastValue" :decimal-places="0" /></h2>
          </div>
          <p class="small-text text-center">Total net return from {{ pastDate.format('DD-MM-YYYY') }} to {{ presentDate.format('DD-MM-YYYY') }}</p>
        </div>
      </div>
      <div class="flex align-items-center mb-5">
      <!-- MONEY IN/OUT  -->
      <div v-if="policyBasicInfo.performance && policyBasicInfo.clarityType !== 'openbanking'" class="flex-1 text-center p-4">
        <div v-if="loadingPresent" align="center">
          <Skeleton class="mb-2" height="2rem" borderRadius="16px"></Skeleton>
          <Skeleton class="mb-3" width="12rem" borderRadius="16px"></Skeleton>
        </div>
        <div v-else class="flex squared-box clarity-snow-grey money-in">
            <span class="material-icons-round mx-4 green chevron">expand_less</span>
            <div class="flex flex-wrap justify-content-center">
                <CurrencyValue :value="moneyIn" :decimal-places="2"/>
                <span class="pl-2">Money In</span>
            </div>
            <span class="material-icons-round mx-4 green chevron">expand_less</span>
        </div>
      </div>
      <div v-if="policyBasicInfo.performance && policyBasicInfo.clarityType !== 'openbanking'" class="flex-1 text-center p-4">
        <div v-if="loadingPast" align="center">
          <Skeleton class="mb-2" height="2rem" borderRadius="16px"></Skeleton>
          <Skeleton class="mb-3" width="12rem" borderRadius="16px"></Skeleton>
        </div>
        <div v-else>
          <div class="flex squared-box clarity-snow-grey money-out">
            <span class="material-icons-round mx-4 red chevron">expand_more</span>
              <div class="flex flex-wrap justify-content-center">
                  <CurrencyValue :value="moneyOut" :decimal-places="2"/>
                  <span class="pl-2">Money Out</span>
              </div>
            <span class="material-icons-round mx-4 red chevron">expand_more</span>
          </div>
        </div>
      </div>
      <!-- BANNER - NO PERFORMANCE -->
      <div v-if="!policyBasicInfo.performance && policyBasicInfo.clarityType !== 'openbanking'" class="clarity-card-inner pr-4" style="width: 66%;">
          <div class="banner">
            <div class="flex flex-column">
              <h3>No Performance?</h3>
            </div>
            <div>
              <Button class="clarity-btn clarity-gradient-orange" @click="$router.push('/securemessaging/message'); contactAdviser(policyBasicInfo.name);">Transfer to Clarity</Button>
            </div>
          </div>
      </div>
      <!-- BANNER - ACCESS -->
      <!-- @TODO: this will need to be updated following open banking integration
      <div v-if="policyBasicInfo.clarityType === 'openbanking'" class="clarity-card-inner pr-4">
        <div class="banner">
          <div class="flex flex-column">
            <h3>The access to this account is expiring soon!</h3>
            <div>Ipsam debitis autem, sequi temporibus quidem magni tempora accusamus?</div>
          </div>
          <div>
            <Button class="clarity-btn clarity-gradient-orange">Renew Access</Button>
          </div>
        </div>
      </div>
      -->
      <!-- ACTION BUTTONS  -->
      <div class="flex-1 text-center p-4">
        <div v-if="loadingPast" align="center">
          <Skeleton class="mb-2" height="2rem" borderRadius="16px"></Skeleton>
          <Skeleton class="mb-3" width="12rem" borderRadius="16px"></Skeleton>
        </div>
        <div v-else>
          <div class="flex squared-box" v-if="policyBasicInfo.clarityType !== 'openbanking'">
            <Button v-if="policyBasicInfo.performance" class="clarity-btn clarity-gradient-grey mr-4" @click="togglePerformanceTransactions">Performance Transactions</Button>
<!--            <Button class="clarity-btn clarity-gradient-grey mx-3 round" v-tooltip.top="'View Documents'"><span class="material-icons-round grey">description</span></Button>-->
            <router-link to="/investments/permissions">
              <Button class="clarity-btn clarity-gradient-grey mx-3 round" v-tooltip.top="'Give Permission'"><span class="material-icons-round grey">group_add</span></Button>
            </router-link>
          </div>
          <div class="flex squared-box" v-if="policyBasicInfo.clarityType === 'openbanking'">
            <open-banking-actions :account="policyBasicInfo"></open-banking-actions>
          </div>
        </div>
      </div>
    </div>
    </div>

    <div class="show-small mb-3">
        <div class="flex flex-wrap">
            <div class="flex flex-column p-2 top-column" >
                <div class="flex-1 text-center p-2">
                    <div v-if="loadingPresent" align="center">
                        <Skeleton class="mb-2" height="2rem" borderRadius="16px"></Skeleton>
                        <Skeleton class="mb-3" width="12rem" borderRadius="16px"></Skeleton>
                    </div>
                    <div v-else>
                        <h1 class="blue text-center w-400"><currency-value :value="presentValue" :decimal-places="0" /></h1>
                        <p class="small-text text-center">Value as at {{ presentDate.format('DD-MM-YYYY') }}</p>
                    </div>
                </div>
                <div class="flex-1 text-center p-2">
                    <div v-if="loadingPast" align="center">
                        <Skeleton class="mb-2" height="2rem" borderRadius="16px"></Skeleton>
                        <Skeleton class="mb-3" width="12rem" borderRadius="16px"></Skeleton>
                    </div>
                    <div v-else>
                        <h2 class="blue text-center"><currency-value :value="pastValue" :decimal-places="0" /></h2>
                        <p class="small-text text-center">Value as at {{ pastDate.format('DD-MM-YYYY') }}</p>
                    </div>
                </div>
                <!-- MONEY IN/OUT  -->
                <div v-if="policyBasicInfo.performance && policyBasicInfo.clarityType !== 'openbanking'" class="flex-1 text-center">
                    <div v-if="loadingPresent" align="center">
                        <Skeleton class="mb-2" height="2rem" borderRadius="16px"></Skeleton>
                        <Skeleton class="mb-3" width="12rem" borderRadius="16px"></Skeleton>
                    </div>
                    <div v-else class="flex squared-box clarity-snow-grey money-in">
                        <span class="material-icons-round mx-4 green chevron">expand_less</span>
                        <div class="flex flex-wrap justify-content-center">
                            <CurrencyValue :value="moneyIn" :decimal-places="2"/>
                            <span class="pl-2">Money In</span>
                        </div>
                        <span class="material-icons-round mx-4 green chevron">expand_less</span>
                    </div>
                </div>
                <div v-if="policyBasicInfo.performance && policyBasicInfo.clarityType !== 'openbanking'" class="flex-1 text-center">
                    <div v-if="loadingPast" align="center">
                        <Skeleton class="mb-2" height="2rem" borderRadius="16px"></Skeleton>
                        <Skeleton class="mb-3" width="12rem" borderRadius="16px"></Skeleton>
                    </div>
                    <div v-else>
                        <div class="flex squared-box clarity-snow-grey money-out">
                            <span class="material-icons-round mx-4 red chevron">expand_more</span>
                            <div class="flex flex-wrap justify-content-center">
                                <CurrencyValue :value="moneyOut" :decimal-places="2"/>
                                <span class="pl-2">Money Out</span>
                            </div>
                            <span class="material-icons-round mx-4 red chevron">expand_more</span>
                        </div>
                    </div>
                </div>
                <!-- BANNER - NO PERFORMANCE -->
                <div v-if="!policyBasicInfo.performance && policyBasicInfo.clarityType !== 'openbanking'" class="flex-1 text-center">
                    <div v-if="loadingPresent" align="center">
                        <Skeleton class="mb-2" height="2rem" borderRadius="16px"></Skeleton>
                        <Skeleton class="mb-3" width="12rem" borderRadius="16px"></Skeleton>
                    </div>
                    <div v-else class="flex squared-box clarity-snow-grey">
                        <span class="material-icons-round mx-4 chevron grey">remove</span>
                        <div class="flex flex-wrap justify-content-center grey">
                            No Data Available
                        </div>
                        <span class="material-icons-round mx-4 chevron grey">remove</span>
                    </div>
                </div>
                <div v-if="!policyBasicInfo.performance && policyBasicInfo.clarityType !== 'openbanking'" class="flex-1 text-center">
                    <div v-if="loadingPast" align="center">
                        <Skeleton class="mb-2" height="2rem" borderRadius="16px"></Skeleton>
                        <Skeleton class="mb-3" width="12rem" borderRadius="16px"></Skeleton>
                    </div>
                    <div v-else>
                        <div class="flex squared-box clarity-snow-grey">
                            <span class="material-icons-round mx-4 chevron grey">remove</span>
                            <div class="flex flex-wrap justify-content-center grey">
                                No Data Available
                            </div>
                            <span class="material-icons-round mx-4 chevron grey">remove</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex flex-column p-2 top-column">
                <div class="flex-1 text-center p-2">
                    <h4 v-if="policyBasicInfo.performance" class="dark-grey text-center bold uncapitalize">{{$case('Annualised Return', 'title')}}</h4>
                    <div v-if="policyBasicInfo.performance" class="inline-flex align-items-center">
                        <h2 class="blue text-center"><currency-value :value="annualisedReturn(presentValue, pastValue, moneyIn, moneyOut)" :decimal-places="0" /></h2>
                        <h6 v-if="typeof calcReturn.percent == 'number'" class="light-blue ml-3" :class="[isNegative(calcReturn.percent) ? 'negative-text' : 'positive-text']">{{ $nb(calcReturn.percent, 'percentage')}}</h6>
                        <h6 v-else class="light-blue ml-3">(N/A)</h6>
                    </div>
                    <h4 v-if="!policyBasicInfo.performance" class="dark-grey text-center bold uncapitalize">{{$case('Annual Change', 'title')}}</h4>
                    <div v-if="!policyBasicInfo.performance" class="inline-flex align-items-center">
                        <h2 class="blue text-center"><currency-value :value="presentValue - pastValue" :decimal-places="0" /></h2>
                    </div>
                    <p class="small-text text-center">Total net return from {{ pastDate.format('DD-MM-YYYY') }} to {{ presentDate.format('DD-MM-YYYY') }}</p>
                </div>
                <!-- ACTION BUTTONS  -->
                <div class="flex-1 text-center p-2">
                    <div v-if="loadingPast" align="center">
                        <Skeleton class="mb-2" height="2rem" borderRadius="16px"></Skeleton>
                        <Skeleton class="mb-3" width="12rem" borderRadius="16px"></Skeleton>
                    </div>
                    <div v-else>
                        <div class="flex flex-column" v-if="policyBasicInfo.clarityType !== 'openbanking'">
                            <div class="m-3 squared-box" v-if="policyBasicInfo.performance">
                              <Button class="clarity-btn clarity-gradient-grey mr-4" @click="togglePerformanceTransactions">Performance Transactions</Button>
                              <!--            <Button class="clarity-btn clarity-gradient-grey mx-3 round" v-tooltip.top="'View Documents'"><span class="material-icons-round grey">description</span></Button>-->
                            </div>
                            <div v-else>
                                <div class="flex flex-column align-content-center justify-content-center">
                                  <h3 class="blue text-center">No Performance?</h3>
                                  <div>
                                      <Button class="clarity-btn clarity-gradient-orange" @click="$router.push('/securemessaging/message'); contactAdviser(policyBasicInfo.name);">Transfer to Clarity</Button>
                                  </div>
                                </div>
                            </div>
                            <div class="m-3 squared-box">
                              <router-link to="/investments/permissions">
                                  <Button class="clarity-btn clarity-gradient-grey mx-3 round" v-tooltip.top="'Give Permission'"><span class="material-icons-round grey">group_add</span></Button>
                              </router-link>
                            </div>
                        </div>
                        <div class="flex flex-column" v-if="policyBasicInfo.clarityType === 'openbanking'">
                            <div class="flex m-3 squared-box">
                              <Button class="clarity-btn clarity-gradient-orange mx-3 round" v-tooltip.top="'Renew Access'"><span class="material-icons-round white">sync</span></Button>
                              <Button class="clarity-btn clarity-gradient-grey mx-3 round" v-tooltip.top="'Revoke Access'" @click="toggleRevokeDialog"><span class="material-icons-round grey">delete_forever</span></Button>
                            </div>
                            <div class="flex m-3 squared-box">
                              <Button class="clarity-btn clarity-gradient-grey mx-3 round" v-tooltip.top="'View Transactions'"><span class="material-icons-round grey">sync_alt</span></Button>
                              <router-link to="/investments/permissions">
                                  <Button class="clarity-btn clarity-gradient-grey mx-3 round" v-tooltip.top="'Give Permission'"><span class="material-icons-round grey">group_add</span></Button>
                              </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="my-2">
      <div class="flex card-container blue-container overflow-hidden">
          <div class="flex-grow-1 flex align-items-center">
              <h4 class="mb-4">Policy Holdings</h4>
          </div>
          <div class="flex align-items-center justify-content-center">
              <div v-if="show" class="flex align-items-center grey cursor-pointer" style="margin-bottom: 21px;" @click="toggleShow"><span
                      class="material-icons-round grey">expand_less</span>Hide Details
              </div>
              <div v-else class="flex align-items-center grey cursor-pointer" style="margin-bottom: 21px;" @click="toggleShow"><span
                      class="material-icons-round grey">expand_more</span>Show Details
              </div>
          </div>
      </div>
      <div v-if="show">
        <policy-holdings :planuuid="uuid" @toggleDialog="toggleDialog"/>
      </div>
    </div>
  </div>
  <div v-if="showModal.fundAllocation">
    <FundAllocationModal v-model:visible="showModal.fundAllocation"  id="fundAllocationDialog" @closeModal="toggleDialog()" />
  </div>
  <div v-if="showModal.fundHistory">
    <FundHistoryModal v-model:visible="showModal.fundHistory" id="fundHistoryDialog"  @closeModal="toggleDialog()" />
  </div>
  <div v-if="showModal.fundInfo">
    <FundInfoModal v-model:visible="showModal.fundInfo"  id="fundInfoDialog" @closeModal="toggleDialog()" />
  </div>

</template>
<script>

import {computed, reactive, ref, toRef, watch, inject, onMounted, onBeforeUnmount} from "vue";
import dayjs from "dayjs";
import PolicyHoldings from "./PolicyHoldings";
import {useStore} from "vuex";
import CurrencyValue from "@/components/common/CurrencyValue";
import Pill from '@/components/nav/Pill'
import SeparatorV from '@/components/common/layout/SeparatorV.vue'
import FundInfoModal from '../modals/ValuationFundInfoModal.vue'
import FundHistoryModal from '../modals/ValuationFundHistoryModal.vue'
import FundAllocationModal from '../modals/ValuationFundAllocationModal'
import api from '@/core/services/api'
import {toDrupalRequest} from '@/core/helpers';
import OpenBankingActions from "@/components/open_banking/OpenBankingActions.vue";

export default {
  name: "PolicyListItem",
  components: {
    OpenBankingActions,
    CurrencyValue,
    PolicyHoldings,
    Pill,
    SeparatorV,
    FundInfoModal,
    FundHistoryModal,
    FundAllocationModal,
  },
  props: {
    planuuid: String
  },
  setup(props) {
    const store = useStore()
    const bus = inject('bus')

    const uuid = toRef(props, 'planuuid')

    const presentValue = ref(0);
    const pastValue = ref(0);

    const show = ref(true);
    const toggleShow = () => {
        show.value = !show.value;
    }

    const presentDate = computed(() => {
      const date = store.state.valuation.valuationDate;
      return new dayjs(date)
    })

    const pastDate = computed(() => {
      const date = store.state.valuation.valuationDate;
      return new dayjs(date).subtract(1, "year")
    })

    const loadingPresent = ref(true);
    const loadingPast = ref(true);

    const getPastLoadingState = computed(() => store.getters.pastValue(uuid.value))
    const getPresentLoadingState = computed(() => store.getters.presentValue(uuid.value))

    // need watchers and if statements to allow either view to load first and update the dom
    if(getPastLoadingState.value !== null) {
      pastValue.value = getPastLoadingState.value
      loadingPast.value = false
    }
    if(getPresentLoadingState.value !== null) {
      presentValue.value = getPresentLoadingState.value
      loadingPresent.value = false
    }

    watch(getPastLoadingState, (value) => {
      console.log('past value for '+uuid.value+' is ', value)
      if(value !== null) {
        loadingPast.value = false
        pastValue.value = value
      }
    })

    watch(getPresentLoadingState, (value) => {
      if(value !== null) {
        loadingPresent.value = false
        presentValue.value = value
      }
    })

    const getOwnerFromUuid = (owner) => {
        let rtn = []
        owner.forEach(person => {
          rtn.push(store.getters.personFromUuid(person).name)
        })

        return rtn.join("<br />")
    }

    const togglePerformanceTransactions = () => {
      store.dispatch('setPerformancePolicySelected', uuid)
      store.dispatch('setActiveModal', {modal: 'PerformanceTransactions', origin: 'valuation'})
    }

    const calcReturn = computed(() => {
      if (pastValue.value != 0) {
        return store.getters.getGoalSeek(uuid.value)
      }

      return 'N/A'
    })

    const isNegative = (value) => {
      return value < 0
    }

    const annualisedReturn = (present, past, i, out) => {
      return (present - i) - (past + out)
    }

    bus.on('closeValuationDialogs', () => {
      showModal.fundInfo = false
      showModal.fundHistory = false
      showModal.fundAllocation = false
    })

    const toggleDialog = (dialog) => {
      // reset the dialogs for all Policy List Item Cards
      bus.emit('closeValuationDialogs')
      // open chosen dialog
      if (dialog) {
        showModal[dialog] = true
      }
    }

    const showModal = reactive({
      fundInfo: false,
      fundHistory : false,
      fundAllocation : false
    })

    const getPolicyTypeLabel = (policyInfo) => {
      switch (policyInfo.clarityType) {
        case 'clarity':
          return 'investment'
        case 'selfmanagedholding':
          return 'Self Managed Holding'
        case 'openbanking':
          return 'Open Banking'
      }
    }

    const showRevokeDialog = ref(false)

    const toggleRevokeDialog = () => {
      showRevokeDialog.value = !showRevokeDialog.value
    }
    const loadPerformanceTransactions = (policy) => {
        store.dispatch('getPerformanceTransactionsForPolicy', {value: policy,
            from: pastDate.value.format('YYYY-MM-DD'),
            to: presentDate.value.format('YYYY-MM-DD')
        })
    }

    const loadValues = (uuid) => {
        loadPresentValues(uuid);
        loadPastValues(uuid);
        loadPerformanceTransactions(uuid)
    }

    onMounted(() => {
        loadValues(uuid.value)
    })

    const loadPresentValues = async (uuid) => {
        const presentResponse = await api.get(`${toDrupalRequest('valuation/plan/summary/'+uuid+'/'+presentDate.value.format('YYYY-MM-DD'))}`)
        if (presentResponse) {
            store.dispatch('setPerformanceTransactionTotal', {policy: uuid, value: presentResponse.total, point: 'present'})
        }
    }

    const loadPastValues = async (uuid) => {
        const pastResponse = await api.get(`${toDrupalRequest('valuation/plan/summary/'+uuid+'/'+pastDate.value.format('YYYY-MM-DD'))}`)
        if (pastResponse) {
            store.dispatch('setPerformanceTransactionTotal', {policy: uuid, value: pastResponse.total, point: 'past'})
        }
    }

    const contactAdviser = (plan) => {
        store.dispatch('globalMessageTrigger',  {
            subject: 'Transfer to clarity - ' + plan,
            category: 'Investments',
            recipient: store.getters.getAdviserName,
            messagebody: 'I would like to discuss transferring my '+plan+' to clarity.'
        })
    }

    watch(presentDate, () => {
        loadValues(uuid.value)
    })

    onBeforeUnmount(() => {
        bus.off('closeValuationDialogs')
    });

    return {
      policyBasicInfo: computed(() => store.getters.policyBasicInfo(uuid.value)),
      policyIsIMS: computed(() => store.getters.policyIsIMS(uuid.value)),
      uuid,
      presentDate,
      pastDate,
      presentValue,
      pastValue,
      loadingPresent,
      loadingPast,
      getOwnerFromUuid,
      togglePerformanceTransactions,
      moneyIn: computed(() => store.getters.performanceTotal(uuid.value, 'in')),
      moneyOut: computed(() => store.getters.performanceTotal(uuid.value, 'out')),
      calcReturn,
      isNegative,
      annualisedReturn,
      toggleDialog,
      showModal,
      getAllocationModalData: computed(() => store.getters.getAllocationModalData),
      getHistoryModalData: computed(() => store.getters.getHistoryModalData),
      getFundInfoModalData: computed(() => store.getters.getFundInfoModalData),
      currentisin: computed(() => store.getters.getCurrentISIN),
      getPolicyTypeLabel,
      showRevokeDialog,
      toggleRevokeDialog,
      show,
      toggleShow,
      contactAdviser
    }
  }
}
</script>
<style scoped>
.banner {
  flex-direction: row;
  justify-content: space-between;
  padding: 30px;
  background-image: url('../../assets/img/banner-bg-fade-left.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
}
.banner button {
  padding: 15px 45px;
}
.positive-text {
  color: var(--clarity-green);
}
.positive-text::before {
  content: '+';
}
.negative-text {
  color: var(--clarity-red);
}
.money-out span:hover {
  color: var(--clarity-red);
  cursor: default;
}
.money-in span:hover {
  color: var(--clarity-green);
  cursor: default;
}
.show-small {
    display: none;
    @media only screen and (max-width: 1100px) {
        display: block;
    }
}
.show-big {
    display: block;
    @media only screen and (max-width: 1100px) {
        display: none !important;
    }
}
.top-column {
    width: 50%;
    @media only screen and (max-width: 768px) {
        width: 100%;
    }
}

.show-small h1{
    font-size: var(--clarity-font-size-30);
}
.w-400 {
    font-weight: 400;
}
</style>
