import {formatter} from "../services/formatter";
import store from "@/store";


export default class PlanSection {
    constructor(name, owner, rows) {
        this.name = name
        this.owner = owner
        this.rows = rows
    }

    getHeader() {

        return [
            {
                "A": "Holding Name",
                "B": "ISIN Code",
                "C": "Units/Shares",
                "D": "Price",
                "E": "Price Date",
                "F": "Holding (%)",
                "G": "Value"
            }
        ]
    }

    getContent() {
        let body = []

        const currency = store.state.selections.currencySelected;

        for(let row of this.rows) {
            body.push({
                "A": row.name,
                "B": row.isin,
                "C": row.units,
                "D": formatter.currency(currency, row.price, 4, true),
                "E": row.priceDate,
                "G": formatter.currency(currency, row.value, 2, true)
            })
        }

        return body
    }

    getFooter() {
        return {}
    }


    getSection() {
        return {
            name: this.name,
            owner: this.owner,
            sectionheader: this.getHeader(),
            sectioncontent: this.getContent(),
            sectionfooter: this.getFooter()
        }
    }
}
