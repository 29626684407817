<template>
  <span class="wrapper">
    <Calendar id="basic" v-model="localDate" dateFormat="dd/mm/yy" autocomplete="off" @date-select="updateValuationDate" @change="inputChange" panel-class="calendar-valuationdate" :max-date="today" ref="datepicker">
      <template #footer>
        <div class="flex flex-row-reverse flex-wrap">
          <div class="flex align-items-center justify-content-center m-2">
            <Button @click="setToToday" class="clarity-btn clarity-gradient-orange">Set As Today</Button>
          </div>
        </div>
      </template>
    </Calendar>
    <span class="material-icons-round calendar-icon grey" @click="triggerDatepicker">today</span>
  </span>
</template>
<script>
import {useStore} from "vuex";
import {computed, ref, watch, inject, onMounted} from "vue";
import dayjs from "dayjs";

export default {
  name: "ValuationDate",
  props:['dateRef'],
  setup(props) {
    const store = useStore()
    const bus = inject('bus')
    const localDate = ref(dayjs().toDate())
    const today = dayjs().toDate();
    const datepicker = ref(null);
    const valuationDate = computed(() => store.state.valuation[props.dateRef] )

    const triggerDatepicker = () => {
      datepicker.value.onButtonClick();
    }

    onMounted(() => {
        if(valuationDate.value) {
            localDate.value = valuationDate.value;
        }
    })

    watch(valuationDate, (value) => {
      localDate.value = value;
    })

    if (props.dateRef != 'valuationDate') {
        localDate.value = store.state.valuation[props.dateRef]
    }

    const updateValuationDate = (data) => {
      // check if valid date
      if(dayjs(data).isValid()) {
        // temp fix - use across tabs/store
        switch(props.dateRef) {
          case 'valuationDate':
            store.dispatch('setValuationDate', data)
            bus.emit('refreshTransactions')
            break;
          case 'dateRangeFrom':
            store.dispatch('setDateRangeFrom', data)
            break;
          case 'dateRangeTo':
            store.dispatch('setDateRangeTo', data)
            break;
        }

      }else{
        // do not update keep at current value.
        localDate.value = valuationDate.value
      }
    }

    const inputChange = () => {
      if(dayjs(localDate.value).isValid()) {
        store.dispatch('setValuationDate', localDate.value)
      }else{
        // do not update keep at current value.
        localDate.value = valuationDate.value
      }
    }

    const setToToday = () => {
      // store.dispatch('setValuationDate', today)
      // temp fix - use across tabs/store
        switch(props.dateRef) {
          case 'valuationDate':
            store.dispatch('setValuationDate', today)
            break;
          case 'dateRangeFrom':
            store.dispatch('setDateRangeFrom', today)
            break;
          case 'dateRangeTo':
            store.dispatch('setDateRangeTo', today)
            break;
        }
    }

    return {
      datepicker,
      triggerDatepicker,
      localDate,
      updateValuationDate,
      inputChange,
      setToToday,
      today
    }
  }
}
</script>

<style scoped>
  .wrapper {
    position: relative;
  }
 .calendar-icon {
   position:absolute;
   top: calc(50% - 12px);
   right: 20px;
 }
 .wrapper:deep(.p-inputtext) {
   border-right: solid;
   border-right-color: var(--clarity-light-grey);
   border-right-width: 1px;
 }
  .wrapper:deep(.p-inputtext):enabled:focus,
  .wrapper:deep(.p-inputtext):hover {
   border-right: solid;
   border-right-color: var(--clarity-grey);
   border-right-width: 1px;
 }
 .wrapper:deep(.p-inputwrapper) {
   width: 100%;
 }
</style>
