<template>
  <Dropdown :model-value="selectedOption" :options="portfolioOptions" optionLabel="name" optionValue="uuid" :loading="loading" @change="updatePortfolioSelected" @before-show="stopScroll" @before-hide="allowScroll">
    <template #option="slotProps">
      <div v-if="slotProps.option.uuid == 'manage'" class="flex align-items-center bottom-option">
        <div class="flex align-items-center dropdown-custom-option"><span class="material-icons-round mr-2">{{slotProps.option.icon}}</span>{{slotProps.option.name}}</div>
      </div>
    </template>
  </Dropdown>
</template>

<script>
import {useStore} from "vuex";
import {computed, onMounted, inject} from "vue";
import { useRouter } from 'vue-router';

export default {
  name: "PortfolioSelect",
  setup() {
    const store = useStore()
    const router = useRouter()

    onMounted(() => {
      getPortfolioOptions()
    })

    const getPortfolioOptions = () => {
      store.dispatch('getPortfolioOptions')
    }

    const updatePortfolioSelected = (data) => {
      if(data.value == 'manage') {
        router.push('/investments/portfolios')
      } else {
        store.dispatch('setPortfolioSelected', data.value)
      }
    }

    const portfolioOptions = computed(() => {
      const options = store.state.selections.portfolioOptions;
      if(options && options.error){
        store.dispatch('addNotification', {severity: 'error', summary: 'Portfolio Select', detail: options.message })
        return [];
      }else{
        return  options ? [...options, {name: 'Manage Portfolios', uuid: 'manage', icon: 'edit'}] : options
      }
    })

    const bus = inject('bus')

    const stopScroll = () => {
      bus.emit('stopScroll')
    }
    const allowScroll = () => {
      bus.emit('allowScroll')
    }

    return {
      selectedOption: computed(() => store.getters.portfolioSelected ),
      loading: computed(() => store.state.selections.portfolioLoading),
      portfolioOptions,
      updatePortfolioSelected,
      allowScroll,
      stopScroll
    }
  }
}
</script>

<style scoped>
.bottom-option {
  border-top: 1px solid var(--clarity-light-grey);
  padding-top: 10px;
}
.p-dropdown {
  width: 300px;
}

@media only screen and (max-width: 1366px)  {
  .p-dropdown {
    width: 170px;
  }
}

@media only screen and (max-width: 1024px)  {
  .p-dropdown {
    width: 150px;
  }
}

@media only screen and (max-width: 768px)  {
  .p-dropdown {
    width: 139px;
  }
}
</style>
