import { ref, computed, watch } from 'vue';
import { debounce } from 'lodash';

// Create a reusable function to debounce computed property updates
export function createDebouncedComputed(initialComputed, delay) {
    const debouncedValue = ref(initialComputed.value);
    const computedProperty = computed(() => debouncedValue.value);

    watch(initialComputed, debounce(() => {
        debouncedValue.value = initialComputed.value;
    }, delay));

    return computedProperty;
}
