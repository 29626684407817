<template>
    <div class="separator-h"></div>
</template>
<style scoped>
.separator-h {
    border-bottom-color: var(--clarity-blue);
    border-bottom-width: 1px;
    border-bottom-style: solid;
    width: 200px;
}
</style>
