<template>
  <Dialog class="modal" style="width: 30%">
    <div class="fund-info-modal">
      <div class="flex">
        <div class="flex justify-content-center" style="width: 90%">
          <Skeleton v-if="!info.name && !hasError" class="mb-2" height="2rem" borderRadius="16px"></Skeleton>
          <h3 v-if="info.name" class="header fund-title">{{ info.name }}</h3>
        </div>
        <div class="flex justify-content-end" style="width: 10%">
          <Button class="clarity-close-modal-btn" @click="closeModal"><span
              class="material-icons-round grey">close</span></Button>
        </div>
      </div>
      <p class="header">Fund Info</p>
      <spacer-h/>
      <div v-if="hasError">
        <h3 class="flex justify-content-center">No Data Currently Available</h3>
      </div>
      <div v-if="!info.price && !hasError" style="height: 300px;">
        <Loading/>
      </div>
      <div class="flex flex-column" v-else-if="info.price && !hasError">
        <div class="row"><span class="label">Price ({{ $date(info.priceDate, 'DD/MM/YYYY') }})</span>{{ info.price }}
        </div>
        <div class="row"><span class="label">Fund Sector:</span>{{ info.fundSector }}</div>
        <div class="row"><span class="label">Mex Code:</span>{{ info.mexCode }}</div>
        <div class="row"><span class="label">Unit Type:</span>{{ info.unitType }}</div>
        <div class="row"><span class="label">Investment Type:</span>{{ info.investmentType }}</div>
        <div class="row"><span class="label">ISIN Code:</span>{{ isin.isin }}</div>
        <div class="row"><span class="label">Annual Management Charge:</span>{{ info.charge }}</div>
        <spacer-h/>
        <div v-if="!info.aims">
          <Skeleton class="mb-2" height="2rem" borderRadius="16px"></Skeleton>
        </div>
        <div v-else><span class="label">Investment Aims:</span>{{ info.aims }}</div>
      </div>
      <spacer-h/>
      <div class="source" v-if="!hasError">Source: Morningstar</div>
    </div>
  </Dialog>
</template>
<script>
import {computed, ref, watch} from 'vue'
import SpacerH from '@/components/common/layout/SpacerH.vue'
import {useStore} from "vuex";

export default {
  name: 'ValuationFundInfoModal',
  components: {SpacerH},
  emits: ['closeModal'],
  setup(props, context) {
    const store = useStore()
    const closeModal = () => {
      context.emit('closeModal')
    }
    const info = computed(() => store.getters.getFundInfoModalData)
    const isin = computed(() => store.getters.getCurrentISIN)
    const hasError = ref(false)
    const loading = ref(true)

    watch(info, (value) => {
      if (typeof value.error !== 'undefined') {
        loading.value = false
        hasError.value = true
      } else {
        loading.value = false
        hasError.value = false
      }
    })

    return {
      info,
      isin,
      hasError,
      closeModal,
      loading
    }
  }
}
</script>
<style scoped>
.modal:deep(.p-dialog-content) {
  border-bottom-left-radius: 27px !important;
  border-bottom-right-radius: 27px !important;
}

.fund-info-modal {
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  align-self: center;
}

.fund-title {
  color: var(--clarity-blue);
  padding-left: 10%;
  text-align: center;
}

.label {
  font-weight: bold;
  margin-right: 10px;
}

.row {
  padding: 10px;
}

.row:nth-child(odd) {
  background-color: var(--clarity-light-grey);
}

.source {
  color: var(--clarity-grey);
  font-weight: bold;
}
</style>
