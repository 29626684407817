<template>
  <Modal id="modal-full-asset-allocation">
    <template #body>
      <content-card-full>
        <template #title>
          <div class="flex justify-content-between title align-items-center">
            <h2>Full Asset Allocation Report</h2>
            <Button class="clarity-close-modal-btn" @click="closeModal"><span
                class="material-icons-round grey">close</span></Button>
          </div>
        </template>
        <template #content-full>
          <div class="mb-5">
            <div class="body mb-5">Welcome to the asset allocation section of the website. This page combines the data
              from your automated data feeds as awell as any self managed holdings you have added to the website.
            </div>
            <div class="flex justify-content-between">
              <div class="flex align-items-center justify-content-evenly dropdown-group">
                <div class="title-dropdown"><label>Portfolios:</label>
                  <portfolio-select/>
                </div>
                <div class="title-dropdown"><label>People:</label>
                  <people-select/>
                </div>
                <div class="title-dropdown"><label>Currency:</label>
                  <currency-select/>
                </div>
              </div>
              <div class="flex align-items-center justify-content-between bottom-actions">
                <Button class="clarity-btn clarity-gradient-orange" @click="expandAll"><span>Expand All Info</span>
                </Button>
                <Button class="clarity-btn clarity-gradient-grey" @click="collapseAll"><span>Collapse All Info</span>
                </Button>
              </div>
            </div>
          </div>
          <DataTable :value="fullAllocation" rowGroupMode="subheader" groupRowsBy="type" scrollable scrollHeight="800px"
                     id="allocationTable"
                     :expandableRowGroups="true" v-model:expandedRowGroups="expandedRowGroups"
                     class="full-allocation-table" :rowClass="testClass">
            <ColumnGroup type="header">
              <Row>
                <Column header="Policy" class="asset-wide-col" :colspan="2" :rowspan="2"/>
                <Column header="Fund" class="asset-wide-col" :colspan="2" :rowspan="2"/>
                <Column :header="`Cash (${getCurrency})`" :colspan="1" :rowspan="2"/>
                <Column :header="`Fixed Interest (${getCurrency})`" :colspan="1" :rowspan="2"/>
                <Column :header="`Equities (${getCurrency})`" :colspan="8"/>
                <Column :header="`Property (${getCurrency})`" :colspan="1" :rowspan="2"/>
                <Column :header="`Total (${getCurrency})`" :colspan="1" :rowspan="2"/>
              </Row>
              <Row>
                <Column header="UK" :colspan="1"/>
                <Column header="US" :colspan="1"/>
                <Column header="Europe" :colspan="1"/>
                <Column header="Asa Pacific" :colspan="1"/>
                <Column header="Japan" :colspan="1"/>
                <Column header="Emerging Markets" :colspan="1"/>
                <Column header="Specialist Equities" :colspan="1"/>
                <Column header="Total Equities" :colspan="1"/>
              </Row>
            </ColumnGroup>
            <Column field="name" class="asset-wide-col">
              <template #body="slotProps">
                <div v-show="slotProps.data.type !== 'Totals'">
                  <div class="flex flex-column align-items-center">
                    <span class="text-center">{{ slotProps.data.name }}</span>
                    <span class="text-center" @click="toggleShowFund(slotProps.data.name)" style="cursor: pointer">
                                            {{
                        collapsedFundRows.includes(slotProps.data.name) ? 'Show More' : 'Show Less'
                      }}
                                        </span>
                  </div>
                </div>
                <div v-if="slotProps.data.type == 'Totals'">
                  <DataTable id="allocationTotalsLabels" :value="totalAllocation">
                    <Column>
                      <template #body="slotProps">
                        {{ getTotalColLabel(slotProps.data.type, 'totals') }}
                      </template>
                    </Column>
                  </DataTable>

                  <DataTable id="allocationAssetsTotalsLabels" :value="allocationTotals[0]">
                    <Column>
                      <template #body="slotProps">
                        {{ getTotalColLabel(slotProps.data.type, 'assets') }}
                      </template>
                    </Column>
                  </DataTable>

                  <DataTable id="allocationEquityTotalsLabels" :value="allocationTotals[1]">
                    <Column>
                      <template #body="slotProps">
                        {{ getTotalColLabel(slotProps.data.type, 'equity') }}
                      </template>
                    </Column>
                  </DataTable>
                </div>
              </template>
            </Column>
            <Column field="funds">
              <template #body="slotProps">
                <div v-if="slotProps.data.type !== 'Totals' && !collapsedFundRows.includes(slotProps.data.name)">
                  <DataTable
                      :value="slotProps.data.funds"
                      :id="`nestedTable-${slotProps.data.name}`"
                  >
                    <Column class="asset-wide-col fund" field="name"></Column>
                    <Column class="asset-col" field="allocation.cash">
                      <template #body="slotProps">
                        <currency-value :value="slotProps.data.allocation.cash"></currency-value>
                      </template>
                    </Column>
                    <Column class="asset-col" field="allocation.fixed_interest">
                      <template #body="slotProps">
                        <currency-value :value="parseFloat(slotProps.data.allocation.fixed_interest)"></currency-value>
                      </template>
                    </Column>
                    <Column class="asset-col" field="allocation.uk_equity">
                      <template #body="slotProps">
                        <currency-value :value="parseFloat(slotProps.data.allocation.uk_equity)"></currency-value>
                      </template>
                    </Column>
                    <Column class="asset-col" field="allocation.us_equity">
                      <template #body="slotProps">
                        <currency-value :value="parseFloat(slotProps.data.allocation.us_equity)"></currency-value>
                      </template>
                    </Column>
                    <Column class="asset-col" field="allocation.european_equity">
                      <template #body="slotProps">
                        <currency-value :value="parseFloat(slotProps.data.allocation.european_equity)"></currency-value>
                      </template>
                    </Column>
                    <Column class="asset-col" field="allocation.fareast_equity">
                      <template #body="slotProps">
                        <currency-value :value="parseFloat(slotProps.data.allocation.fareast_equity)"></currency-value>
                      </template>
                    </Column>
                    <Column class="asset-col" field="allocation.japan_equity">
                      <template #body="slotProps">
                        <currency-value :value="parseFloat(slotProps.data.allocation.japan_equity)"></currency-value>
                      </template>
                    </Column>
                    <Column class="asset-col" field="allocation.other_equity">
                      <template #body="slotProps">
                        <currency-value :value="parseFloat(slotProps.data.allocation.other_equity)"></currency-value>
                      </template>
                    </Column>
                    <Column class="asset-col" field="allocation.specialist_equity">
                      <template #body="slotProps">
                        <currency-value
                            :value="parseFloat(slotProps.data.allocation.specialist_equity)"></currency-value>
                      </template>
                    </Column>
                    <Column class="asset-col" field="allocation.equity">
                      <template #body="slotProps">
                        <currency-value :value="parseFloat(slotProps.data.allocation.equity)"></currency-value>
                      </template>
                    </Column>
                    <Column class="asset-col" field="allocation.property">
                      <template #body="slotProps">
                        <currency-value :value="parseFloat(slotProps.data.allocation.property)"></currency-value>
                      </template>
                    </Column>
                    <Column class="asset-col" field="value">
                      <template #body="slotProps">
                        <currency-value :value="parseFloat(slotProps.data.value)"></currency-value>
                      </template>
                    </Column>
                  </DataTable>
                </div>
                <div v-if="slotProps.data.type !== 'Totals' && collapsedFundRows.includes(slotProps.data.name)">
                  <DataTable
                      :value="[slotProps.data.totals]"
                      :id="`rowSummaryTable-${slotProps.data.name}`"
                  >
                    <Column class="asset-wide-col fund" field="name">
                      <template #body>
                        <span>Various Funds</span>
                      </template>
                    </Column>
                    <Column class="asset-col" field="cash">
                      <template #body="slotProps">
                        <currency-value :value="slotProps.data.cash"></currency-value>
                      </template>
                    </Column>
                    <Column class="asset-col" field="fixed_interest">
                      <template #body="slotProps">
                        <currency-value :value="parseFloat(slotProps.data.fixed_interest)"></currency-value>
                      </template>
                    </Column>
                    <Column class="asset-col" field="uk_equity">
                      <template #body="slotProps">
                        <currency-value :value="parseFloat(slotProps.data.uk_equity)"></currency-value>
                      </template>
                    </Column>
                    <Column class="asset-col" field="us_equity">
                      <template #body="slotProps">
                        <currency-value :value="parseFloat(slotProps.data.us_equity)"></currency-value>
                      </template>
                    </Column>
                    <Column class="asset-col" field="european_equity">
                      <template #body="slotProps">
                        <currency-value :value="parseFloat(slotProps.data.european_equity)"></currency-value>
                      </template>
                    </Column>
                    <Column class="asset-col" field="fareast_equity">
                      <template #body="slotProps">
                        <currency-value :value="parseFloat(slotProps.data.fareast_equity)"></currency-value>
                      </template>
                    </Column>
                    <Column class="asset-col" field="japan_equity">
                      <template #body="slotProps">
                        <currency-value :value="parseFloat(slotProps.data.japan_equity)"></currency-value>
                      </template>
                    </Column>
                    <Column class="asset-col" field="other_equity">
                      <template #body="slotProps">
                        <currency-value :value="parseFloat(slotProps.data.other_equity)"></currency-value>
                      </template>
                    </Column>
                    <Column class="asset-col" field="specialist_equity">
                      <template #body="slotProps">
                        <currency-value :value="parseFloat(slotProps.data.specialist_equity)"></currency-value>
                      </template>
                    </Column>
                    <Column class="asset-col" field="equity">
                      <template #body="slotProps">
                        <currency-value :value="parseFloat(slotProps.data.equity)"></currency-value>
                      </template>
                    </Column>
                    <Column class="asset-col" field="property">
                      <template #body="slotProps">
                        <currency-value :value="parseFloat(slotProps.data.property)"></currency-value>
                      </template>
                    </Column>
                    <Column class="asset-col" field="value">
                      <template #body="slotProps">
                        <currency-value :value="parseFloat(slotProps.data.value)"></currency-value>
                      </template>
                    </Column>
                  </DataTable>
                </div>
                <div v-if="slotProps.data.type == 'Totals'" class="nested-table">
                  <DataTable id="allocationTotals" :value="totalAllocation">
                    <Column class="asset-wide-col"/>
                    <Column class="asset-col" field="cash">
                      <template #body="slotProps">
                        <currency-value :value="slotProps.data.cash"></currency-value>
                      </template>
                    </Column>
                    <Column class="asset-col" field="fixed_interest">
                      <template #body="slotProps">
                        <currency-value :value="parseFloat(slotProps.data.fixed_interest)"></currency-value>
                      </template>
                    </Column>
                    <Column class="asset-col" field="uk_equity">
                      <template #body="slotProps">
                        <currency-value :value="parseFloat(slotProps.data.uk_equity)"></currency-value>
                      </template>
                    </Column>
                    <Column class="asset-col" field="us_equity">
                      <template #body="slotProps">
                        <currency-value :value="parseFloat(slotProps.data.us_equity)"></currency-value>
                      </template>
                    </Column>
                    <Column class="asset-col" field="european_equity">
                      <template #body="slotProps">
                        <currency-value :value="parseFloat(slotProps.data.european_equity)"></currency-value>
                      </template>
                    </Column>
                    <Column class="asset-col" field="fareast_equity">
                      <template #body="slotProps">
                        <currency-value :value="parseFloat(slotProps.data.fareast_equity)"></currency-value>
                      </template>
                    </Column>
                    <Column class="asset-col" field="japan_equity">
                      <template #body="slotProps">
                        <currency-value :value="parseFloat(slotProps.data.japan_equity)"></currency-value>
                      </template>
                    </Column>
                    <Column class="asset-col" field="other_equity">
                      <template #body="slotProps">
                        <currency-value :value="parseFloat(slotProps.data.other_equity)"></currency-value>
                      </template>
                    </Column>
                    <Column class="asset-col" field="specialist_equity">
                      <template #body="slotProps">
                        <currency-value :value="parseFloat(slotProps.data.specialist_equity)"></currency-value>
                      </template>
                    </Column>
                    <Column class="asset-col" field="equity">
                      <template #body="slotProps">
                        <currency-value :value="parseFloat(slotProps.data.equity)"></currency-value>
                      </template>
                    </Column>
                    <Column class="asset-col" field="property">
                      <template #body="slotProps">
                        <currency-value :value="parseFloat(slotProps.data.property)"></currency-value>
                      </template>
                    </Column>
                    <Column class="asset-col" field="total">
                      <template #body="slotProps">
                        <currency-value :value="parseFloat(slotProps.data.total)"></currency-value>
                      </template>
                    </Column>
                  </DataTable>

                  <!-- assets -->
                  <DataTable id="allocationAssetsTotals" :value="allocationTotals[0]">
                    <Column class="asset-wide-col"/>
                    <Column class="asset-col" field="cash_percent">
                      <template #body="slotProps">
                        {{ $nb(slotProps.data.cash_percent, 'percentage') }}
                      </template>
                    </Column>
                    <Column class="asset-col" field="fixed_interest_percent">
                      <template #body="slotProps">
                        {{ $nb(slotProps.data.fixed_interest_percent, 'percentage') }}
                      </template>
                    </Column>
                    <Column class="asset-col" field=""></Column>
                    <Column class="asset-col" field=""></Column>
                    <Column class="asset-col" field=""></Column>
                    <Column class="asset-col" field=""></Column>
                    <Column class="asset-col" field=""></Column>
                    <Column class="asset-col" field=""></Column>
                    <Column class="asset-col" field=""></Column>
                    <Column class="asset-col" field="equity_percent">
                      <template #body="slotProps">
                        {{ $nb(slotProps.data.equity_percent, 'percentage') }}
                      </template>
                    </Column>
                    <Column class="asset-col" field="property_percent">
                      <template #body="slotProps">
                        {{ $nb(slotProps.data.property_percent, 'percentage') }}
                      </template>
                    </Column>
                    <Column class="asset-col" field=""/>
                  </DataTable>
                  <!-- equity -->
                  <DataTable id="allocationEquityTotals" :value="allocationTotals[1]">
                    <Column class="asset-wide-col"/>
                    <Column class="asset-col" field=""/>
                    <Column class="asset-col" field=""/>
                    <Column class="asset-col" field="uk_equity_percent">
                      <template #body="slotProps">
                        {{ $nb(slotProps.data.uk_equity_percent, 'percentage') }}
                      </template>
                    </Column>
                    <Column class="asset-col" field="us_equity_percent">
                      <template #body="slotProps">
                        {{ $nb(slotProps.data.us_equity_percent, 'percentage') }}
                      </template>
                    </Column>
                    <Column class="asset-col" field="european_equity_percent">
                      <template #body="slotProps">
                        {{ $nb(slotProps.data.european_equity_percent, 'percentage') }}
                      </template>
                    </Column>
                    <Column class="asset-col" field="fareast_equity_percent">
                      <template #body="slotProps">
                        {{ $nb(slotProps.data.fareast_equity_percent, 'percentage') }}
                      </template>
                    </Column>
                    <Column class="asset-col" field="japan_equity_percent">
                      <template #body="slotProps">
                        {{ $nb(slotProps.data.japan_equity_percent, 'percentage') }}
                      </template>
                    </Column>
                    <Column class="asset-col" field="other_equity_percent">
                      <template #body="slotProps">
                        {{ $nb(slotProps.data.other_equity_percent, 'percentage') }}
                      </template>
                    </Column>
                    <Column class="asset-col" field="specialist_equity_percent">
                      <template #body="slotProps">
                        {{ $nb(slotProps.data.specialist_equity_percent, 'percentage') }}
                      </template>
                    </Column>
                    <Column class="asset-col" field=""></Column>
                    <Column class="asset-col" field=""></Column>
                    <Column class="asset-col" field=""/>
                  </DataTable>
                </div>
              </template>
            </Column>
            <template #groupheader="slotProps">
              <div :id="`rowGroupHeader-${slotProps.data.type}`">{{ formatRowGroupName(slotProps.data.type) }}</div>
            </template>
          </DataTable>
        </template>
      </content-card-full>
    </template>
  </Modal>
</template>
<script>
import {computed, ref} from 'vue'
import {useStore} from 'vuex'
import ContentCardFull from '@/components/common/ContentCardFull'
import Modal from '@/components/common/Modal'
import PortfolioSelect from "@/components/common/filters/PortfolioSelect";
import CurrencySelect from "@/components/common/filters/CurrencySelect";
import PeopleSelect from "@/components/common/filters/PeopleSelect";
import CurrencyValue from "@/components/common/CurrencyValue.vue";

export default {

  setup() {
    const store = useStore()

    // DEFAULT OF ['NonPens', 'Totals', 'Pens'] -> All Open
    const expandedRowGroups = ref(['NonPens', 'Totals', 'Pens']);
    const collapsedFundRows = ref([])

    const expandAll = () => {
      expandedRowGroups.value = ['NonPens', 'Totals', 'Pens']
    };
    const collapseAll = () => {
      expandedRowGroups.value = ['Totals'];
    };

    const formatRowGroupName = (name) => {
      switch (name) {
        case 'Pens':
          return 'Pension Holdings'
        case 'NonPens':
          return 'Non-Pension Holdings'
        case 'Totals':
          return name
      }
    }

    const getCurrency = computed(() => store.getters.currencySymbol)

    const getTotalColLabel = (label, table) => {
      switch (table) {
        case 'totals':
          if (label === 'pension') return `Total Pension (${store.getters.currencySymbol})`
          if (label === 'nonpension') return `Total Non-Pension (${store.getters.currencySymbol})`
          if (label === 'all') return `Total Value (${store.getters.currencySymbol})`
          break;
        case 'assets' :
          if (label === 'pension') return 'Asset Allocation - Pension'
          if (label === 'nonpension') return 'Asset Allocation - Non-Pension'
          if (label === 'all') return 'Asset Allocation - Total'
          if (label === 'benchmark') return 'Benchmark'
          break;
        case 'equity' :
          if (label === 'pension') return 'Equity Regional Allocation - Pension'
          if (label === 'nonpension') return 'Equity Regional Allocation - Non-Pension'
          if (label === 'all') return 'Equity Regional Allocation - Total'
          if (label === 'benchmark') return 'Benchmark'
          break;
      }
    }

    const toggleShowFund = (fund) => {
      if (collapsedFundRows.value.includes(fund)) {
        const idx = collapsedFundRows.value.indexOf(fund)
        collapsedFundRows.value.splice(idx, 1)
      } else {
        collapsedFundRows.value.push(fund)
      }
    }

    const closeModal = () => {
      store.dispatch('setActiveModal')
    }

    const testClass = (data) => {
      return ['Pens', 'NonPens'].includes(data.type) ? 'row-define' : null
    }


    return {
      expandedRowGroups,
      collapsedFundRows,
      expandAll,
      collapseAll,
      getTotalColLabel,
      toggleShowFund,
      formatRowGroupName,
      closeModal,
      fullAllocation: computed(() => store.getters.fullAllocation),
      assetAllocation: computed(() => store.getters.assetAllocation),
      allocationTotals: computed(() => store.getters.assetAllocationTotals),
      totalAllocation: computed(() => store.getters.totalAllocation),
      getCurrency,
      testClass,
    }
  },
  components: {
    Modal,
    PortfolioSelect,
    CurrencySelect,
    PeopleSelect,
    ContentCardFull,
    CurrencyValue
  }
}
</script>
<style scoped lang="scss">
#modal-full-asset-allocation {
  /* needs to be high to sit above all other elements, including nav*/
  z-index: 2150;
}

/* for safari, explicitly hide overflow */
#modal-full-asset-allocation:deep(.modal-body) {
  overflow-y: hidden;
}

/* content card */
.dropdown-group > .title-dropdown {
  padding-right: 20px;

  label {
    padding-right: 10px;
  }
}

.dropdown-group > .title-dropdown:first-of-type > label {
  margin-left: 0px;
}

/* allocation table - top level table */
#allocationTable:deep(.asset-wide-col) {
  flex: unset;
  width: calc((100% / 15) * 2);
  justify-content: center;
}

#allocationTable:deep(.asset-col) {
  flex: unset;
  width: calc(100% / 14);
  justify-content: flex-end;
}

#allocationTable:deep(.asset-wide-col.fund) {
  justify-content: flex-start;
  padding: 20px 30px;
}

#allocationTable:deep(.nested-table) {
  width: unset;
}

/* table header */
div[id='allocationEquityTotalsLabels']:deep(tr:last-of-type) {
  border-bottom-left-radius: 27px;
}

div[id='allocationEquityTotals']:deep(tr:last-of-type) {
  border-bottom-right-radius: 27px;
}

#allocationTable :deep(.p-datatable-table .p-datatable-thead .p-column-header-content .p-column-title) {
  padding-left: 30px;
}

#allocationTable :deep(.p-datatable-table .p-datatable-thead tr:first-of-type th:nth-of-type(5)) {
  border-bottom: 1px solid var(--clarity-pure-white);
}

#allocationTable :deep(.p-datatable-table .p-datatable-thead tr:first-of-type th:nth-of-type(5) div) {
  justify-content: center;
}

#allocationTable.full-allocation-table:deep(thead) {
  background: transparent linear-gradient(180deg, var(--clarity-light-blue) 0%, var(--clarity-blue) 100%) 0% 0% no-repeat padding-box;
  color: var(--clarity-pure-white);
  font-size: 14px;
  font-weight: normal;
  padding: 20px 30px;
  border-top-left-radius: 27px;
  border-top-right-radius: 27px;
}

#allocationTable.full-allocation-table:deep(thead th) {
  background: none;
  color: var(--clarity-pure-white);
  border: none;
}

/* row group headers */
#allocationTable.full-allocation-table:deep(.p-rowgroup-header) {
  font-weight: bold;
  background: transparent linear-gradient(180deg, var(--clarity-snow-grey) 0%, var(--clarity-light-grey) 100%) 0% 0% no-repeat padding-box;
}

div[id='allocationTable']:deep(tr.p-rowgroup-header) {
  top: 115px !important;
}

/* div[id='rowGroupHeader-Totals'] {
    margin-left: 30px;
} */


/* row striping */
div[id^='allocationTotals']:deep(.p-datatable-table tr:nth-child(even)),
div[id^='allocationAssetsTotals']:deep(.p-datatable-table tr:nth-child(even)),
div[id^='allocationEquityTotals']:deep(.p-datatable-table tr:nth-child(even)),
div[id^='nestedTable-']:deep(.p-datatable-table tr:nth-child(even)) {
  background-color: var(--clarity-snow-grey)
}

div[id^='allocationTotals']:deep(.p-datatable-table tr:last-of-type),
div[id^='allocationAssetsTotals']:deep(.p-datatable-table tr:last-of-type),
div[id^='allocationEquityTotals']:deep(.p-datatable-table tr:last-of-type) {
  background: transparent linear-gradient(180deg, #7CC2E2 0%, var(--clarity-light-blue) 100%) 0% 0% no-repeat padding-box;
  color: var(--clarity-pure-white);
}

/* general table styles */
#allocationTable.full-allocation-table:deep(td) {
  border: none;
}

/*  TESTING CLASS FOR DEFINING BETWEEN ROWS */
div[id^='allocationTable']:deep(tr.row-define) {
  border-top: 1px solid var(--clarity-light-grey) !important;
}

div[id^='allocationTable']:deep(tr:last-of-type .asset-wide-col),
div[id^='allocationTable']:deep(tr:last-of-type td:nth-child(2)) {
  padding: 0px;
}

div[id^='allocationTable']:deep(tr:last-of-type .asset-wide-col thead tr th),
div[id^='allocationTable']:deep(tr:last-of-type td:nth-child(2) thead tr th) {
  display: none !important;
}

div[id^='allocationTable']:deep(tr:last-of-type thead tr) {
  height: 0px;
}

div[id^='allocationTable']:deep(tr:last-of-type tr) {
  height: 60px;
}

/* all nested/child tables */
#nestedTable:deep(.p-datatable-thead > tr) {
  display: none;
}

div[id^='rowSummaryTable-']:deep(thead tr th),
div[id^='nestedTable-']:deep(thead tr th) {
  display: none !important;
}

div[id^='allocationTotals']:deep(tr td:first-of-type),
div[id^='allocationTotals']:deep(tr td:last-of-type),
div[id^='allocationAssetsTotals']:deep(tr:last-of-type),
div[id^='allocationEquityTotals']:deep(tr:last-of-type) {
  font-weight: bold;
}

/* required to override the styling of same parent/child in higher table */
div[id^='nestedTable-']:deep(tr:last-of-type td:nth-child(2)) {
  padding: 20px 30px;
}

/*  duplicated from TabAssetllocation */
.title {
  color: var(--clarity-blue);
  padding: 30px;
  padding-bottom: 0px;
}

.bottom-actions {
  display: flex;
  justify-content: space-between;
}

.bottom-actions button {
  padding: 0px 45px;
  margin-right: 20px;
}

div:deep(.modal-close) {
  display: none;
}

</style>
