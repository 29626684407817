<template>
  <Dropdown :model-value="selectedOption" :options="currencyOptions" optionLabel="currencyCode" optionValue="currencyCode" :loading="loading" @change="updateCurrencySelected" @before-show="stopScroll" @before-hide="allowScroll" ></Dropdown>
</template>

<script>
import {useStore} from "vuex";
import {computed, onMounted, inject, ref, watch} from "vue";

export default {
  name: "CurrencySelect",
  setup() {
    const store = useStore()
    const bus = inject('bus')

    const currentSelectedOption = computed(() => store.getters.currencySelected)
    const selectedOption = ref(currentSelectedOption.value ?? '')

    watch(currentSelectedOption, (value) => {
        selectedOption.value = value;
    })

    onMounted(() => {
      getCurrencyOptions()
    })

    const getCurrencyOptions = () => {
      store.dispatch('getCurrencyOptions')
    }

    const updateCurrencySelected = (data) => {
      store.dispatch('setCurrencySelected', data.value)
    }

    const currencyOptions = computed(() => {
      const options = store.state.selections.currencyOptions;
      if(options && options.error){
        store.dispatch('addNotification', {severity: 'error', summary: 'Currency Select', detail: options.message })
        return [
          {"currencyCode":"GBP","currencySymbol":"\u00a3","numericCode":"826","conversionrate":"1.00000000000000","date":"2021-07-21"}
        ];
      }else{
        return options;
      }
    })

    const stopScroll = () => {
      bus.emit('stopScroll')
    }
    const allowScroll = () => {
      bus.emit('allowScroll')
    }

    return {
      currentSelectedOption,
      selectedOption,
      loading: computed(() => store.state.selections.currencyLoading),
      currencyOptions,
      updateCurrencySelected,
      stopScroll,
      allowScroll
    }
  }
}
</script>

<style scoped>
.p-dropdown {
  width: 300px;
}

@media only screen and (max-width: 1366px)  {
  .p-dropdown {
    width: 170px;
  }
}

@media only screen and (max-width: 1024px)  {
  .p-dropdown {
    width: 150px;
  }
}

@media only screen and (max-width: 768px)  {
  .p-dropdown {
    width: 138px;
  }
}
</style>
