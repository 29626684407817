<template>
  <Dropdown :model-value="selectedOption" :options="periodOptions" optionLabel="label" optionValue="value" @change="updatePeriodSelected" @before-show="stopScroll" @before-hide="allowScroll" ></Dropdown>
</template>

<script>
import {useStore} from "vuex";
import {computed, inject} from "vue";

export default {
  name: "PeriodSelect",
  setup() {
    const store = useStore()
    const bus = inject('bus')

    const updatePeriodSelected = (data) => {
      store.dispatch('setPeriodSelected', data.value)
    }

    const stopScroll = () => {
      bus.emit('stopScroll')
    }
    const allowScroll = () => {
      bus.emit('allowScroll')
    }

    return {
      periodOptions: computed( () => store.state.selections.periodOptions),
      selectedOption: computed(() => store.state.selections.periodSelected ),
      updatePeriodSelected,
      allowScroll,
      stopScroll
    }
  }
}
</script>

<style scoped>
.p-dropdown {
  width: 300px;
}

@media only screen and (max-width: 1366px)  {
  .p-dropdown {
    width: 170px;
  }
}

@media only screen and (max-width: 1024px)  {
  .p-dropdown {
    width: 150px;
  }
}

@media only screen and (max-width: 768px)  {
  .p-dropdown {
    width: 138px;
  }
}
</style>
