<template>
  <Dialog v-model:visible="showRenewDialog" class="renew-dialog">
    <div class="flex justify-content-between modal-header">
      <h3 class="blue">Renew Access</h3>
      <Button class="clarity-close-modal-btn" @click="toggleRenewDialog"><span
          class="material-icons-round grey">close</span></Button>
    </div>
    <div class="py-3" v-if="stage == 1">
      <p class="body-text">clarityONLINE’s data partner, TrueLayer, would like to renew a 90-day access to your Monzo account details. If you don’t renew, you won’t be able to see your accounts with other banks on clarityONLINE.</p>
      <p class="body-text"><b>What details am I sharing?</b></p>
      <p class="body-text">To provide its services, TrueLayer needs permission to access the following information and share it with clarityONLINE:
        <ul>
          <li>Full name</li>
          <li>Account number and sort code</li>
          <li>Balance</li><li>Transactions, direct debits and standing orders</li>
        </ul>
      </p>
      <p class="body-text">TrueLayer is FCA-regulated, and won’t share or use your personal data for anything else. To keep your information secure, TrueLayer connects to your account using bank-grade encryption.</p>
      <p>
        <span class="small-text grey">
          By tapping ‘Proceed’, you agree to TrueLayer’s  <a href="https://truelayer.com/enduser_tos" target="_blank">Terms of Service</a> and <a href="https://truelayer.com/privacy" target="_blank">Privacy Policy</a>.
        </span>
      </p>
    </div>
    <div class="flex justify-content-between">
      <Button class="clarity-btn clarity-gradient-grey" @click="toggleRenewDialog">Dismiss</Button>
      <submit-button @submitted="confirmRenew" :loading="loadingState" button-text="Proceed" loading-text="Renewing access..." ></submit-button>
    </div>
  </Dialog>
</template>

<script>
import Dialog from "primevue/dialog";
import {computed, toRef, ref} from "vue";
import {useStore} from "vuex";
import SubmitButton from "@/components/common/layout/interactive/SubmitButton";


export default {
  name: 'RenewAccessDialog',
  components: {
    SubmitButton,
    Dialog
  },
  props: {
    id: String,
    show: Boolean
  },
  emits: ['toggleRenewDialog'],
  setup(props, context) {
    const store = useStore()
    const uuid = toRef(props, 'id')
    const showRenewDialog = toRef(props, 'show')
    const loadingState = computed(() => store.getters.isLoading('RenewOpenBanking'))

    const stage = ref(1);


    const toggleRenewDialog = () => {
      // showRenewDialog.value = !showRenewDialog.value
      context.emit('toggleRenewDialog')
    }

    const confirmRenew = async () => {
      await store.dispatch('setLoading', {id: 'RenewOpenBanking', state: true})
      const result = await store.dispatch('renewAccess', uuid.value)
      if(result === true) {
        alert('success renew complete')
      }else if(result){
        document.location.href = result;
      }else{
        alert('failed!')
      }
      await store.dispatch('setLoading', {id: 'RenewOpenBanking', state: false})
      toggleRenewDialog()
    }

    return {
      confirmRenew,
      toggleRenewDialog,
      showRenewDialog,
      loadingState,
      stage
    }
  }
}
</script>
<style scoped lang="scss">
.renew-dialog {
  .clarity-btn {
    padding: 15px 45px;
  }
}
</style>
