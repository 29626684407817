export default {
    mounted(el, binding) {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.1
        };

        const callback = (entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    binding.value(); // Call the bound function
                }
            });
        };

        const observer = new IntersectionObserver(callback, options);
        observer.observe(el);

        el.__visibleObserver__ = observer; // Store reference to the observer for cleanup
    },
    unmounted(el) {
        if (el.__visibleObserver__) {
            el.__visibleObserver__.disconnect();
        }
    }
};
