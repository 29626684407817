<template>
  <Dialog class="modal" style="width: 50%">
      <div v-if="info.error">
          <div class="flex justify-content-end title align-items-center mb-3">
              <div class="flex justify-content-center" style="width: 90%">
              </div>
              <div class="flex justify-content-end" style="width: 10%">
                  <Button class="clarity-close-modal-btn" @click="closeModal"><span class="material-icons-round grey">close</span>
                  </Button>
              </div>
          </div>
          <h3 class="flex justify-content-center">No Data Currently Available</h3>
      </div>
      <div v-else>
        <div class="flex justify-content-end title align-items-center mb-3">
          <div class="flex justify-content-center" style="width: 90%">
            <Skeleton v-if="!info.name" class="mb-2" height="2rem" borderRadius="16px"></Skeleton>
            <h3 v-if="info.name" class="blue">{{ info.name }}</h3>
          </div>
          <div class="flex justify-content-end" style="width: 10%">
            <Button class="clarity-close-modal-btn" @click="closeModal"><span class="material-icons-round grey">close</span>
            </Button>
          </div>
        </div>
        <div v-if="!info.cash">
          <Skeleton class="mb-2" height="2rem" borderRadius="16px"></Skeleton>
        </div>
        <div v-else>
          <DataTable :value="[info]">
            <Column v-for="col in holdingAllocationCols" :field="col.field" :header="col.header" :key="col.field"></Column>
          </DataTable>
        </div>
        <spacer-h/>
        <h5>Equity Regional Allocation</h5>
        <div v-if="!info.equity">
          <Skeleton class="mb-2" height="2rem" borderRadius="16px"></Skeleton>
        </div>
        <div v-else>
          <DataTable :value="[info]" v-if="!loading">
            <Column v-for="col in holdingAllocationRegionalCols" :field="col.field" :header="col.header"
                    :key="col.field"></Column>
          </DataTable>
        </div>
      </div>
  </Dialog>
</template>
<script>
import {computed, ref} from 'vue'
import SpacerH from '@/components/common/layout/SpacerH.vue'
import {useStore} from "vuex";

export default {
  name: 'ValuationFundAllocationModal',
  props: {
    loading: Boolean
  },
  emits: ['closeModal'],
  setup(props, context) {
    const store = useStore()
    const holdingAllocationCols = ref([
      {'field': "cash", 'header': "Cash"},
      {'field': "fixed_interest", 'header': "Fixed Interest"},
      {'field': "equity", 'header': "Equity"},
      {'field': "property", 'header': "Other"}
    ])

    const holdingAllocationRegionalCols = ref([
      {'field': "uk_equity", 'header': "UK"},
      {'field': "us_equity", 'header': "US"},
      {'field': "european_equity", 'header': "European"},
      {'field': "fareast_equity", 'header': "Asia Pacific"},
      {'field': "japan_equity", 'header': "Japan"},
      {'field': "other_equity", 'header': "Emerging Markets"},
      {'field': "specialist_equity", 'header': "Specialist"}
    ])

    const closeModal = () => {
      context.emit('closeModal')
    }

    return {
      info: computed(() => store.getters.getFundAllocationModalData),
      holdingAllocationCols,
      holdingAllocationRegionalCols,
      closeModal
    }
  },
  components: {
    SpacerH
  }
}
</script>
<style scoped>
.modal :deep(.p-dialog-content) {
  border-bottom-left-radius: 27px;
  border-bottom-right-radius: 27px;
}

.fund-info-modal {
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  align-self: center;
}

.fund-title {
  color: var(--clarity-blue);
}

.label {
  font-weight: bold;
  margin-right: 10px;
}

.row {
  padding: 10px;
}

.row:nth-child(odd) {
  background-color: var(--clarity-light-grey);
}

.source {
  color: var(--clarity-grey);
  font-weight: bold;
}
</style>
