<template>
    <div>
      <div ref="valuationsummarychart" class="valuation-summary-chart">

      </div>
    </div>
</template>

<script>
    import * as am5 from '@amcharts/amcharts5';
    import * as am5percent from '@amcharts/amcharts5/percent'
    import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
    import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
    import {ref, watch, computed, onMounted} from 'vue'
    import {useStore} from "vuex";
    import {formatter} from "../../core/services/formatter";

    export default {
      name: 'ValuationSummaryChart',
      setup() {

        const store = useStore();

        const chartParts = ref({});
        const chartRendered = ref(false);
        const valuationsummarychart = ref(null);
        const callFilters = computed(() => store.getters.valuationDateCallFilters)
        const policyOptions = computed(() => store.getters.policyOptions)
        const exporting = ref();

        const policyOptionsValid = computed( () => {
          if(policyOptions.value && !policyOptions.value.error){
            //check if policy options are loading
            if(!store.state.selections.policyLoading) {
              return true;
            }
          }
          return false;
        })

        const chartData = computed(() => {
          const theData = store.state.valuation.valuationSummaryChartData;
          if (theData && theData.error) {
            store.dispatch('addNotification', {severity: 'error', summary: 'Valuation Summary Chart', detail: theData.message})
            return false;
          } else {
            return theData;
          }
        })

        const selectedOption = computed(() => store.getters.currencySelected );
        const valuationTotal = computed(() => {

          let value = 0;
          if(chartData.value) {
            chartData.value.forEach((record) => {
              value += record.value;
            })

          }
          return formatter.currency(selectedOption.value, value, 2)

        })

        watch(valuationTotal, () => {
          if(chartParts.value.label) {
            chartParts.value.label.set("text", "[fontSize:20px]VALUATION\nSUMMARY[/]\n\n[bold fontSize:30px #0058A9]" + valuationTotal.value + "[/]\n\n[bold fontSize:12px #8B9682]Net Worth[/]");
          }
        })

        watch(policyOptions, () => {
          if(policyOptionsValid.value) {
            getChartData(callFilters.value);
          }
        })

        watch(callFilters, () => {
          if(policyOptionsValid.value) {
            getChartData(callFilters.value);
          }
        })

        onMounted(() => {
          if(policyOptionsValid.value) {
            getChartData(callFilters.value);
          }
        })

        watch(chartData, (value) => {
          if(chartRendered.value){
            updateChart(chartParts, value)
          }else{
            renderChart(chartParts, value)
            chartRendered.value = true;
          }
        })

        const getChartData = (filters) => {
          store.dispatch('getValuationSummaryChartData', filters)
        }

        const renderChart = (chartParts, data) => {

          am5.addLicense("AM5C334338446");

          let root = am5.Root.new(valuationsummarychart.value);
          root.setThemes([am5themes_Animated.new(root)]);

          // Create chart
          // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/
          var chart = root.container.children.push(am5percent.PieChart.new(root, {
            layout: root.verticalLayout,
            innerRadius: 110,
          }));

          // Create series
          // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Series
          chartParts.value.series = chart.series.push(am5percent.PieSeries.new(root, {
            valueField: "value",
            categoryField: "name",
            legendValueText: ""
          }));

          chartParts.value.series.labels.template.set("forceHidden", true);
          chartParts.value.series.ticks.template.set("forceHidden", true);

          chartParts.value.series.slices.template.setAll({
            stroke: am5.color("#ffffff"),
            strokeWidth: 4
          });

          chartParts.value.series.slices.template.set("fillGradient", am5.LinearGradient.new(root, {
            stops: [{
              brighten: 0.8
            }, {
              brighten: 0
            }],
          }));

          chartParts.value.series.get("colors").set("colors", [
            am5.color("#AEDCF1"),
            am5.color("#179BD7"),
            am5.color("#0058A9"),
            am5.color("#019567"),
            am5.color("#8B96A2")
          ]);

          // Set data
          // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Setting_data
          chartParts.value.series.data.setAll(data);

          // Create legend
          // https://www.amcharts.com/docs/v5/charts/percent-charts/legend-percent-series/
          chartParts.value.legend = chart.children.push(am5.Legend.new(root, {
            centerX: am5.percent(50),
            x: am5.percent(50),
            marginTop: 15,
            marginBottom: 15,
            layout: root.verticalLayout
          }));

          chartParts.value.legend.labels.template.setAll({
            maxWidth: 50,
            oversizedBehavior: "truncate",
            fontSize: 14,
            fontWeight: "400",
            marginLeft: 18,
            centerY: 6
          });

          chartParts.value.legend.markerRectangles.template.setAll({
            cornerRadiusTL: 15,
            cornerRadiusTR: 15,
            cornerRadiusBL: 15,
            cornerRadiusBR: 15,
            width: 25,
            height: 25
          });

          chartParts.value.legend.data.setAll(chartParts.value.series.dataItems);


            // Play initial series animation
          // https://www.amcharts.com/docs/v5/concepts/animations/#Animation_of_series
          chartParts.value.series.appear(1000, 100);

          chartParts.value.label = chart.seriesContainer.children.push(
              am5.Label.new(root, {
                textAlign: "center",
                centerY: am5.percent(50),
                centerX: am5.p50,
                text: "[fontSize:20px]VALUATION\nSUMMARY[/]\n\n[bold fontSize:30px #0058A9]"+valuationTotal.value+"[/]\n\n[bold fontSize:12px #8B9682]Net Worth[/]"
              })
          );

          exporting.value = am5plugins_exporting.Exporting.new(root, {});
          setTimeout(function () {
              exportImage();
          } , 1000);

        }

        const exportImage = () => {
          return exporting.value.export("png").then(function(imgData) {
              store.commit('setPDFGraph2', imgData);
          });
        }

        const updateChart = (series, data) => {
          chartParts.value.series.data.setAll(data);
          chartParts.value.label.set("text", "[fontSize:20px]VALUATION\nSUMMARY[/]\n\n[bold fontSize:30px #0058A9]"+valuationTotal.value+"[/]\n\n[bold fontSize:12px #8B9682]Net Worth[/]");
          chartParts.value.legend.data.setAll(chartParts.value.series.dataItems);
          chartParts.value.legend.labels.template.setAll({
              maxWidth: 250,
              oversizedBehavior: "truncate",
              fontSize: 14,
              fontWeight: "400",
              marginLeft: 18,
              centerY: 6
          });
          setTimeout(function () {
              exportImage();
          } , 1000);
        }

        return {
          chartParts,
          chartRendered,
          valuationsummarychart,
          exportImage
        }
      }
    }
</script>

<style scoped lang="scss">
    .valuation-summary-chart {
      position: relative;
      width: 100%;
      height: 600px;
    }
</style>
