<template>
  <div>
    <Button
        type="button"
        @click="toggle"
        aria-haspopup="true"
        :aria-controls="`download-options-${id}`"
        :id="`download-options-button-${id}`"
        class="clarity-btn"
        :class="[ isVisible ? 'visible' : '', colourClass ]"
        :disabled="loading"
    >
      <div class="flex">
        <span v-if="loading" class="material-icons-round mr-3" :class="iconColour">
          <ProgressSpinner style="width:30px;height:30px" strokeWidth="8"/>
        </span>
        <span v-else class="material-icons-round mr-3" :class="iconColour">download</span>{{ buttonText }}
      </div>
    </Button>
    <Menu :id="`download-options-${id}`" ref="menu" :model="items" :popup="true">
      <template #item="{item}">
        <router-link :to="item.to" v-if="item.to">
          <div class="p-menuitem-link">
            <span v-if="!item.icon.includes('icon')" class="material-icons-round grey mr-3">{{ item.icon }}</span>
            <Icon v-if="item.icon.includes('icon')" :name="item.icon" :altText="item.icon" class="mr-3 icon-grey"/>
            {{ item.label }}
          </div>
        </router-link>
        <a :href="item.url" target="_blank" v-else-if="item.url">
          <div class="p-menuitem-link">
            <span v-if="!item.icon.includes('icon')" class="material-icons-round grey mr-3">{{ item.icon }}</span>
            <Icon v-if="item.icon.includes('icon')" :name="item.icon" :altText="item.icon" class="mr-3 icon-grey"/>
            {{ item.label }}
          </div>
        </a>
        <div v-else class="p-menuitem-link" @click="item.command(); toggle();">
          <span v-if="!item.icon.includes('icon')" class="material-icons-round grey mr-3">{{ item.icon }}</span>
          <Icon v-if="item.icon.includes('icon')" :name="item.icon" :altText="item.icon" class="mr-3 icon-grey"/>
          {{ item.label }}
        </div>
      </template>
    </Menu>
  </div>
</template>
<script>
import {computed, ref } from 'vue'
import Icon from '../../../common/Icon.vue'
import {useStore} from "vuex";

export default {
  props: {
    items: Array,
    id: String,
    buttonText: {
      default: "Download Report",
      type: String
    },
    colourClass: {
      default: "clarity-gradient-orange",
      type: String
    }
  },
  components: {
    Icon
  },
  setup(props) {
    const menu = ref()
    /*
    const bus = inject('bus')
    const isDisabled = ref(false)
    */
    const store = useStore()
    const toggle = (event) => {
      menu.value.toggle(event);
    };

    const isVisible = computed(() => {
      return menu.value && menu.value.overlayVisible == true
    })

    const iconColour = computed(() => {
      if (props.colourClass === 'clarity-gradient-orange') {
        return 'white';
      }
      return 'grey';
    })
    /*

    // can be refactored to be reuseable on next pdf type creation
    bus.on('valuationPDF', (type) => {
      toggleButtonState(type)
    })

    bus.on('valuationExcel', (type) => {
      toggleButtonState(type)
    })

    bus.on('isinExcel', (type) => {
      toggleButtonState(type)
    })

    bus.on('transactionsExcel', (type) => {
      toggleButtonState(type)
    })

    bus.on('allocationExcelCondensed', (type) => {
      toggleButtonState(type)
    })

    bus.on('allocationExcelFull', (type) => {
      toggleButtonState(type)
    })

    const toggleButtonState = (type) => {
      switch (true) {
        case type == 'start':
          isDisabled.value = true
          break
        case type == 'stop':
          isDisabled.value = false
          break
      }
    }
    */

    return {
      menu,
      toggle,
      isVisible,
      //isDisabled,
      iconColour,
      loading: computed(() => store.getters.isAnyLoading)
    }
  },
}
</script>
<style scoped>
button {
  width: 100%;
  justify-content: center;
}

img {
  opacity: 1;
}

.visible {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.visible:deep(.p-menu-overlay) {
  border-radius: unset !important;
}

.icon-grey {
  filter: invert(66%) sepia(16%) saturate(261%) hue-rotate(171deg) brightness(87%) contrast(90%);
}
</style>
