import dayjs from "dayjs";
import PlanSection from '../../classes/ValuationExcelExportPlanSection'
import * as ExportData from './ValuationDataExtractor'
import {formatter} from "../formatter";
import store from "@/store";

export async function buildValuationExcelPayload(valuationDate){

    const adviser = { name: store.getters.getAdviserName };
    const formattedDateDMY = dayjs(valuationDate).format('DD/MM/YYYY')
    const todayDateDMY = dayjs().format('DD/MM/YYYY')

    const baseObject = {
        details: {},
        filters: {},
        summary: {},
        plans: {}
    }

    let detailsSet = await buildHeaderSection()
    let filtersSet = await buildFilterSection()
    let summarySet = await buildSummarySection(valuationDate)
    let plansSet = await buildPlansSection()

    if (detailsSet && filtersSet && summarySet && plansSet) {
        return baseObject
    }
    async function buildHeaderSection() {
        const primaryClient = await ExportData.getPrimaryClient()
        const adviserName = adviser.name

        return baseObject.details = {
            "sectionheader": [],
            "sectioncontent": [
                {
                    "A": "Client Name",
                    "G": primaryClient
                },
                {
                    "A": "Exporting Date",
                    "G": todayDateDMY
                },
                {
                    "A": "Adviser Name",
                    "G": adviserName
                }
            ],
            "sectionfooter": []
        }
    }

    async function buildFilterSection() {
        let portfolio = await ExportData.getSelectedPortfolio()
        let policy = await ExportData.getPolicies()

        return baseObject.filters = {
            "sectionheader": [],
            "sectioncontent": [
                {
                    "A": "Portfolio",
                    "G": portfolio
                },
                {
                    "A": "People",
                    "G": await ExportData.getSelectedPeople()
                },
                {
                    "A": "Policies",
                    "G": await policy
                },
                {
                    "A": "Valuations Date",
                    "G": formattedDateDMY
                }
            ],
            "sectionfooter": []
        }
    }

    async function buildSummarySection(date) {
        const summary = {
            "sectionheader": [],
            "sectioncontent": [],
            "sectionfooter": []
        }

        const summaryData = await ExportData.getSummarySectionData(date)

        const currency = store.state.selections.currencySelected;

        for (let obj of summaryData) {
            if (obj.holding) {
                summary.sectioncontent.push({A: obj.holding, G: parseFloat(formatter.currency(currency, obj.value, 2, true))})
            }
            if (obj.total) {
                summary.sectionfooter.push({A: "Total Valuation", G: parseFloat(formatter.currency(currency, obj.value, 2, true))})
            }
        }

        baseObject.summary = summary

        return summary
    }

    async function buildPlansSection() {
        const plansObject = {
            sectionheader:[],
            sectioncontent:[],
            sectionfooter: []
        }

        const formattedDate = dayjs(valuationDate).format('YYYY-MM-DD')

        let plans = await ExportData.getPlans(formattedDate)

        for (const [name, rows, owner] of plans) {
            let section = new PlanSection(name, owner, rows)
            plansObject.sectioncontent.push(section.getSection())
        }

        baseObject.plans = plansObject

        return plansObject
    }
}
