import api from '@/core/services/api'
import pdfGeneration from "@/core/services/documentExport/pdfGeneration";
import {pdfStyles} from "./documentExport/pdfCommon";
import store from "@/store";
import {toDrupalRequest} from '@/core/helpers';
import { v4 as uuidv4 } from 'uuid';

export async function downloadDocument(type, content, fileName, key){

    if(type === 'pdf') {

        // create the PDF document object.
        let css = pdfStyles();
        if(key === 'imsapplication_pdf'){
            // update the margins
            css += 'body {margin-top: 2cm; margin-bottom: 2cm; margin-left: 1.5cm; margin-right: 1.5cm; }'
        }
        const pdf = new pdfGeneration({css: css, html: content});

        // make the call
        const endpoint = '/document_export';
        const response = await api.post(
            `${toDrupalRequest(endpoint)}`,
            // generate the HTML and submit it in the payload.
            JSON.stringify({
                payload: pdf.buildHTML(),
                request_type: 'PDF',
                request_key: key,
                request_id: uuidv4()
            }),
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
        if (response) {
            // CSD-2775
            // Update to support download as PDF on iOS (Safari issues with anchor and use of download)

            // Convert base64 to a Blob; create a URL for the Blob
            const pdfData = atob(response.payload); // Decode base64
            const byteArray = new Uint8Array(pdfData.length);
            for (let i = 0; i < pdfData.length; i++) {
                byteArray[i] = pdfData.charCodeAt(i);
            }
            const blob = new Blob([byteArray], {type: "application/pdf"});

            // Create an Object URL for the Blob
            const objectURL = URL.createObjectURL(blob);

            // Use the Object URL for the download link
            const downloadLink = document.createElement("a");
            downloadLink.href = objectURL;
            downloadLink.download = fileName;
            document.body.appendChild(downloadLink); // Append to body to ensure visibility in the DOM
            downloadLink.click();
            store.dispatch('setLoading', {id: key, state: false});
            document.body.removeChild(downloadLink); // Clean up

            // Revoke the Object URL to free up resources
            URL.revokeObjectURL(objectURL);

        } else {
            await store.dispatch('addNotification', {
                severity: 'error',
                summary: 'Download Report',
                detail: `Failed to download the PDF`
            })
            store.dispatch('setLoading', {id: key, state: false})
        }
    }else if(type === 'excel'){

        // create the PDF document object.
        const endpoint = '/document_export';
        const response = await api.post(
            `${toDrupalRequest(endpoint)}`,
            // generate the HTML and submit it in the payload.
            JSON.stringify({
                payload: content,
                request_type: 'Excel',
                request_key: key,
                request_id: uuidv4()
            }),
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
        if (response) {
            //output the pdf to the browser.
            const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${response.payload}`;
            const downloadLink = document.createElement("a");
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
            store.dispatch('setLoading', {id: key, state: false})
        } else {
            await store.dispatch('addNotification', {
                severity: 'error',
                summary: 'Download Report',
                detail: `Failed to download the Excel`
            })
            store.dispatch('setLoading', {id: key, state: false})
        }

    }

}


