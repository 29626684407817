<template>
  <div>
    <div :class="expiresClass">
      <div class="flex p-3 align-content-center">
        <span class="material-icons-round pr-3" style="color: var(--clarity-green)" v-if="expires > 60">check_circle</span>
        <span class="material-icons-round pr-3" style="color: var(--clarity-orange)" v-else-if="expires > 30">warning</span>
        <span class="material-icons-round pr-3" style="color: var(--clarity-red)" v-else>error</span>
        <span v-if="expires > 0">Account Consent Expires in {{ expires }} days.</span>
        <span v-else>Account consent expired, <span><u>please renew</u></span>.</span>
      </div>
    </div>
    <div class="flex pt-5 justify-content-center">
      <Button class="clarity-btn round clarity-gradient-orange mx-2" v-tooltip.top="'Renew Access'" v-if="expires <= 60" @click="toggleRenewDialog"><span class="material-icons-round white">autorenew</span></Button>
      <Button class="clarity-btn clarity-gradient-grey round mx-2" v-tooltip.top="'Revoke Access/Remove'" @click="toggleRevokeDialog"><span class="material-icons-round dark-grey">delete_forever</span></Button>
      <router-link to="/investments/transactions">
        <Button class="clarity-btn clarity-gradient-grey round mx-2" v-tooltip.top="'View Transactions'"><span class="material-icons-round dark-grey">sync_alt</span></Button>
      </router-link>
      <router-link to="/investments/permissions">
        <Button class="clarity-btn round clarity-gradient-grey mx-2" v-tooltip.top="'Give Permission'"><span class="material-icons-round dark-grey">group_add</span></Button>
      </router-link>
    </div>
    <RevokeAccessDialog :id="account.credentials_id" :show="showRevokeDialog" @toggleRevokeDialog="toggleRevokeDialog"/>
    <RenewAccessDialog :id="account.credentials_id" :show="showRenewDialog" @toggleRenewDialog="toggleRenewDialog"/>
  </div>
</template>
<script>


import {computed, ref, toRef} from "vue";
import RevokeAccessDialog from "@/components/valuations/RevokeAccessDialog.vue";
import RenewAccessDialog from "@/components/valuations/RenewAccessDialog.vue";

export default {
  name: "OpenBankingActions",
  components: {
    RevokeAccessDialog,
    RenewAccessDialog
  },
  props: {
    account: Object
  },
  setup(props) {

    const account = toRef(props, 'account')

    const calculateDaysFromToday = (date) => {
      const today = new Date();
      const givenDate = new Date(date);

      // Calculate the difference in milliseconds
      const diffInMillis = givenDate - today;

      // Convert milliseconds to days
      const diffInDays = Math.round(diffInMillis / (1000 * 60 * 60 * 24));

      return diffInDays;
    }

    const expires = computed(() => {
      return calculateDaysFromToday(account.value['consent_expires'])
    })

    const expiresClass = computed(() => {
      if(expires.value <= 0){
        return 'expired';
      }
      if(expires.value <= 30){
        return 'expiring'
      }
      if(expires.value <= 60){
        return 'warning'
      }
      return 'active'
    })

    const showRevokeDialog = ref(false)
    const toggleRevokeDialog = () => {
      showRevokeDialog.value = !showRevokeDialog.value
    }

    const showRenewDialog = ref(false)
    const toggleRenewDialog = () => {
      showRenewDialog.value = !showRenewDialog.value
    }

    return {
      calculateDaysFromToday,
      expires,
      expiresClass,
      showRevokeDialog,
      toggleRevokeDialog,
      showRenewDialog,
      toggleRenewDialog
    }
  }
}
</script>
<style scoped>
  .expired {
    background: var(--clarity-pure-white) 0% 0% no-repeat padding-box;
    border: 1px solid var(--clarity-red);
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 20px #0000000D;
    border: 1px solid #D13138;
    border-radius: 27px;
    opacity: 1;
    color: var(--clarity-red);
    font: normal normal bold 16px Open Sans;
    letter-spacing: 0px;
  }
  .expiring {
    background: transparent linear-gradient(180deg, #F2686E33 0%, #D131384D 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 20px #0000000D;
    border: 1px solid #D1313880;
    border-radius: 27px;
    opacity: 1;
    color: var(--clarity-red);
    font: normal normal bold 16px Open Sans;
    letter-spacing: 0px;
  }
  .warning {
    background: transparent linear-gradient(180deg, #FFBA6233 0%, #F189034D 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 20px #0000000D;
    border: 1px solid #F1890380;
    border-radius: 27px;
    opacity: 1;
    color: var(--clarity-orange);
    font: normal normal bold 16px Open Sans;
    letter-spacing: 0px;
  }
  .active {
    background: transparent linear-gradient(180deg, #1CD37F33 0%, #0195674D 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 20px #0000000D;
    border: 1px solid #01956780;
    border-radius: 27px;
    opacity: 1;
    color: var(--clarity-green);
    font: normal normal bold 16px Open Sans;
    letter-spacing: 0px;
  }
</style>
