<template>
  <Dialog class="modal" style="width: 50%">
    <div class="mb-5">
      <div class="flex justify-content-center mb-3">
        <div class="flex justify-content-center" style="width: 90%">
          <h3 class="blue">{{ name.name }}</h3>
        </div>
        <div class="flex justify-content-end" style="width: 10%">
          <Button class="clarity-close-modal-btn" @click="closeModal"><span class="material-icons-round grey">close</span>
          </Button>
        </div>
      </div>
      <div v-show="chartData && chartData.length > 0 && !hasError">
        <div ref="fundhistorychart" class="fund-history-modal-chart"></div>
      </div>
      <div v-if="hasError">
        <h3 class="flex justify-content-center">No Data Currently Available</h3>
      </div>
      <div v-else-if="!(chartData && chartData.length > 0)">
        <Skeleton class="mb-2" height="400px" borderRadius="16px"></Skeleton>
      </div>
    </div>
  </Dialog>
</template>
<script>
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import dayjs from "dayjs";
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import {ref, watch, onMounted, computed } from 'vue'
import {useStore} from "vuex";

export default {
  name: 'ValuationFundHistoryChart',
  emits: ['closeModal'],
  setup(props, context) {
    const store = useStore()
    const series = ref(null);
    const chartRendered = ref(false);
    const fundhistorychart = ref(null)
    const hasError = ref(false)

    const chartData = computed(() => store.getters.getFundHistoryModalData)

    onMounted(() => {
      // on mount render the chart.
      if(!chartData.value || typeof chartData.value.error !== 'undefined') {
        hasError.value = true;
      }else if(chartData.value.length > 0) {
        renderChart(series, chartData.value)
        chartRendered.value = true;
        hasError.value = false;
      }else{
        hasError.value = false;
      }
    })

    // check for chart data changes
    watch(chartData, (value) => {
      // if the chart already exists update the dataset.
      if(!value || typeof value.error !== 'undefined') {
        hasError.value = true;
      }else if(value.length > 0) {
        hasError.value = false;
        if (chartRendered.value) {
          updateChart(series, value)
        } else {
          // else render the chart with the new data.
          renderChart(series, value)
          chartRendered.value = true;
        }
      }else{
        hasError.value = false;
      }
    })

    const renderChart = (series, data) => {

      //create the chart instance using the ref to the wrapper in the component
      let root = am5.Root.new(fundhistorychart.value);
      //set the base theme.
      root.setThemes([am5themes_Animated.new(root)]);

      // add the pan and wheel features to the chart
      var chart = root.container.children.push(am5xy.XYChart.new(root, {
        panX: true,
        panY: true,
        wheelX: "panX",
        wheelY: "zoomX"
      }));

      // Create axes
      // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
      // first the X Axis set to day.
      var xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
        baseInterval: {
          timeUnit: "day",
          count: 1
        },
        renderer: am5xy.AxisRendererX.new(root, {}),
        tooltip: am5.Tooltip.new(root, {}),
        snapTooltip: true
      }));

      // then the y axis, a simple value axis
      var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {})
      }));

      // Add series
      // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
      // tie the Y field to the Value property of the data.
      // and the X field to the End Date property of the data.
      series.value = chart.series.push(am5xy.LineSeries.new(root, {
        name: "Series",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "Value",
        valueXField: "EndDate",
        tooltip: am5.Tooltip.new(root, {
          labelText: "{valueY}"
        })
      }));

      // Add scrollbar
      // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
      chart.set("scrollbarX", am5.Scrollbar.new(root, {
        orientation: "horizontal"
      }));

      // parse the data to fit the format.
      const parsedData = data.map((record) => {
        const EndDate = dayjs(record.EndDate).unix() * 1000;
        return {
          "EndDate": EndDate,
          "Value": record.Value * 1
        }
      })

      // Add cursor
      // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
        var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
            snapToSeries: [ series.value ],
            snapToSeriesBy: "x"
        }));
        cursor.lineY.set("visible", false);


        // Set data
      series.value.data.setAll(parsedData);

      // Make stuff animate on load
      // https://www.amcharts.com/docs/v5/concepts/animations/
      series.value.appear(1000);
      chart.appear(1000, 100);
    }

    const updateChart = (series, data) => {
      const parsedData = data.map((record) => {
        const EndDate = dayjs(record.EndDate).unix() * 1000;
        return {
          "EndDate": EndDate,
          "Value": record.Value * 1
        }
      })
      series.value.data.setAll(parsedData);
    }

    const closeModal = () => {
      context.emit('closeModal')
    }

    return {
      series,
      chartData,
      chartRendered,
      fundhistorychart,
      closeModal,
      hasError,
      name: computed(() => store.getters.getCurrentISIN)
    }
  },
}
</script>
<style scoped>
.fund-history-modal-chart {
  position: relative;
  width: 100%;
  height: 400px;
}
</style>
