import dayjs from "dayjs";
import * as ExportData from './ValuationDataExtractor'
import store from "@/store";

export async function buildValuationIsinPayload(valuationDate){
    const formattedDateDMY = dayjs(valuationDate).format('DD/MM/YYYY')
    const todayDateDMY = dayjs().format('DD/MM/YYYY')

    const adviser = { name: store.getters.getAdviserName };

    const baseObject = {
        details: {},
        filters: {},
        summary: {},
        plans: {}
    }

    let detailsSet = await buildHeaderSection()
    let filtersSet = await buildFilterSection()
    let plansSet = await buildPlansSection()

    if (detailsSet && filtersSet && plansSet) {
        return baseObject
    }
    async function buildHeaderSection() {
        const primaryClient = await ExportData.getPrimaryClient()
        const adviserName = adviser.name

        return baseObject.details = {
            "sectionheader": [],
            "sectioncontent": [
                {
                    "A": "Client Name",
                    "C": primaryClient
                },
                {
                    "A": "Exporting Date",
                    "C": todayDateDMY
                },
                {
                    "A": "Adviser Name",
                    "C": adviserName
                }
            ],
            "sectionfooter": []
        }
    }

    async function buildFilterSection() {
        let portfolio = await ExportData.getSelectedPortfolio()
        let policy = await ExportData.getPolicies()

        return baseObject.filters = {
            "sectionheader": [],
            "sectioncontent": [
                {
                    "A": "Portfolio",
                    "C": portfolio
                },
                {
                    "A": "People",
                    "C": await ExportData.getSelectedPeople()
                },
                {
                    "A": "Policies",
                    "C": await policy
                },
                {
                    "A": "Valuations Date",
                    "C": formattedDateDMY
                }
            ],
            "sectionfooter": []
        }
    }

    async function buildPlansSection() {
        const plansObject = {
            sectionheader:[
                {
                    "A": "Holding Name",
                    "B": "ISIN Code",
                    "C": "Total Value"
                }
            ],
            sectioncontent:[],
            sectionfooter: []
        }

        const formattedDate = dayjs(valuationDate).format('YYYY-MM-DD')

        let plans = await ExportData.getISINDetails(formattedDate)

        plansObject.sectioncontent = plans;
        baseObject.plans = plansObject

        return plansObject
    }
}
