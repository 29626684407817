<template>
  <content-card-full>
    <template #title>
      <card-title>
        <template #title><h2 class="title">Valuation</h2></template>
        <template #content>
          <div class="flex align-items-center">
            <span class="mr-3">Filters:</span>
            <Button class="clarity-btn round grey clarity-gradient-grey" @click="toggleFilters"><span
                class="material-icons-round blue">filter_alt</span></Button>
          </div>
        </template>
        <template #filters>
          <FilterGroup :showOptions="['portfolios', 'people', 'policies', 'currency', 'valuation']" toggleKey="valuationfilter"/>
        </template>
      </card-title>
    </template>
    <template #content-full>
      <div class="flex flex-wrap justify-content-evenly filter-button-group clarity-gap">
        <div class="flex flex-grow-1 justify-content-evenly filter-button-group flex-wrap clarity-gap">
          <div class="button-width">
            <DownloadButton :items="items"/>
          </div>
          <div class="button-width">
            <Button class="clarity-btn clarity-gradient-grey mr-3 full-width" @click="$router.push('/investments/portfolios')" style="width: 100%;">Manage
              Portfolios
            </Button>
          </div>
        </div>
        <div class="flex flex-grow-1 justify-content-evenly filter-button-group flex-wrap clarity-gap">
          <div class="button-width">
            <Button class="clarity-btn clarity-gradient-grey mr-3 full-width" @click="$router.push('/investments/datafeeds')" style="width: 100%;">Update
              Feeds
            </Button>
          </div>
          <div class="button-width">
            <DownloadButton :items="isinItems" colourClass="clarity-gradient-grey" buttonText="Download ISIN Codes"/>
          </div>
        </div>
        <!--
         <Button class="clarity-btn clarity-gradient-grey" @click="console.log('DOWNLOAD ISIN CODES')"></Button>
         -->
      </div>
      <div class="flex flex-wrap clarity-margin-top-s">
        <div class="flex flex-column mr-5 chart-1" >
          <div class="flex justify-content-between align-items-center h-6rem">
            <h4>Valuation History Chart</h4>
            <!-- Date picker for range -->
            <div class="title-dropdown"><label>Period:</label>
              <period-select/>
            </div>
          </div>
          <div class="flex">
            <chart-card>
              <template #content-chart>
                <valuation-history-chart></valuation-history-chart>
              </template>
            </chart-card>
          </div>
        </div>
        <div class="flex flex-column chart-2" >
          <div class="flex justify-content-between align-items-center h-6rem">
            <h4>Valuation Summary Chart</h4>
          </div>
          <div class="flex">
            <chart-card>
              <template #content-chart>
                <div v-if="policySelected">
                  <policy-summary-chart :planuuid="policySelected.value"></policy-summary-chart>
                </div>
                <div v-else>
                  <valuation-summary-chart></valuation-summary-chart>
                </div>
              </template>
            </chart-card>
          </div>
        </div>
        <div class="flex flex-column chart-3">
            <div class="flex justify-content-between align-items-center h-6rem">
                <h4>Performance Overview</h4>
            </div>
            <div class="flex">
              <valuation-performance/>
            </div>
        </div>
      </div>
      <div class="mt-5">
        <valuation-policies/>
      </div>
    </template>
  </content-card-full>
</template>
<script>
import dayjs from "dayjs";
import {useStore} from 'vuex'
import {computed, ref} from 'vue'
import {buildValuationExcelPayload} from "@/core/services/documentExport/valuationExcelBuilder";
import {buildValuationPDFString} from '../../core/services/documentExport/valuationPdfBuilder'
import {buildValuationIsinPayload} from '../../core/services/documentExport/isinExcelBuilder'
//import {pdfStyles} from "../../core/services/documentExport/pdfCommon";
import CardTitle from '@/components/common/CardTitle'
import ChartCard from "../common/ChartCard.vue"
import ContentCardFull from '@/components/common/ContentCardFull'
import DownloadButton from '@/components/common/layout/interactive/DownloadButton.vue'
import FilterGroup from "@/components/common/filters/FilterGroup";
//import pdfGeneration from "@/core/services/documentExport/pdfGeneration";
import PolicySummaryChart from "../charts/PolicySummaryChart";
import ValuationPerformance from "../valuations/ValuationPerformance";
import ValuationPolicies from "../valuations/ValuationPolicies";
import ValuationHistoryChart from "../charts/ValuationHistoryChart";
import ValuationSummaryChart from "../charts/ValuationSummaryChart";
import PeriodSelect from "../common/filters/PeriodSelect";
import {downloadDocument} from '../../core/services/documentDownload'

export default {
  setup() {
    const store = useStore()

    const rawdate = computed(() => store.state.valuation.valuationDate)
    const portfolio = computed(() => store.getters.portfolioSelected)
    const policySelected = computed(() => store.getters.policySelected);
    const fullPortfolioSelected = computed(() => store.getters.fullPortfolioSelected)

    const getHumanReadablePortfolioName = () => {
      switch (portfolio.value) {
        case 'nonpens':
          return 'NONPENSION'
        case 'pens':
          return 'PENSION'
        case 'all':
          return 'ALLHOLDINGS'
        default:
          // assumption here is that ISA and IMS will remain the same
          return fullPortfolioSelected.value.name.replace(" ","");
      }
    }

    const downloadPDF = async () => {
      const currency = store.getters.currencySelected;
      const adviser = { name: store.getters.getAdviserName };
      const valuationPdf = await buildValuationPDFString(rawdate.value, currency, adviser)

      if (valuationPdf) {
        const fileName = `ValuationReport_${getHumanReadablePortfolioName(portfolio)}_${dayjs().format('DDMMYYYY')}.pdf`
        downloadDocument('pdf', valuationPdf, fileName, 'valuation_pdf')
      }
    }

    const downloadExcel = async () => {
      const valuationExcel = await buildValuationExcelPayload(rawdate.value)

      if (valuationExcel) {
        const fileName = `ValuationReport_${getHumanReadablePortfolioName()}_${dayjs().format('DDMMYYYY')}.xlsx`
        downloadDocument('excel', valuationExcel, fileName, 'ClientValuation')
      }
    }

    const items = ref([
      {
        key: '0_0',
        label: 'PDF',
        icon: 'pdf-icon',
        command: () => {
          downloadPDF()
          store.dispatch('setLoading', {id: 'valuation_pdf', state: true})
        }
      },
      {
        key: '0_1',
        label: 'Excel',
        icon: 'description',
        command: () => {
          downloadExcel()
          store.dispatch('setLoading', {id: 'ClientValuation', state: true})
        }
      }
    ])

    const downloadISIN = async () => {
      const valuationExcel = await buildValuationIsinPayload(rawdate.value)

      if (valuationExcel) {
        const fileName = `ISINReport_${getHumanReadablePortfolioName()}_${dayjs().format('DDMMYYYY')}.xlsx`
        downloadDocument('excel', valuationExcel, fileName, 'IsinValuation')
      }

    }

    const isinItems = ref([
      {
        key: '0_1',
        label: 'Excel',
        icon: 'description',
        command: () => {
          downloadISIN()
          store.dispatch('setLoading', {id: 'IsinValuation', state: true})
        }
      }
    ])

    const showFilters = computed(() => store.getters.getElementVisibility('valuationfilter'));

    const toggleFilters = () => {
        store.dispatch('updateElementVisibility', { "key": 'valuationfilter', "value": !showFilters.value})
    }

    return {
      toggleFilters,
      policySelected,
      items,
      isinItems
    }
  },
  components: {
    PeriodSelect,
    CardTitle,
    ChartCard,
    ContentCardFull,
    DownloadButton,
    FilterGroup,
    PolicySummaryChart,
    ValuationHistoryChart,
    ValuationPerformance,
    ValuationPolicies,
    ValuationSummaryChart
  }
}
</script>
<style scoped>
.title {
  color: var(--clarity-blue);
}

button {
  width: 25%;
  justify-content: center;
}
.chart-1 {
    width: 56%;
    @media only screen and (max-width: 1000px) {
        width: 100%;
    }
}
.chart-2 {
    width: 36%;
    @media only screen and (max-width: 1000px) {
        width: 48%;
    }
    @media only screen and (max-width: 800px) {
        width: 100%;
    }
}
.chart-3 {
    width: 100%;
    margin-top: 18px;
    @media only screen and (max-width: 1000px) {
        width: 48%;
        margin-left: 10px;
        margin-top: 0px;
    }
    @media only screen and (max-width: 800px) {
        width: 100%;
    }
}

.button-width {
    width: 48%;
}
@media only screen and (max-width: 768px) {
    .button-width {
        width: 100%;
    }
}

.clarity-gap {
    gap: 15px;
}

</style>
