<template>
  <div class="flex flex-column align-items-center full-width">
    <div class="flex flex-column align-items-center full-width">
      <div class="flex justify-content-end full-width">
        <h3 class="temp-title" :class="{ 'negative-text' : presentValue < 0 }" >{{ policyBasicInfo.name }}</h3>
        <span class="material-icons-round blue" v-tooltip.top="'View Info'"
              @click="scrollToListView(policyBasicInfo.planstring)">add</span>
      </div>
      <span class="small-text dark-grey" style="text-align: center">Owner: {{ getOwnerFromUuid(policyBasicInfo.ownership) }} </span>
    </div>
    <div v-if="loadingPresent" align="center">
        <Skeleton class="mb-3" height="4rem" width="20rem" borderRadius="16px"></Skeleton>
    </div>
    <div v-else>
      <div class="flex flex-wrap justify-content-center grid-margin" >
        <div>
          <pill v-if="policyIsDFM"  label="DFM Portfolio" icon="pi pi-star-fill" colour="clarity-gradient-yellow"/>
          <pill v-else-if="policyIsIMS"  label="IMS Portfolio" icon="pi pi-check"/>
        </div>
        <separator-v class="mx-3" style="border-right-color: var(--clarity-light-grey); height: 24px"/>
        <div v-if="policyBasicInfo.clarityType === 'clarity'" class="flex justify-content-center" style="min-width: 182px;">
          <img src="@/assets/logos/clarity-logo-small-blue.svg"> <span class="small-text ml-3" style="color: var(--clarity-grey)">{{
            getPolicyTypeLabel(policyBasicInfo)
            }}</span>
        </div>
        <div v-if="policyBasicInfo.clarityType === 'openbanking'" class="flex justify-content-center" style="min-width: 182px;">
          <span class="material-icons-round blue">account_balance</span> <span class="small-text ml-3" style="color: var(--clarity-grey)">{{
            getPolicyTypeLabel(policyBasicInfo)
            }}</span>
        </div>
        <div v-if="policyBasicInfo.clarityType === 'selfmanagedholding'" class="flex justify-content-center" style="min-width: 182px;">
          <span class="material-icons-round blue">person</span> <span class="small-text ml-3" style="color: var(--clarity-grey)">{{
            getPolicyTypeLabel(policyBasicInfo)
            }}</span>
        </div>
      </div>
      <h1 class="blue text-center mt-2">
        <currency-value :class="{ 'negative-text' : presentValue < 0 }" :value="presentValue" :decimal-places="0"/>
      </h1>
      <p class="small-text text-center grey">Value as at {{ presentDate.format('DD-MM-YYYY') }}</p>
    </div>
    <div v-if="loadingPast" align="center">
      <Skeleton class="mb-3" height="6rem" width="20rem" borderRadius="16px"></Skeleton>
    </div>
    <div v-else>
      <div class="grid-margin">
        <h2 class="blue text-center">
          <currency-value :class="{ 'negative-text' : pastValue < 0 }" :value="pastValue" :decimal-places="0"/>
        </h2>
        <p class="small-text text-center grey">Value as at {{ pastDate.format('DD-MM-YYYY') }}</p>
      </div>
    </div>
    <div v-if="loadingPast" align="center">
      <Skeleton class="mb-3" height="6rem" width="20rem" borderRadius="16px"></Skeleton>
    </div>
    <div v-if="policyBasicInfo.performance" class="full-width">
      <div class="flex squared-box clarity-snow-grey money-in">
        <span class="material-icons-round mx-4 green default-cursor chevron">expand_less</span>
        <div class="flex flex-wrap justify-content-center">
            <CurrencyValue :value="moneyIn" :decimal-places="2"/>
            <span class="pl-2">Money In</span>
        </div>
        <span class="material-icons-round mx-4 green default-cursor chevron">expand_less</span>
      </div>
      <div class="flex squared-box money-out">
        <span class="material-icons-round mx-4 red default-cursor chevron">expand_more</span>
          <div class="flex flex-wrap justify-content-center">
              <CurrencyValue :value="moneyOut" :decimal-places="2"/>
              <span class="pl-2">Money Out</span>
          </div>
        <span class="material-icons-round mx-4 red default-cursor chevron">expand_more</span>
      </div>
    </div>
    <div v-if="policyBasicInfo.performance" class="flex flex-column align-items-center">
      <div class="flex-1 text-center grid-margin">
        <h4 class="dark-grey text-center bold uncapitalize">{{ $case('Annualised Return', 'title') }}</h4>
        <div class="inline-flex align-items-center">
          <h2 class="blue text-center">
            <currency-value :value="annualisedReturn(presentValue, pastValue, moneyIn, moneyOut)" :decimal-places="0"/>
          </h2>
          <h6 v-if="typeof calcReturn.percent == 'number'" class="light-blue ml-3"
              :class="[isNegative(calcReturn.percent) ? 'negative-text' : 'positive-text']">
            {{ $nb(calcReturn.percent, 'percentage') }}</h6>
          <h6 v-else class="light-blue ml-3">(N/A)</h6>
        </div>
        <p class="small-text text-center grey">Total net return from {{ pastDate.format('DD-MM-YYYY') }} to
          {{ presentDate.format('DD-MM-YYYY') }}</p>
      </div>
      <div class="flex squared-box">
        <Button class="clarity-btn clarity-gradient-grey mr-4" @click="togglePerformanceTransactions"><span class="material-icons-outlined mr-4 grey performance-transaction-icon">info</span> Performance Transactions</Button>
        <!--            <Button class="clarity-btn round clarity-gradient-grey mx-2" v-tooltip.top="'View Documents'"><span class="material-icons-round grey">description</span></Button>-->
        <router-link to="/investments/permissions">
          <Button class="clarity-btn round clarity-gradient-grey mx-2" v-tooltip.top="'Give Permission'"><span class="material-icons-round dark-grey">group_add</span></Button>
        </router-link>
        <Button v-if="policyBasicInfo.clarityType === 'openbanking'" class="clarity-btn clarity-gradient-grey round" v-tooltip.top="'Revoke Access/Remove'" @click="toggleRevokeDialog"><span class="material-icons-round dark-grey">delete_forever</span></Button>
        <router-link to="/investments/transactions">
          <Button v-if="policyBasicInfo.clarityType === 'openbanking'" class="clarity-btn clarity-gradient-grey round" v-tooltip.top="'View Transactions'"><span class="material-icons-round dark-grey">sync_alt</span></Button>
        </router-link>
      </div>
    </div>

    <div v-if="!policyBasicInfo.performance && policyBasicInfo.clarityType !== 'openbanking'" class="flex clarity-card-inner">
      <div class="banner" v-if="policyBasicInfo.clarityType === 'selfmanagedholding'">
        <h3 class="m-2">No Performance?</h3>
        <Button class="clarity-btn clarity-gradient-orange" @click="$router.push('/securemessaging/message'); contactAdviser(policyBasicInfo.name);">Transfer to Clarity</Button>
      </div>
        <div class="banner" v-else>
            <div class="flex flex-wrap justify-content-center align-items-center">
                <h3 class="m-2" style="max-width: 60%">No Performance Available</h3>
                <img src="../../assets/img/Sticky-Woman.svg" style="max-width: 100px;">
            </div>
        </div>
    </div>
    <div v-if="!policyBasicInfo.performance" class="flex clarity-card-inner">
      <div class="button-group">
          <router-link to="/investments/selfmanaged">
            <Button v-if="policyBasicInfo.clarityType === 'selfmanagedholding'" class="clarity-btn clarity-gradient-grey round" v-tooltip.top="'Update Holding'"><span class="material-icons-round dark-grey">edit</span></Button>
            <Button v-if="policyBasicInfo.clarityType === 'selfmanagedholding'" class="clarity-btn clarity-gradient-grey round" v-tooltip.top="'Remove Holding'"><span class="material-icons-round dark-grey">delete_forever</span></Button>
          </router-link>
          <!--          <Button class="clarity-btn clarity-gradient-grey round" v-tooltip.top="'View Documents'"><span class="material-icons-round grey">description</span></Button>-->
          <router-link to="/investments/permissions" v-if="policyBasicInfo.clarityType !== 'openbanking'">
              <Button class="clarity-btn clarity-gradient-grey round" v-tooltip.top="'Give Permission'"><span class="material-icons-round dark-grey">group_add</span></Button>
          </router-link>
      </div>
    </div>
    <open-banking-actions v-if="policyBasicInfo.clarityType === 'openbanking'" :account="policyBasicInfo"></open-banking-actions>
  </div>
</template>
<script>

import {computed, ref, toRef, watch, inject, onMounted} from "vue";
import dayjs from "dayjs";
import {useStore} from "vuex";
import CurrencyValue from "@/components/common/CurrencyValue";
import Pill from '@/components/nav/Pill'
import SeparatorV from '@/components/common/layout/SeparatorV.vue'
import api from '@/core/services/api'
import {toDrupalRequest} from '@/core/helpers';
import OpenBankingActions from "@/components/open_banking/OpenBankingActions.vue";

export default {
  name: "PolicyGridItem",
  components: {OpenBankingActions, CurrencyValue, Pill, SeparatorV},
  props: {
    planuuid: String
  },
  setup(props) {

    const store = useStore()
    const bus = inject('bus')

    const uuid = toRef(props, 'planuuid')

    const presentValue = ref(0);
    const pastValue = ref(0);

    const presentDate = computed(() => {
      const date = store.state.valuation.valuationDate;
      return new dayjs(date)
    })

    const pastDate = computed(() => {
      const date = store.state.valuation.valuationDate;
      return new dayjs(date).subtract(1, "year")
    })

    const loadingPresent = ref(true);
    const loadingPast = ref(true);

    const getPastLoadingState = computed(() => store.getters.pastValue(uuid.value))
    const getPresentLoadingState = computed(() => store.getters.presentValue(uuid.value))

    // need watchers and if statements to allow either view to load first and update the dom
    // check not null to allow for 0 value if wasn't started or 0 value/funds
    if (getPastLoadingState.value !== null) {
      loadingPast.value = false
      pastValue.value = getPastLoadingState.value
    }

    if (getPresentLoadingState.value !== null) {
      loadingPresent.value = false
      presentValue.value = getPresentLoadingState.value
    }

    watch(getPastLoadingState, (value) => {
      if (value !== null) {
        loadingPast.value = false
        pastValue.value = value
      }
    })

    watch(getPresentLoadingState, (value) => {
      if (value !== null) {
        loadingPresent.value = false
        presentValue.value = value
      }
    })

    const getOwnerFromUuid = (owner) => {
      let rtn = []
      owner.forEach(person => {
        const name = store.getters.personFromUuid(person)
        name ? rtn.push(name.name) : false
      })

      return rtn.join(", ")
    }

    const togglePerformanceTransactions = () => {
      // add action to set selected plan
      store.dispatch('setPerformancePolicySelected', uuid)
      store.dispatch('setActiveModal', {modal: 'PerformanceTransactions', origin: 'valuation'})
    }

    const annualisedReturn = (present, past, i, out) => {
      return present - (past +(i-out));
    }

    const calcReturn = computed(() => {
      if (pastValue.value !== 0) {
        return store.getters.getGoalSeek(uuid.value)
      }
      return 'N/A'
    })

    const isNegative = (value) => {
      return value < 0
    }

    const scrollToListView = (payload) => {
      bus.emit('scrollToListView', payload)
    }

    const getPolicyTypeLabel = (policyInfo) => {
      switch (policyInfo.clarityType) {
        case 'clarity':
          return 'investment'
        case 'selfmanagedholding':
          return 'Self Managed Holding'
        case 'openbanking':
          return 'Open Banking'
      }
    }

    const showRevokeDialog = ref(false)

    const toggleRevokeDialog = () => {
      showRevokeDialog.value = !showRevokeDialog.value
    }

      const loadPerformanceTransactions = (policy) => {
          store.dispatch('getPerformanceTransactionsForPolicy', {value: policy,
              from: pastDate.value.format('YYYY-MM-DD'),
              to: presentDate.value.format('YYYY-MM-DD')
          })
      }

      const loadValues = (uuid) => {
          loadPresentValues(uuid);
          loadPastValues(uuid);
          loadPerformanceTransactions(uuid)
      }

      onMounted(() => {
          loadValues(uuid.value)
      })

      const loadPresentValues = async (uuid) => {
          const presentResponse = await api.get(`${toDrupalRequest('valuation/plan/summary/'+uuid+'/'+presentDate.value.format('YYYY-MM-DD'))}`)
          if (presentResponse) {
              store.dispatch('setPerformanceTransactionTotal', {policy: uuid, value: presentResponse.total, point: 'present'})
          }
      }

      const loadPastValues = async (uuid) => {
          const pastResponse = await api.get(`${toDrupalRequest('valuation/plan/summary/'+uuid+'/'+pastDate.value.format('YYYY-MM-DD'))}`)
          if (pastResponse) {
              store.dispatch('setPerformanceTransactionTotal', {policy: uuid, value: pastResponse.total, point: 'past'})
          }
      }

      const contactAdviser = (plan) => {
          store.dispatch('globalMessageTrigger',  {
              subject: 'Transfer to clarity - ' + plan,
              category: 'Investments',
              recipient: store.getters.getAdviserName,
              messagebody: 'I would like to discuss transferring my '+plan+' to clarity.'
          })
      }

      watch(presentDate, () => {
          loadValues(uuid.value)
      })


      return {
      policyBasicInfo: computed(() => store.getters.policyBasicInfo(uuid.value)),
      policyIsIMS: computed(() => store.getters.policyIsIMS(uuid.value)),
      policyIsDFM: computed(() => store.getters.policyIsDFM(uuid.value)),
      uuid,
      presentDate,
      pastDate,
      presentValue,
      pastValue,
      loadingPresent,
      loadingPast,
      getOwnerFromUuid,
      getPolicyTypeLabel,
      togglePerformanceTransactions,
      moneyIn: computed(() => store.getters.performanceTotal(uuid.value, 'in')),
      moneyOut: computed(() => store.getters.performanceTotal(uuid.value, 'out')),
      calcReturn,
      isNegative,
      annualisedReturn,
      scrollToListView,
      toggleRevokeDialog,
      showRevokeDialog,
      contactAdviser
    }
  }
}
</script>
<style scoped lang="scss">
/* @ToDo: needs reworking after policy naming finalised */
.temp-title {
  display: flex;
  text-align: center;
  color: var(--clarity-blue);
  width: 100%;
  justify-content: center;
  align-items: center;
  overflow-wrap: break-word;
  inline-size: 100%;
  height: 60px;
}

.banner {
  flex-direction: column;
  justify-content: center;
  height: 250px;
  padding: 30px;
  background-image: url('../../assets/img/banner-bg-grid-item.svg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.alternate-banner {
  flex-direction: column;
  justify-content: center;
  height: 250px;
  padding: 30px;
  background-image: url('../../assets/img/No-Performance-Image.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.button-group {
  display: flex;
  margin: 40px auto 0px auto;
}

.button-group button {
  margin: 0px 10px;
}

.material-icons-round {
  cursor: pointer;
}

.positive-text {
  color: var(--clarity-green);
}

.positive-text::before {
  content: '+';
}

.negative-text {
  color: var(--clarity-red);
}

.money-out span:hover {
  color: var(--clarity-red);
  cursor: default;
}
.money-in span:hover {
  color: var(--clarity-green);
  cursor: default;
}

.default-cursor {
  cursor: default;
}

@media only screen and (max-width: 1366px)  {
  .chevron {
    display: none;
  }

  .performance-transaction-icon {
    display: none;
  }

  .banner {
    height: 280px;
  }

  .button-group button {
    margin: 0px 3px;
  }
}

.grid-margin {
  margin: 30px 0px;
}

@media only screen and (max-width: 768px)  {
  .grid-margin {
    margin: 8px 0px;
  }
  h1 {
    font-size: var(--clarity-font-size-30);
    line-height: var(--clarity-line-spacing-40);
    font-weight: 400;
  }
  h2 {
    line-height: var(--clarity-line-spacing-24);
  }
}

</style>
