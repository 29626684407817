<template>
  <content-card-full>
    <template #title>
      <card-title>
        <template #title><h2 class="title">Asset Allocation</h2></template>
        <template #content>
          <div class="flex align-items-center">
            <span class="mr-3">Filters:</span>
            <Button class="clarity-btn round grey clarity-gradient-grey" @click="toggleFilters"><span
                class="material-icons-round blue">filter_alt</span></Button>
          </div>
        </template>
        <template #filters>
          <FilterGroup :showOptions="['portfolios', 'people', 'currency', 'valuation']" toggleKey="allocationfilter"/>
        </template>
      </card-title>
    </template>
    <template #content-full>
      <div class="blurb mb-5">
          Welcome to the Asset Allocation section. This page combines the data from your Automated Data Feeds as well as any Self Managed Holdings you have added to your account.
          If you want to know more about the terminologies used in this section, please consult the  <a href="https://website-public-assets.clarityglobal.com/saleswebsite/pdf/clarityInvestmentGlossary.pdf" class="clarity-link" target="_blank">Investment Glossary</a>.
      </div>
      <div class="flex flex-wrap justify-content-evenly filter-button-group">
          <div class="flex flex-grow-1 justify-content-evenly filter-button-group mb-3">
              <Button class="clarity-btn clarity-gradient-orange" @click="openFullReport" style="width: 50%;">
                  <span class="material-icons-round white mr-5">visibility</span>
                  <span>View Full Report</span>
              </Button>
              <DownloadButton :items="downloadOptions" id="asset-allocation" style="width: 50%; margin-right: 14px;" />
          </div>
          <div class="flex flex-grow-1 justify-content-evenly filter-button-group mb-3">
            <DownloadButton :items="isinItems" colourClass="clarity-gradient-grey" buttonText="Download ISIN Codes" style="width: 50%; margin-right: 14px;" />
            <Button class="clarity-btn clarity-gradient-grey" @click="openBenchmarkModal" style="width: 50%;"><span
              class="material-icons-round grey mr-5">sync</span><span>Update Benchmark</span></Button>
          </div>
      </div>
      <div>
        <div>
          <h4 class="mb-2">Asset Allocation Full</h4>
          <div v-if="loadingAssSummary && !assetSummary" align="center">
            <Skeleton class="mb-2" width="100%" height="2rem" borderRadius="27px"></Skeleton>
            <Skeleton class="mb-2" width="100%" height="2rem" borderRadius="27px"></Skeleton>
            <Skeleton class="mb-2" width="100%" height="2rem" borderRadius="27px"></Skeleton>
          </div>
          <DataTable :value="assetSummary" v-if="assetSummary" class="rd-table">
            <Column header="Allocation" field="allocation" class="label-col">
              <template #body="slotProps">
                {{ $case(slotProps.data.allocation, 'title') }}
              </template>
            </Column>
            <Column header="Cash" field="cash">
              <template #body="slotProps">
                <div :class="negativeVarianceClass(slotProps.data.cash, slotProps.index)">
                  {{ $nb(slotProps.data.cash, 'percentage') }}
                </div>
              </template>
            </Column>
            <Column header="Fixed Interest" field="fixed" class="label-col">
              <template #body="slotProps">
                <div :class="negativeVarianceClass(parseFloat(slotProps.data.fixed, slotProps.index))">
                  {{ $nb(slotProps.data.fixed, 'percentage') }}
                </div>
              </template>
            </Column>
            <Column header="Equity" field="equity" class="label-col">
              <template #body="slotProps">
                <div :class="negativeVarianceClass(slotProps.data.equity, slotProps.index)">
                  {{ $nb(slotProps.data.equity, 'percentage') }}
                </div>
              </template>
            </Column>
            <Column header="Other" field="property" class="label-col">
              <template #body="slotProps">
                <div :class="negativeVarianceClass(slotProps.data.property, slotProps.index)">
                  {{ $nb(slotProps.data.property, 'percentage') }}
                </div>
              </template>
            </Column>
          </DataTable>
        </div>
        <spacer-h/>
        <div>
          <h4 class="mb-2">Equity Regional Allocation</h4>
          <div v-if="loadingEQBreakdown && !equityBreakdown" align="center">
            <Skeleton class="mb-2" width="100%" height="2rem" borderRadius="27px"></Skeleton>
            <Skeleton class="mb-2" width="100%" height="2rem" borderRadius="27px"></Skeleton>
            <Skeleton class="mb-2" width="100%" height="2rem" borderRadius="27px"></Skeleton>
          </div>
          <DataTable :value="equityBreakdown" v-if="equityBreakdown" class="rd-table">
            <Column header="Allocation" field="allocation" class="label-col">
              <template #body="slotProps">
                {{ $case(slotProps.data.allocation, 'title') }}
              </template>
            </Column>
            <Column header="UK" field="uk_equity" class="label-col-eq">
              <template #body="slotProps">
                <div :class="negativeVarianceClass(slotProps.data.uk_equity, slotProps.index)">
                  {{ $nb(slotProps.data.uk_equity, 'percentage') }}
                </div>
              </template>
            </Column>
            <Column header="US" field="us_equity" class="label-col-eq">
              <template #body="slotProps">
                <div :class="negativeVarianceClass(slotProps.data.us_equity, slotProps.index)">
                  {{ $nb(slotProps.data.us_equity, 'percentage') }}
                </div>
              </template>
            </Column>
            <Column header="European" field="european_equity" class="label-col-eq">
              <template #body="slotProps">
                <div :class="negativeVarianceClass(slotProps.data.european_equity, slotProps.index)">
                  {{ $nb(slotProps.data.european_equity, 'percentage') }}
                </div>
              </template>
            </Column>
            <Column header="Asia Pacific" field="fareast_equity" class="label-col-eq">
              <template #body="slotProps">
                <div :class="negativeVarianceClass(slotProps.data.fareast_equity, slotProps.index)">
                  {{ $nb(slotProps.data.fareast_equity, 'percentage') }}
                </div>
              </template>
            </Column>
            <Column header="Japan" field="japan_equity" class="label-col-eq">
              <template #body="slotProps">
                <div :class="negativeVarianceClass(slotProps.data.japan_equity, slotProps.index)">
                  {{ $nb(slotProps.data.japan_equity, 'percentage') }}
                </div>
              </template>
            </Column>
            <Column header="Emerging Markets" field="other_equity" class="label-col-eq">
              <template #body="slotProps">
                <div :class="negativeVarianceClass(slotProps.data.other_equity, slotProps.index)">
                  {{ $nb(slotProps.data.other_equity, 'percentage') }}
                </div>
              </template>
            </Column>
            <Column header="Specialist" field="specialist_equity" class="label-col-eq">
              <template #body="slotProps">
                <div :class="negativeVarianceClass(slotProps.data.specialist_equity, slotProps.index)">
                  {{ $nb(slotProps.data.specialist_equity, 'percentage') }}
                </div>
              </template>
            </Column>
          </DataTable>
        </div>
        <spacer-h/>
        <div class="grid">
          <div :class="allocationClass">
            <div class="flex justify-content-between align-items-center h-3rem">
              <h4>Asset Allocation</h4>
            </div>
            <div class="flex">
              <chart-card>
                <template #content-chart>
                  <div v-if="loadingAssSummary && !assetSummary" align="center">
                    <Skeleton class="mb-2" width="100%" height="450px" borderRadius="27px"></Skeleton>
                  </div>
                  <div v-else>
                    <div v-if="benchmarkPresent">
                      <AllocationVarianceChart :chart-data="assetSummary" :keys="assetKeys" :bar-width="40"/>
                    </div>
                    <div v-else>
                      <AllocationSummaryChart :chart-data="assetSummary" :keys="assetKeys"
                                              chart-title="ASSET ALLOCATION"/>
                    </div>
                  </div>
                </template>
              </chart-card>
            </div>
          </div>
          <div :class="regionalClass">
            <div class="flex justify-content-between align-items-center h-3rem">
              <h4>Equity Regional Allocation</h4>
            </div>
            <div class="flex">
              <chart-card>
                <template #content-chart>
                  <div v-if="loadingEQBreakdown && !equityBreakdown" align="center">
                    <Skeleton class="mb-2" width="100%" height="450px" borderRadius="27px"></Skeleton>
                  </div>
                  <div v-else>
                    <div v-if="benchmarkPresent">
                      <AllocationVarianceChart :chart-data="equityBreakdown" :keys="equityKeys" :bar-width="40"/>
                    </div>
                    <div v-else>
                      <AllocationSummaryChart :chart-data="equityBreakdown" :keys="equityKeys"
                                              chart-title="EQUITY REGIONAL ALLOCATION"/>
                    </div>
                  </div>
                </template>
              </chart-card>
            </div>
          </div>
        </div>
      </div>
    </template>
  </content-card-full>
  <full-allocation-modal v-show="showFullReport == 'FullAllocation'"/>
  <benchmark-modal v-show="showBenchmarkModal" @closeBenchmarkModal="openBenchmarkModal" />
</template>
<script>
import dayjs from "dayjs";
import {onMounted} from "vue";
import {useStore} from 'vuex'
import {computed, ref, watch} from 'vue'
import {buildAllocationExcelPayload} from "../../core/services/documentExport/allocationExcelBuilder";
import AllocationSummaryChart from "../charts/AssetAllocationSummaryChart";
import AllocationVarianceChart from "../charts/AssetAllocationVarianceBar";
import BenchmarkModal from "@/components/modals/BenchmarkModal";
import ContentCardFull from '@/components/common/ContentCardFull'
import CardTitle from '@/components/common/CardTitle'
import ChartCard from "../common/ChartCard";
import DownloadButton from '@/components/common/layout/interactive/DownloadButton.vue'
import FilterGroup from "@/components/common/filters/FilterGroup";
import FullAllocationModal from '@/components/modals/FullAllocationModal'
import SpacerH from '@/components/common/layout/SpacerH'
import {buildValuationIsinPayload} from "../../core/services/documentExport/isinExcelBuilder";
import {downloadDocument} from "../../core/services/documentDownload";


export default {
  components: {
    AllocationSummaryChart,
    AllocationVarianceChart,
    BenchmarkModal,
    CardTitle,
    ChartCard,
    ContentCardFull,
    DownloadButton,
    FilterGroup,
    FullAllocationModal,
    SpacerH
  },
  setup() {
    const store = useStore()
    const loadingEQBreakdown = ref(false)
    const loadingAssSummary = ref(false)
    const showFullReport = computed(() => store.getters.currentActiveModal)
    const showBenchmarkModal = ref()
    const callFilters = computed(() => store.getters.valuationDateCallFilters)

    const rawdate = computed(() => store.state.valuation.valuationDate)
    const portfolio = computed(() => store.getters.portfolioSelected)
    const fullPortfolioSelected = computed(() => store.getters.fullPortfolioSelected)

    const getHumanReadablePortfolioName = () => {
      switch (portfolio.value) {
        case 'nonpens':
          return 'NONPENSION'
        case 'pens':
          return 'PENSION'
        case 'all':
          return 'ALLHOLDINGS'
        default:
          // assumption here is that ISA and IMS will remain the same
          return fullPortfolioSelected.value.name.replace(" ","");
      }
    }

    onMounted(() => {
      getAssetAllocationData(callFilters.value)
      store.dispatch('getPolicyOptions')
      loadingAssSummary.value = true
      loadingEQBreakdown.value = true
    })

    const showFilters = computed(() => store.getters.getElementVisibility('allocationfilter'));

    const toggleFilters = () => {
        store.dispatch('updateElementVisibility', { "key": 'allocationfilter', "value": !showFilters.value})
    }

    const getAssetAllocationData = (filter) => {
      store.dispatch('getAssetAllocationData', filter)
    }

    watch(callFilters, (value) => {
      getAssetAllocationData(value)
    })

    const openFullReport = () => {
      store.dispatch('setActiveModal', {modal: 'FullAllocation'})
    }

    const closeFullReport = () => {
      store.dispatch('setActiveModal')
    }

    const negativeVarianceClass = (data, index) => {
        if(index === 2) {
            return data > 0.00 ? 'negative' : 'positive-class';
        }else{
            return 'positive-class'
        }
    }

    const assetSummary = computed(() => store.getters.allocationSummary);
    const equityBreakdown = computed(() => store.getters.equityBreakdown);

    const assetKeys = [
      {label: 'Cash', value: 'cash'},
      {label: 'Equity', value: 'equity'},
      {label: 'Fixed Interest', value: 'fixed'},
      {label: 'Other', value: 'property'}
    ];

    const equityKeys = [
      {label: 'UK', value: 'uk_equity'},
      {label: 'US', value: 'us_equity'},
      {label: 'European', value: 'european_equity'},
      {label: 'Asia Pacific', value: 'fareast_equity'},
      {label: 'Japan', value: 'japan_equity'},
      {label: 'Emerging Mkts', value: 'other_equity'},
      {label: 'Specialist', value: 'specialist_equity'}
    ];

    const benchmarkPresent = computed(() => {
      return (assetSummary.value && assetSummary.value.length > 1);
    })

    const allocationClass = computed(() => (benchmarkPresent.value ? 'col-12 xl:col-5' : 'col-12 xl:col-6'));
    const regionalClass = computed(() => (benchmarkPresent.value ? 'col-12 xl:col-7' : 'col-12 xl:col-6'));

    const openBenchmarkModal = () => {
      showBenchmarkModal.value = !showBenchmarkModal.value
    }

    const downloadExcel = async  (mode) => {
      const allocationExcel = await buildAllocationExcelPayload(rawdate.value, mode)
      //console.log('allocation payload is ', allocationExcel);
      if (allocationExcel) {
        const fileName = `${ dayjs().format('YYYYMMDD')}_AssetAllocation_${mode}_${getHumanReadablePortfolioName()}.xlsx`
        downloadDocument('excel', allocationExcel, fileName, 'AssetAllocation'+mode)
      }
    }

    const downloadOptions = ref([
      {
        key: '0_0',
        label: 'Excel - Condensed',
        icon: 'description',
        command: () => {
          downloadExcel('Condensed')
          store.dispatch('setLoading', {id: 'AssetAllocationCondensed', state: true})
        }
      },
      {
        key: '0_1',
        label: 'Excel - Full',
        icon: 'description',
        command: () => {
          downloadExcel('Full')
          store.dispatch('setLoading', {id: 'AssetAllocationFull', state: true})
        }
      }
    ])

    const downloadISIN = async  () => {

      const valuationExcel = await buildValuationIsinPayload(rawdate.value)

      if (valuationExcel) {
        const fileName = `ISINReport_${getHumanReadablePortfolioName()}_${ dayjs().format('DDMMYYYY')}.xlsx`
        downloadDocument('excel', valuationExcel, fileName, 'IsinValuation')
      }

    }

    const isinItems = ref([
      {
        key: '0_1',
        label: 'Excel',
        icon: 'description',
        command: () => {
          downloadISIN()
          store.dispatch('setLoading', {id: 'IsinValuation', state: true})
        }
      }
    ])

    return {
      assetSummary,
      equityBreakdown,
      callFilters,
      loadingEQBreakdown,
      loadingAssSummary,
      showFullReport,
      openFullReport,
      closeFullReport,
      negativeVarianceClass,
      benchmarkPresent,
      assetKeys,
      equityKeys,
      toggleFilters,
      allocationClass,
      regionalClass,
      openBenchmarkModal,
      showBenchmarkModal,
      downloadOptions,
      isinItems,
      getHumanReadablePortfolioName
    }
  },
}
</script>
<style scoped>
.title {
  color: var(--clarity-blue);
}

.bottom-actions {
  display: flex;
  justify-content: space-between;
}

.bottom-actions button {
  padding: 0px 45px;
  margin-right: 20px;
  width: 25%;
  justify-content: center;
}

.rd-table :deep(.label-col) {
  width: calc(100% / 5);
}

.rd-table :deep(.label-col-eq) {
  width: calc((100% - (100% / 5)) / 7);
}

/* Pesudo footer */
.rd-table:deep(.p-datatable-table tbody tr:nth-of-type(3)) {
  color: var(--clarity-pure-white);
  font-weight: bold;
  background: transparent linear-gradient(180deg, #7CC2E2 0%, var(--clarity-light-blue) 100%) 0% 0% no-repeat padding-box;
}

.filter-button-group button {
    justify-content: center;
    width: 25%;
    margin-right: 14px;
}

.blurb {
    font-size: 16px;
}

@media only screen and (max-width: 960px) {

    :deep(tr td:not(:first-child) .p-column-title){
        color: var(--clarity-blue)
    }

    :deep(tr td.label-col:first-of-type) {
        margin-top: 16px;
        border-top-left-radius: 27px;
        border-top-right-radius: 27px;
        border-bottom-left-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
        background: transparent linear-gradient(180deg, var(--clarity-light-blue) 0%, var(--clarity-blue) 100%) 0% 0% no-repeat padding-box;
        color: var(--clarity-pure-white);
        font-size: 14px;
        font-weight: normal;
        padding: 20px 15px;
    }

    :deep(.rd-table .p-datatable-table .p-datatable-tbody tr:last-child td:first-child) {
        border-bottom-left-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
    }

    :deep(tr){
        background-color: transparent !important;
        background: transparent !important;
    }

    :deep(tr td.label-col:nth-of-type(odd)){
        background-color: var(--clarity-snow-grey);
    }
    :deep(tr td.label-col:nth-of-type(even)){
        background-color: var(--clarity-pure-white);
    }
    :deep(td.label-col-eq:nth-of-type(odd)){
        background-color: var(--clarity-pure-white);
    }
    :deep(td.label-col-eq:nth-of-type(even)){
        background-color: var(--clarity-snow-grey);
    }

    :deep(td.label-col-eq:last-child) {
        border-bottom-left-radius: 27px !important;
        border-bottom-right-radius: 27px !important;
    }
    :deep(td.label-col:last-child) {
        border-bottom-left-radius: 27px !important;
        border-bottom-right-radius: 27px !important;
    }


    :deep(.negative){
        width: 200px;
        text-align: right;
    }

    :deep(.p-datatable-table tbody tr:nth-of-type(3) .positive-class){
        width: 200px;
        color: var(--clarity-pure-white);
        font-weight: bold;
        background: transparent linear-gradient(180deg, #7CC2E2 0%, var(--clarity-light-blue) 100%) 0% 0% no-repeat padding-box;
        margin: -20px -15px;
        padding: 20px 15px;
        text-align: right;
    }
}
</style>
