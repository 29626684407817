<template>
  <Modal :width="`90%`">
    <template #body>
      <content-card-full>
        <template #title>
          <div class="flex justify-content-between title align-items-center">
            <card-title>
              <template #title>
                <div class="flex" style="padding-top: 15px">
                  <h3 class="blue">Customise Benchmark</h3>
                </div>
              </template>
            </card-title>
            <Button class="clarity-close-modal-btn" @click="closeModal">
              <span class="material-icons-round grey">close</span>
            </Button>
          </div>
        </template>
        <template #content-full>
          <ScrollPanel style="width: 100%; height: 50vh" class="pr-3">
            <div style="margin-right: 5%" class="blurb">
                Please use the inputs below to set your Portfolio Benchmark. If you are on a clarity service we will suggest a ‘best fit’ target Asset Allocation based on your Risk Score,
                which can be used as a Benchmark for your investments. If you would like to learn more about Asset Allocation Benchmarks please see our <a
                    :href="clarityURL+'/sites/default/files/research/notes/asset_allocation_risk_return.pdf'"
                    target="_blank" class="clarity-link">Research
                Note</a> on Asset Allocation. If you want to know more about the terminologies used in this section, please consult the <a href="https://website-public-assets.clarityglobal.com/saleswebsite/pdf/clarityInvestmentGlossary.pdf" class="clarity-link" target="_blank">Investment Glossary</a>.
            </div>
            <div class="note">
                      <span class="small-text"><b>Please Note:</b> The information stored on this website is intended as a starting point
                          and an aid for discussions with your clarity adviser, who will also take into account your particular circumstances
                          and preferences before giving any formal advice.</span>
                <br /><br />
            </div>
            <div class="allocation-group">
              <div class="flex justify-content-between title align-items-center">
                <card-title class="with-totaliser">
                  <template #title>
                    <h3>Asset Allocation Values</h3>
                    <span class="material-icons-round grey"
                          v-tooltip.top="'Asset Allocation values (Fixed Interest, Equity and Other) must total 100%'">info</span>
                  </template>
                  <template #content>
                      <span class="small-text">
                          Total target value:
                          <span :class="[totalTally('asset') ? 'tally' : 'no-tally']">{{ assetTotal }}% / <b>100%</b></span>
                      </span>
                  </template>
                </card-title>
              </div>
              <div class="filter-group">
                <div class="full-width filter-set">
                  <label>Fixed Interest:</label>
                  <div class="p-inputgroup">
                    <InputText :disabled="isIMS" name="assetFixedInt" v-model="assetAllocation.fixedInt"
                               placeholder="Please specify..."
                               @blur="v$.assetAllocation.fixedInt.$touch"/>
                    <span class="p-inputgroup-addon">%</span>
                  </div>
                  <div v-if="v$.assetAllocation.fixedInt.$error">Please ensure these values total 100%
                  </div>
                </div>
                <div class="full-width filter-set">
                  <label>Equity:</label>
                  <div class="p-inputgroup">
                    <InputText :disabled="isIMS" v-model="assetAllocation.equity" placeholder="Please specify..."
                               @blur="v$.assetAllocation.equity.$touch"/>
                    <span class="p-inputgroup-addon">%</span>
                  </div>
                  <div v-if="v$.assetAllocation.equity.$error">Please ensure these values total 100%</div>
                </div>
                <div class="full-width filter-set">
                  <label>Other:</label>
                  <div class="p-inputgroup">
                    <InputText :disabled="isIMS" v-model="assetAllocation.property" placeholder="Please specify..."
                               @blur="v$.assetAllocation.property.$touch"/>
                    <span class="p-inputgroup-addon">%</span>
                  </div>
                  <div v-if="v$.assetAllocation.property.$error">Please ensure these values total 100%
                  </div>
                </div>
              </div>
            </div>
            <div class="allocation-group">
            <div class="flex justify-content-between title align-items-center">
              <card-title class="with-totaliser">
                <template #title><h3>Equity Allocation Values</h3>
                  <span class="material-icons-round grey"
                        v-tooltip.top="'Equity allocation values must total 100%'">info</span>
                </template>
                <template #content>
                  <span class="small-text">Total target value: <span
                      :class="[totalTally('equity') ? 'tally' : 'no-tally']">{{
                      equityTotal
                    }}% / <b>100%</b></span></span>
                </template>
              </card-title>
            </div>
            <div class="filter-group long">
              <div class="flex filter-top-row">
                <div class="full-width filter-set">
                  <label>UK:</label>
                  <div class="p-inputgroup">
                    <InputText :disabled="isIMS" v-model="equityAllocation.uk" placeholder="Please specify..."
                               @blur="v$.equityAllocation.uk.$touch"/>
                    <span class="p-inputgroup-addon">%</span>
                  </div>
                  <div v-if="v$.equityAllocation.uk.$error">Please ensure these values total 100%
                  </div>
                </div>
                <div class="full-width filter-set">
                  <label>US:</label>
                  <div class="p-inputgroup">
                    <InputText :disabled="isIMS" v-model="equityAllocation.us" placeholder="Please specify..."
                               @blur="v$.equityAllocation.us.$touch"/>
                    <span class="p-inputgroup-addon">%</span>
                  </div>
                  <div v-if="v$.equityAllocation.us.$error">Please ensure these values total 100%
                  </div>
                </div>
                <div class="full-width filter-set">
                  <label>European:</label>
                  <div class="p-inputgroup">
                    <InputText :disabled="isIMS" v-model="equityAllocation.euro" placeholder="Please specify..."
                               @blur="v$.equityAllocation.euro.$touch"/>
                    <span class="p-inputgroup-addon">%</span>
                  </div>
                  <div v-if="v$.equityAllocation.euro.$error">Please ensure these values total 100%
                  </div>
                </div>
                <div class="full-width filter-set">
                  <label>Asia Pacific:</label>
                  <div class="p-inputgroup">
                    <InputText :disabled="isIMS" v-model="equityAllocation.asia" placeholder="Please specify..."
                               @blur="v$.equityAllocation.asia.$touch"/>
                    <span class="p-inputgroup-addon">%</span>
                  </div>
                  <div v-if="v$.equityAllocation.asia.$error">Please ensure these values total 100%
                  </div>
                </div>
              </div>
              <div class="flex filter-second-row">
                <div class="full-width filter-set">
                  <label>Japan:</label>
                  <div class="p-inputgroup">
                    <InputText :disabled="isIMS" v-model="equityAllocation.japan" placeholder="Please specify..."
                               @blur="v$.equityAllocation.japan.$touch"/>
                    <span class="p-inputgroup-addon">%</span>
                  </div>
                  <div v-if="v$.equityAllocation.japan.$error">Please ensure these values total 100%
                  </div>
                </div>
                <div class="full-width filter-set">
                  <label>Emerging Markets:</label>
                  <div class="p-inputgroup">
                    <InputText :disabled="isIMS" v-model="equityAllocation.emerging" placeholder="Please specify..."
                               @blur="v$.equityAllocation.emerging.$touch"/>
                    <span class="p-inputgroup-addon">%</span>
                  </div>
                  <div v-if="v$.equityAllocation.emerging.$error">Please ensure these values total 100%</div>
                </div>
                <div class="full-width filter-set">
                  <label>Specialist:</label>
                  <div class="p-inputgroup">
                    <InputText
                        :disabled="isIMS"
                        v-model="equityAllocation.special" placeholder="Please specify..."
                        @blur="v$.equityAllocation.special.$touch"/>
                    <span class="p-inputgroup-addon">%</span>
                  </div>
                  <div v-if="v$.equityAllocation.special.$error">Please ensure these values total 100%</div>
                </div>
              </div>
            </div>
          </div>
          </ScrollPanel>
          <div class="flex justify-content-between my-3 btn-group" style="width: 100%">
            <Button class="clarity-btn clarity-gradient-grey" @click="closeModal">Dismiss</Button>
            <div>
              <Button class="clarity-btn clarity-gradient-grey mr-6" @click="setToPortfolio"
                      :disabled="!checkAssetAndEquityProps" v-if="!isIMS">Set Benchmark to Portfolio Values
              </Button>
              <Button class="clarity-btn clarity-gradient-grey mr-6 dark-grey" @click="clearValues"
                      v-if="(formIsDirty.asset || formIsDirty.equity) && !isIMS">Clear Values<span
                  class="material-icons-round dark-grey ml-3">close</span></Button>
              <Button class="clarity-btn clarity-gradient-orange" @click="submitForm"
                      :disabled="!checkBenchmarkTally" v-if="!isIMS">
                Set Benchmark
              </Button>
              <Button class="clarity-btn clarity-gradient-orange" @click="toggleContactForm" v-if="isIMS">
                Contact Us
              </Button>
            </div>
          </div>
        </template>
      </content-card-full>
    </template>
  </Modal>
</template>

<script>
import {reactive, computed, watch, onMounted, ref} from 'vue';
import Modal from "@/components/common/Modal";
import ContentCardFull from '@/components/common/ContentCardFull'
import CardTitle from '@/components/common/CardTitle'
import useVuelidate from '@vuelidate/core'
import {required, decimal} from '@vuelidate/validators'
import {useStore} from "vuex";

export default {
  name: "BenchmarkDialog",
  components: {
    Modal,
    ContentCardFull,
    CardTitle
  },
  emits: ['closeBenchmarkModal'],
  setup(props, context) {
    const clarityURL = process.env.VUE_APP_CLARITYURL;

    const store = useStore()
    const currentPortfolio = computed(() => store.getters.fullPortfolioSelected)
    const date = computed(() => store.getters.valuationDate)
    const isIMS = ref()

    onMounted(() => {
      if (currentPortfolio.value.benchmark) {
        setToBenchmark(currentPortfolio.value.benchmark)
      }
    })

    watch(currentPortfolio, (portfolio) => {
      if(portfolio) {
        if (portfolio.benchmark) {
          setToBenchmark(portfolio.benchmark)
        } else {
          clearValues()
        }
        // IMS Portfolios should not be set be clients
        isIMS.value = portfolio.ims

        store.dispatch('getAssetAllocationData', {
          portfolio: currentPortfolio.value.uuid,
          date: date.value.format('YYYY-MM-DD'),
          people: ['all']
        })
      }
    })

    const equityBreakdown = computed(() => store.getters.equityBreakdown);
    const assetBreakdown = computed(() => store.getters.allocationSummary);

    const formIsDirty = reactive({
      asset: false,
      equity: false
    })

    const assetAllocation = reactive({
      fixedInt: 0.00,
      equity: 0.00,
      property: 0.00
    })

    const assetTotal = computed(() => {
      let total = parseFloat(assetAllocation.fixedInt) + parseFloat(assetAllocation.equity) + parseFloat(assetAllocation.property) // + parseFloat(assetAllocation.cash) Why no cash??
      return isNaN(total) ? 0.00 : total.toFixed(2)
    })

    const equityAllocation = reactive({
      uk: 0.00,
      us: 0.00,
      euro: 0.00,
      asia: 0.00,
      japan: 0.00,
      emerging: 0.00,
      special: 0.00
    })

    const equityTotal = computed(() => {
      let total = parseFloat(equityAllocation.uk) + parseFloat(equityAllocation.us) + parseFloat(equityAllocation.euro) + parseFloat(equityAllocation.asia) + parseFloat(equityAllocation.japan) + parseFloat(equityAllocation.emerging) + parseFloat(equityAllocation.special)
      return isNaN(total) ? 0.00 : total.toFixed(2)
    })

    const closeModal = () => {
      context.emit('closeBenchmarkModal')
      clearValues()
      if (currentPortfolio.value.benchmark) {
        setToBenchmark(currentPortfolio.value.benchmark)
      }
    }

    const totalTally = (total) => {
      switch (total) {
        case 'equity':
          return '100.00' === equityTotal.value
        case 'asset' :
          return '100.00' === assetTotal.value
      }
    }

    const checkBenchmarkTally = computed(() => {
      return totalTally('equity') && totalTally('asset')
    })

    const checkAssetAndEquityProps = computed(() => {
      return assetBreakdown.value && equityBreakdown.value
    })

    const setToPortfolio = () => {
      if (assetBreakdown.value) {
        assetAllocation.fixedInt = assetBreakdown.value[0].fixed
        assetAllocation.equity = assetBreakdown.value[0].equity
        assetAllocation.property = assetBreakdown.value[0].property
      }
      if (equityBreakdown.value) {
        equityAllocation.uk = equityBreakdown.value[0].uk_equity
        equityAllocation.us = equityBreakdown.value[0].us_equity
        equityAllocation.euro = equityBreakdown.value[0].european_equity
        equityAllocation.asia = equityBreakdown.value[0].fareast_equity
        equityAllocation.japan = equityBreakdown.value[0].japan_equity
        equityAllocation.emerging = equityBreakdown.value[0].other_equity
        equityAllocation.special = equityBreakdown.value[0].specialist_equity
      }
    }

    const setToBenchmark = (benchmark) => {
      assetAllocation.equity = benchmark.equity
      assetAllocation.fixedInt = benchmark.fixed_interest
      assetAllocation.property = benchmark.property
      equityAllocation.uk = benchmark.uk_equity
      equityAllocation.us = benchmark.us_equity
      equityAllocation.euro = benchmark.european_equity
      equityAllocation.asia = benchmark.fareast_equity
      equityAllocation.japan = benchmark.japan_equity
      equityAllocation.emerging = benchmark.other_equity
      equityAllocation.special = benchmark.specialist_equity
    }

    const clearValues = () => {
      assetAllocation.fixedInt = 0.00
      assetAllocation.equity = 0.00
      assetAllocation.property = 0.00
      equityAllocation.uk = 0.00
      equityAllocation.us = 0.00
      equityAllocation.euro = 0.00
      equityAllocation.asia = 0.00
      equityAllocation.japan = 0.00
      equityAllocation.emerging = 0.00
      equityAllocation.special = 0.00
      v$.value.$reset()
    }

    const rules = {
      assetAllocation: {
        fixedInt: {required, decimal},
        equity: {required, decimal},
        property: {required, decimal},
      },
      equityAllocation: {
        uk: {required, decimal},
        us: {required, decimal},
        euro: {required, decimal},
        asia: {required, decimal},
        japan: {required, decimal},
        emerging: {required, decimal},
        special: {required, decimal}
      }
    }

    const v$ = useVuelidate(rules, {assetAllocation: assetAllocation, equityAllocation: equityAllocation})

    const submitForm = async () => {
      v$.value.$touch();
      if (v$.value.$invalid) return
      let data = formatDataForPost()

      if (currentPortfolio.value.benchmark) {
        const res = await store.dispatch('updateBenchmark', {portfolioId: currentPortfolio.value.uuid, benchmark: data})
        if (res) {
          setResponseToast(res)
        }
      } else {
        const res = await store.dispatch('setNewBenchmark', {portfolioId: currentPortfolio.value.uuid, benchmark: data})
        if (res) {
          setResponseToast(res)
        }
      }

    }
    const setResponseToast = (success) => {
      if (success === 'true') {
        store.dispatch('addNotification',{severity: 'success', summary: 'Benchmark Saved', detail: ``, life: 4000})
        closeModal()
      } else {
        store.dispatch('addNotification',{severity: 'error', summary: 'Benchmark Error', detail: `Not able to save this Benchmark.`, life: 4000})
      }
    }

    const formatDataForPost = () => {
      let id = currentPortfolio.value.benchmark ? currentPortfolio.value.benchmark.id : null
      const payload = {
        cash: 0,
        fixed_interest: assetAllocation.fixedInt,
        property: assetAllocation.property,
        equity: assetAllocation.equity,
        uk_equity: equityAllocation.uk,
        us_equity: equityAllocation.us,
        european_equity: equityAllocation.euro,
        fareast_equity: equityAllocation.asia,
        japan_equity: equityAllocation.japan,
        other_equity: equityAllocation.emerging,
        specialist_equity: equityAllocation.special
      }
      return id ? {id: id, ...payload} : payload
    }

    watch(assetAllocation, (val) => {
      formIsDirty.asset = val.property !== null || val.fixedInt !== null || val.equity !== null
    })

    watch(equityAllocation, (val) => {
      formIsDirty.equity = val.uk !== null || val.us !== null || val.euro !== null || val.japan !== null || val.asia !== null || val.emerging !== null || val.special !== null
    })

    const toggleContactForm = () => {
      window.open('mailto:enquiries@clarityglobal.com', '_blank');
    }

    return {
      closeModal,
      clearValues,
      assetAllocation,
      equityAllocation,
      assetTotal,
      equityTotal,
      totalTally,
      checkBenchmarkTally,
      checkAssetAndEquityProps,
      setToPortfolio,
      v$,
      submitForm,
      formIsDirty,
      currentPortfolio,
      isIMS,
      toggleContactForm,
      clarityURL
    }
  }
}
</script>

<style scoped>
.modal:deep(.modal-close) {
  display: none !important;
}

.modal:deep(.content-title) {
  padding: 10px 20px 10px 30px;
}

.modal:deep(.card-title) {
  padding: unset;
  height: unset;
  align-items: unset;
}

.modal:deep(.card-title h3) {
  margin-right: 15px;
}

.modal:deep(.card-title::before) {
  height: unset;
  width: unset;
  margin-right: unset;
  background-color: unset !important;
}

.blurb {
  font-size: 16px;
}

.note {
  margin-top: 15px;
}

.allocation-group {
  margin-bottom: 30px;
}

.with-totaliser {
  width: 100%;
}

.with-totaliser > .card-title-wrapper {
  justify-content: space-between;
}

.with-totaliser:deep(.title-content) {
  padding-right: 0;
}

.filter-group {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.filter-set {
  display: flex;
  flex-direction: column;
}

.filter-group .filter-set:first-of-type {
  margin-left: 0px;
  margin-right: 20px;
}

.filter-group .filter-set:last-of-type {
  margin-left: 20px;
  margin-right: 0px;
}

.filter-group .filter-set {
  margin: 15px 15px;
}

.filter-set label {
  margin-bottom: 15px;
  font-weight: bold;
  font-size: 18px;
  display: flex;
  align-content: center;
}

.btn-group button {
  padding: 15px 45px;
}

.tally {
  color: var(--clarity-green);
  font-weight: bold;
}

.no-tally {
  color: var(--clarity-red);
  font-weight: bold;
}

@media (min-width: 1700px) {
  .long {
    flex-wrap: nowrap;
  }

  .filter-top-row {
    width: 57%;
    margin-right: 30px;
  }

  .filter-second-row {
    width: 43%;
  }
}

@media (min-width: 640px) and (max-width: 1699px) {
  .long {
    flex-wrap: wrap;
  }
}

:deep(.content-full){
    padding-top: 10px;
}
</style>
