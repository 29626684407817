<template>
    <DataTable v-if="monies" :value="monies"  class="rd-table" scrollable scrollHeight="400px">
            <Column style="min-width:40%;" field="product" header="Holding Name" sortable>
                <template #body="slotProps">
                    <div>
                      <h3 class="light-blue">{{slotProps.data.product}}</h3>
                      <span class="hide-column-title">{{ slotProps.data.description}}</span>
                    </div>
                </template>
            </Column>
            <Column style="min-width:30%;" class="hide-column" field="description" header="Description" sortable></Column>
            <Column style="max-width: 120px;" field="transactionDate" header="Date" sortable >
                <template #body="slotProps">
                    {{$date(slotProps.data.transactionDate, 'DD/MM/YYYY')}}
                </template>
            </Column>
            <Column style="max-width: 140px;" class="hide-column" field="units" header="Units" sortable ></Column>
            <Column style="max-width: 140px;" class="hide-column" field="price" header="Price" sortable >
                <template #body="slotProps">
                    <currency-value :value="slotProps.data.price" :decimal-places="2" />
                </template>
            </Column>
            <Column style="max-width: 170px;" field="value" header="Value" sortable>
                <template #body="slotProps">
                    <currency-value :value="slotProps.data.value" :decimal-places="2" :class="[ hasValue(slotProps.data.value) ? 'money-in' : 'negative-text' ]"/>
                </template>
            </Column>
            <template #empty>
                <h4 class="flex justify-content-center">No performance transactions currently available.</h4>
            </template>
    </DataTable>
</template>
<script>
import CurrencyValue from "../common/CurrencyValue";
import { ref } from 'vue'

export default {
    props: ['monies'],
    setup() {

        const hasValue = (val) => {
            return val > 0.00
        }

        const rows = ref(10)

        return {
            hasValue,
            rows
        }
    },
    components: {
        CurrencyValue
    }
}
</script>
<style scoped>
.negative-text {
    font-size: 16px;
    color: var(--clarity-red);
}
.negative-text::before {
    content: '-';
}
.money-in {
    font-size: 16px;
    color: var(--clarity-green);
}
.money-in::before {
    content: '+';
}
.rd-table:deep(tr:last-child) {
    border-bottom-left-radius: 27px !important;
    border-bottom-right-radius: 27px !important;
}
</style>
