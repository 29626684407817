<template>
      <div ref="allocationsummarychart" class="allocation-summary-chart">

      </div>
</template>

<script>
    import * as am5 from '@amcharts/amcharts5';
    import * as am5percent from '@amcharts/amcharts5/percent'
    import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
    import {ref, watch, computed, onMounted, toRef} from 'vue'
    import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";

    export default {
      name: 'AllocationSummaryChart',
      props: {
        chartData: Object,
        keys: Array,
        chartTitle: String
      },
      setup(props) {

        const chartData = toRef(props, 'chartData')
        const keys = toRef(props, 'keys')

        const chartParts = ref({});
        const chartRendered = ref(false);
        const allocationsummarychart = ref(null);
        const exporting = ref();

        const tidyChartData = computed(() => {
          const originalData = chartData.value;

          if(originalData) {
            var returnData = [];
            keys.value.forEach((key) => {
              returnData.push({
                name: key.label,
                value: originalData[0][key.value],
              })
            })

            return returnData;

          }else{
            return false;
          }
        })

        onMounted(() => {
          if(tidyChartData.value) {
            renderChart(chartParts, tidyChartData.value)
            chartRendered.value = true;
          }
        })

        watch(tidyChartData, (value) => {
          if(chartRendered.value){
            updateChart(chartParts, value)
          }else{
            renderChart(chartParts, value)
            chartRendered.value = true;
          }
        })

        const renderChart = (chartParts, data) => {

          am5.addLicense("AM5C334338446");
          let root = am5.Root.new(allocationsummarychart.value);
          root.setThemes([am5themes_Animated.new(root)]);

          // Create chart
          // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/
          var chart = root.container.children.push(am5percent.PieChart.new(root, {
            layout: root.horizontalLayout,
            innerRadius: 90,
          }));

          // Create series
          // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Series
          chartParts.value.series = chart.series.push(am5percent.PieSeries.new(root, {
            valueField: "value",
            categoryField: "name",
            legendValueText: "",
          }));

          chartParts.value.series.labels.template.set("forceHidden", true);
          chartParts.value.series.ticks.template.set("forceHidden", true);

          chartParts.value.series.slices.template.setAll({
            stroke: am5.color("#ffffff"),
            strokeWidth: 4
          });

          chartParts.value.series.slices.template.set("fillGradient", am5.LinearGradient.new(root, {
            stops: [{
              brighten: 0.8
            }, {
              brighten: 0
            }],
          }));


          chartParts.value.series.get("colors").set("colors", [
            am5.color("#0058A9"),
            am5.color("#179BD7"),
            am5.color("#AEDCF1"),
            am5.color("#019567"),
            am5.color("#00B04C"),
            am5.color("#F18903"),
            am5.color("#D95D03")
          ]);

          // Set data
          // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Setting_data
          chartParts.value.series.data.setAll(data);

          // Create legend
          // https://www.amcharts.com/docs/v5/charts/percent-charts/legend-percent-series/
          chartParts.value.legend = chart.children.push(am5.Legend.new(root, {
            y: am5.percent(50),
            centerY: am5.percent(50),
            layout: root.verticalLayout
          }));

          chartParts.value.legend.data.setAll(chartParts.value.series.dataItems);

          chartParts.value.legend.labels.template.setAll({
            fontSize: 14,
            fontWeight: "400",
            marginLeft: 18,
            centerY: 6
          });

          chartParts.value.legend.markerRectangles.template.setAll({
            cornerRadiusTL: 15,
            cornerRadiusTR: 15,
            cornerRadiusBL: 15,
            cornerRadiusBR: 15,
            width: 25,
            height: 25
          });



          // Play initial series animation
          // https://www.amcharts.com/docs/v5/concepts/animations/#Animation_of_series
          chartParts.value.series.appear(1000, 100);

          chartParts.value.label = chart.seriesContainer.children.push(
              am5.Label.new(root, {
                textAlign: "center",
                centerY: am5.percent(55),
                centerX: am5.p50,
                text: "[fontSize:20px]"+props.chartTitle.replaceAll(" ", "\n")+"[/]\n\n[bold fontSize:12px #8B9682]Current[/]"
              })
          );

          exporting.value = am5plugins_exporting.Exporting.new(root, {});

        }

        const exportImage = () => {
          exporting.value.export("png").then(function(imgData) {
            console.log('image data is ', imgData);
          });
        }

        const updateChart = (series, data) => {
          chartParts.value.series.data.setAll(data);
          chartParts.value.legend.data.setAll(chartParts.value.series.dataItems);
        }

        return {
          chartParts,
          chartRendered,
          allocationsummarychart,
          exportImage
        }
      }
    }
</script>

<style scoped lang="scss">
  .allocation-summary-chart {
    position: relative;
    width: 100%;
    height:400px;
  }
</style>
