<template>
  <MultiSelect v-model="localPeopleSelected" :options="peopleOptions" optionLabel="name" :loading="loading" placeholder="Everyone" @change="updatePeopleSelected"  @before-show="stopScroll" @before-hide="allowScroll" >
    <template #value="slotProps">
      {{ getValue(slotProps) }}
    </template>
  </MultiSelect>
</template>

<script>
import {useStore} from "vuex";
import {computed, onMounted, inject, ref, watch} from "vue";
import {debounce} from "lodash";

export default {
  name: "PeopleSelect",
  setup() {
    const store = useStore()
    const bus = inject('bus')

    const localPeopleSelected = ref();
    const selectedOption = computed(() => store.getters.peopleSelected );

    onMounted(() => {
      getPeopleOptions()
      localPeopleSelected.value = store.getters.peopleSelected
    })

    const getPeopleOptions = () => {
      store.dispatch('getPeopleOptions')
    }

    const updatePeopleSelected = debounce((data) => {
        store.dispatch('setPeopleSelected', data.value)
    }, 600)

    const peopleOptions = computed(() => {
      const options = store.state.selections.peopleOptions;
      if(options && options.error){
        store.dispatch('addNotification', {severity: 'error', summary: 'People Select', detail: options.message })
        return [];
      }else{
        return options;
      }
    })

    const getValue = (slotProps) => {
      let selectedOption = localPeopleSelected.value;
      const people = store.state.selections.peopleOptions;
      switch (true){
        case selectedOption && selectedOption.length == people.length:
          return 'Everyone'
        case selectedOption && selectedOption.length > 1:
          return 'Multiple'
        case !selectedOption:
          return 'Everyone'
        default:
          // this state reached at start and when deselecting all return placeholder
          return slotProps.value && slotProps.value.length ? slotProps.value[0].name : slotProps.placeholder
      }
    }

    const stopScroll = () => {
      bus.emit('stopScroll')
    }
    const allowScroll = () => {
      bus.emit('allowScroll')
    }

    watch(selectedOption, (value) => {
        localPeopleSelected.value = value
    })

    return {
      selectedOption,
      loading: computed(() => store.state.selections.peopleLoading),
      peopleOptions,
      updatePeopleSelected,
      getValue,
      allowScroll,
      stopScroll,
      localPeopleSelected
    }
  }
}
</script>

<style scoped>
.p-multiselect {
  width: 300px;
}

@media only screen and (max-width: 1366px)  {
  .p-multiselect {
    width: 170px;
  }
}

@media only screen and (max-width: 1024px)  {
  .p-multiselect {
    width: 150px;
  }
}

@media only screen and (max-width: 768px)  {
  .p-multiselect {
    width: 138px;
  }
}
</style>
