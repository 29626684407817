import { ref, computed, watch } from 'vue';
import { throttle } from 'lodash';

export function createThrottledComputed(initialComputed, delay) {
    const throttledValue = ref(initialComputed.value);
    const computedProperty = computed(() => throttledValue.value);

    watch(initialComputed, throttle(() => {
        throttledValue.value = initialComputed.value;
    }, delay));

    return computedProperty;
}
