import dayjs from "dayjs";
import * as ExportData from './ValuationDataExtractor'
import store from "@/store";

export async function buildAllocationExcelPayload(valuationDate, mode){
    const formattedDateDMY = dayjs(valuationDate).format('DD/MM/YYYY')
    const todayDateDMY = dayjs().format('DD/MM/YYYY')

    const adviser = { name: store.getters.getAdviserName };

    const baseObject = {
        details: {},
        filters: {},
        plans: {}
    }

    let detailsSet = await buildHeaderSection()
    let filtersSet = await buildFilterSection()
    let plansSet = await buildPlansSection(mode)

    if (detailsSet && filtersSet && plansSet) {
        return baseObject
    }
    async function buildHeaderSection() {
        const primaryClient = await ExportData.getPrimaryClient()
        const adviserName = adviser.name

        return baseObject.details = {
            "sectionheader": [],
            "sectioncontent": [
                {
                    "A": "Client Name",
                    "P": primaryClient
                },
                {
                    "A": "Exporting Date",
                    "P": todayDateDMY
                },
                {
                    "A": "Adviser Name",
                    "P": adviserName
                }
            ],
            "sectionfooter": []
        }
    }

    async function buildFilterSection() {
        // @TODO: redo this to make use of portfolio options instead to pull out name
        let portfolio = await ExportData.getSelectedPortfolio()

        return baseObject.filters = {
            "sectionheader": [],
            "sectioncontent": [
                {
                    "A": "Portfolio",
                    "P": portfolio
                },
                {
                    "A": "People",
                    "P": await ExportData.getSelectedPeople()
                },
                {
                    "A": "Valuations Date",
                    "P": formattedDateDMY
                },
                {
                    "A": "Currency",
                    "P": store.getters.currencySelected
                }
            ],
            "sectionfooter": []
        }
    }

    async function buildPlansSection(mode) {
        const plansObject = {
            sectionheader:[
                {
                    "A": "Policy",
                    "B": "Fund",
                    "C": "ISIN",
                    "D": "Approx Value",
                    "E": "Cash",
                    "F": "Fixed Interest",
                    "G": "Equities",
                    "O": "Other",
                    "P": "Total"
                },
                {
                    "G": "UK",
                    "H": "US",
                    "I": "Europe",
                    "J": "Asia Pacific",
                    "K": "Japan",
                    "L": "Emerging Markets",
                    "M": "Specialist",
                    "N": "Total Equities"
                }
            ],
            sectioncontent:[],
            sectionfooter: [
                {
                    "A": "Benchmark",
                    "E": 'N/A',
                    "F": 'N/A',
                    "N": 'N/A',
                    "O": 'N/A',
                    "P": 'N/A'
                },
                {
                    "A": "Benchmark",
                    "G": 'N/A',
                    "H": 'N/A',
                    "I": 'N/A',
                    "J": 'N/A',
                    "K": 'N/A',
                    "L": 'N/A',
                    "M": 'N/A',
                    "P": 'N/A'
                }
            ]
        }

        const fullAllocation = store.getters.fullAllocation;
        const selectedCurrency = store.getters.currencySelected
        const currencyOptions = store.state.selections.currencyOptions

        const pensionHoldings = {
            "name": "Pension Holdings",
            "sectionheader": [],
            "sectioncontent": [],
            "sectionfooter": []
        }

        const nonPensionHoldings = {
            "name": "Non-Pension Holdings",
            "sectionheader": [],
            "sectioncontent": [],
            "sectionfooter": []
        }

        fullAllocation.forEach((record)=> {
            if(record['totals']) {
                const policyInfo = store.getters.policyBasicInfo(record.id)
                let ownerString = '';
                if (policyInfo && policyInfo.ownership) {
                    let rtn = []
                    policyInfo.ownership.forEach(person => {
                        rtn.push(store.getters.personFromUuid(person).name)
                    })
                    ownerString += '('+rtn.join(", ")+')'
                }
                if(mode === 'Condensed') {

                    if(record["totals"].value !== 0) {

                        const newRecord = {
                            "A": record.name + ' ' + ownerString,
                            "B": "Various Funds",
                            "C": "ISIN",
                            "D": record["totals"].value,
                            "E": record["totals"].cash,
                            "F": record["totals"].fixed_interest,
                            "G": record["totals"].uk_equity,
                            "H": record["totals"].us_equity,
                            "I": record["totals"].european_equity,
                            "J": record["totals"].fareast_equity,
                            "K": record["totals"].japan_equity,
                            "L": record["totals"].other_equity,
                            "M": record["totals"].specialist_equity,
                            "N": (record["totals"].uk_equity + record["totals"].us_equity + record["totals"].european_equity + record["totals"].fareast_equity + record["totals"].japan_equity + record["totals"].other_equity + record["totals"].specialist_equity),
                            "O": record["totals"].property,
                            "P": record["totals"].value
                        }

                        if (selectedCurrency != 'GBP') {
                            // if display is not in GBP we need to convert the currency.
                            const conversionCurrency = currencyOptions.find(obj => {
                                return obj.currencyCode === selectedCurrency
                            });
                            newRecord["D"] = newRecord["D"] * conversionCurrency.conversionrate;
                            newRecord["E"] = newRecord["E"] * conversionCurrency.conversionrate;
                            newRecord["F"] = newRecord["F"] * conversionCurrency.conversionrate;
                            newRecord["G"] = newRecord["G"] * conversionCurrency.conversionrate;
                            newRecord["H"] = newRecord["H"] * conversionCurrency.conversionrate;
                            newRecord["I"] = newRecord["I"] * conversionCurrency.conversionrate;
                            newRecord["J"] = newRecord["J"] * conversionCurrency.conversionrate;
                            newRecord["K"] = newRecord["K"] * conversionCurrency.conversionrate;
                            newRecord["L"] = newRecord["L"] * conversionCurrency.conversionrate;
                            newRecord["M"] = newRecord["M"] * conversionCurrency.conversionrate;
                            newRecord["N"] = newRecord["N"] * conversionCurrency.conversionrate;
                            newRecord["O"] = newRecord["O"] * conversionCurrency.conversionrate;
                            newRecord["P"] = newRecord["P"] * conversionCurrency.conversionrate;
                        }

                        if (record.type === 'Pens') {
                            pensionHoldings.sectioncontent.push(newRecord)
                        } else if (record.type === 'NonPens') {
                            nonPensionHoldings.sectioncontent.push(newRecord)
                        }
                    }
                }else if(mode === 'Full'){

                    // iterate over the "funds"
                    record["funds"].forEach((fund) => {

                        if(fund.value*1 !== 0) {
                            const newRecord = {
                                "A": record.name + ' ' + ownerString,
                                "B": fund.name,
                                "C": fund.isin,
                                "D": fund.value,
                                "E": fund.allocation.cash,
                                "F": fund.allocation.fixed_interest,
                                "G": fund.allocation.uk_equity,
                                "H": fund.allocation.us_equity,
                                "I": fund.allocation.european_equity,
                                "J": fund.allocation.fareast_equity,
                                "K": fund.allocation.japan_equity,
                                "L": fund.allocation.other_equity,
                                "M": fund.allocation.specialist_equity,
                                "N": (fund.allocation.uk_equity + fund.allocation.us_equity + fund.allocation.european_equity + fund.allocation.fareast_equity + fund.allocation.japan_equity + fund.allocation.other_equity + fund.allocation.specialist_equity),
                                "O": fund.allocation.property,
                                "P": fund.value
                            }
                            if (selectedCurrency != 'GBP') {
                                // if display is not in GBP we need to convert the currency.
                                const conversionCurrency = currencyOptions.find(obj => {
                                    return obj.currencyCode === selectedCurrency
                                });
                                newRecord["D"] = newRecord["D"] * conversionCurrency.conversionrate;
                                newRecord["E"] = newRecord["E"] * conversionCurrency.conversionrate;
                                newRecord["F"] = newRecord["F"] * conversionCurrency.conversionrate;
                                newRecord["G"] = newRecord["G"] * conversionCurrency.conversionrate;
                                newRecord["H"] = newRecord["H"] * conversionCurrency.conversionrate;
                                newRecord["I"] = newRecord["I"] * conversionCurrency.conversionrate;
                                newRecord["J"] = newRecord["J"] * conversionCurrency.conversionrate;
                                newRecord["K"] = newRecord["K"] * conversionCurrency.conversionrate;
                                newRecord["L"] = newRecord["L"] * conversionCurrency.conversionrate;
                                newRecord["M"] = newRecord["M"] * conversionCurrency.conversionrate;
                                newRecord["N"] = newRecord["N"] * conversionCurrency.conversionrate;
                                newRecord["O"] = newRecord["O"] * conversionCurrency.conversionrate;
                                newRecord["P"] = newRecord["P"] * conversionCurrency.conversionrate;
                            }

                            if (record.type === 'Pens') {
                                pensionHoldings.sectioncontent.push(newRecord)
                            } else if (record.type === 'NonPens') {
                                nonPensionHoldings.sectioncontent.push(newRecord)
                            }
                        }
                    })
                }
            }
        })

        plansObject.sectioncontent.push(pensionHoldings)
        plansObject.sectioncontent.push(nonPensionHoldings)

        // lastly the benchmark.
        const assetSummary = store.getters.allocationSummary;
        if(assetSummary && assetSummary.length > 1){
            plansObject.sectionfooter[0]["E"] = assetSummary[1]['cash'];
            plansObject.sectionfooter[0]["F"] = assetSummary[1]['fixed'];
            plansObject.sectionfooter[0]["N"] = assetSummary[1]['equity'];
            plansObject.sectionfooter[0]["O"] = assetSummary[1]['property'];
            plansObject.sectionfooter[0]["P"] = 100;
        }

        const equityBreakdown = store.getters.equityBreakdown;
        if(equityBreakdown && equityBreakdown.length > 1){
            plansObject.sectionfooter[1]["G"] = equityBreakdown[1]['uk_equity'];
            plansObject.sectionfooter[1]["H"] = equityBreakdown[1]['us_equity'];
            plansObject.sectionfooter[1]["I"] = equityBreakdown[1]['european_equity'];
            plansObject.sectionfooter[1]["J"] = equityBreakdown[1]['fareast_equity'];
            plansObject.sectionfooter[1]["K"] = equityBreakdown[1]['japan_equity'];
            plansObject.sectionfooter[1]["L"] = equityBreakdown[1]['other_equity'];
            plansObject.sectionfooter[1]["M"] = equityBreakdown[1]['specialist_equity'];
            plansObject.sectionfooter[1]["P"] = 100;
        }

        return baseObject.plans = plansObject;
    }
}
